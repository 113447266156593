<div class="view-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="flex flex-col p-6 gap-6" *ngIf="!isLoadingViewCard">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!isChanged"
        (click)="formSubmit$.next()"
      >
        Save
      </button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <div class="flex gap-5">
        <div class="left-controls flex flex-col w-full">
          <form *ngIf="editForm" [formGroup]="editForm" class="row-controls flex flex-col">
            <ptg-textbox
              placeholder="View Name"
              errorAsync="View Name already exists."
              [ngClass]="{
                'has-error': nameCtrl.errors && nameCtrl.touched
              }"
              width="33%"
              [controlField]="nameCtrl"
              [hasLabel]="true"
              [maxLength]="100"
              (valueChange)="onChangeData()"
            ></ptg-textbox>
            <div class="detail">
              <span class="label" id="label-Status Name">Type</span>
              <span class="value" id="value-Status Name">
                <ng-container>{{ this.viewDetailData?.entityTypeName }}</ng-container>
              </span>
            </div>
            <div class="detail">
              <span class="label" id="label-Status Name">Entity</span>
              <span class="value" id="value-Status Name">
                <ng-container>{{ this.viewDetailData?.entityName }}</ng-container>
              </span>
            </div>
            <div *ngIf="this.currentViewType === EntityTypeEnum.Detail">
              <ptg-select
                width="33%"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="availableCardConfigs"
                [controlField]="editForm.get('card')"
                placeholder="Card Name"
                panelClass="card-dropdown"
                (changeOptionValue)="onChangeData()"
              ></ptg-select>
            </div>
          </form>
        </div>
      </div>

      <div class="flex gap-5 mt-2" *ngIf="this.currentViewType === EntityTypeEnum.Summary">
        <div class="flex-1">
          <div class="box-info">
            <div class="box-info-header">Add Card</div>
            <div class="box-info-content">
              <div class="form-container">
                <ptg-select
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="availableCardConfigs"
                  [controlField]="addCardForm.get('key')"
                  placeholder="Card Name"
                  panelClass="property-name-dropdown"
                  (changeOptionValue)="changeProperty()"
                ></ptg-select>
                <ptg-textbox
                  [controlField]="addCardForm.get('cardLabel')"
                  [hasLabel]="true"
                  [maxLength]="cardLabelMaxLength"
                  customError="existed"
                  placeholder="Card Label"
                  [maxLength]="100"
                ></ptg-textbox>
                <ptg-button buttonName="Add Card" classInput="add-button" type="button" (clickButton)="addProperty()">
                  <mat-icon>add</mat-icon>
                </ptg-button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div class="box-info">
            <div class="box-info-header">Left Column</div>
            <div class="box-info-content">
              <ptg-grid
                #gridLeftCard
                [data]="leftCard"
                [columns]="configuredCardColumns"
                [paginable]="false"
                [notFoundMessage]="''"
                [allowDragDrop]="true"
                [hideHeader]="true"
                [inlineEditable]="true"
                [softDeletable]="true"
                (softDelete)="onSoftDeleteSectionConfig($event)"
                (change)="onChangeData()"
                (cancelInlineEdit)="touchAllFieldToCheckValidate()"
              >
                <ng-template cellContent columnName="name" let-row>
                  <div class="multiple-line-text">
                    <span class="caption">{{ row.name }}</span>
                    <span class="description">{{ row.sectionName ?? row.cardName }}</span>
                  </div>
                </ng-template>

                <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                  <ng-container *ngIf="!(row.editing || row.deleted)">
                    <ptg-button (clickButton)="onClickMove2Right(row)" classInput="edit-button">
                      <mat-icon>arrow_right_alt</mat-icon>
                    </ptg-button>
                  </ng-container>
                </ng-template>
              </ptg-grid>
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div class="box-info">
            <div class="box-info-header">Right Column</div>
            <div class="box-info-content">
              <ptg-grid
                #gridRightCard
                [data]="rightCard"
                [columns]="configuredCardColumns"
                [isLoading]="isLoadingViewCard"
                [paginable]="false"
                [notFoundMessage]="''"
                [allowDragDrop]="true"
                [hideHeader]="true"
                [inlineEditable]="true"
                [softDeletable]="true"
                (softDelete)="onSoftDeleteSectionConfig($event)"
                (change)="onChangeData()"
                (cancelInlineEdit)="touchAllFieldToCheckValidate()"
              >
                <ng-template cellContent columnName="name" let-row>
                  <div class="multiple-line-text">
                    <span class="caption">{{ row.name }}</span>
                    <span class="description">{{ row.sectionName ?? row.cardName }}</span>
                  </div>
                </ng-template>

                <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                  <ng-container *ngIf="!(row.editing || row.deleted)">
                    <ptg-button (clickButton)="onClickMove2Left(row)" classInput="edit-button">
                      <mat-icon style="transform: scaleX(-1)"> arrow_right_alt </mat-icon>
                    </ptg-button>
                  </ng-container>
                </ng-template>
              </ptg-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoadingViewCard">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
