import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  FormArray,
  FormGroup,
  FormBuilder,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Input, Output, Component, OnChanges, EventEmitter } from '@angular/core';

import { takeUntil, take } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ExceptionOption } from '@ptg-member/constants';
import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { EditExceptionComponentService } from './edit-exception.component.service';
import { EditExceptionConfigurationComponentService } from '@ptg-member/features/calculation/components/edit-exception-configuration/edit-exception-configuration.component.service';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import {
  Entity,
  RangeValue,
  BenefitOption,
  BenefitDetail,
  PropertyDetail,
  EntityProperty,
  AdditionalInfo,
  ExceptionBenefit,
  LookupInformation,
  ExceptionProperties,
  SaveExceptionConfigurationDetailRequest,
} from '@ptg-member/features/calculation/services/models/exception-configuration.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  ExceptionType,
  BenefitOptions,
  ComparisonOperatorLabel,
  ComparisonOperatorSymbol,
} from '@ptg-member/features/calculation/types/enums';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Component({
  selector: 'ptg-edit-exception',
  templateUrl: './edit-exception.component.html',
  styleUrls: ['./edit-exception.component.scss'],
  providers: [EditExceptionComponentService],
})
export class EditExceptionComponent extends BaseComponent implements OnChanges {
  @Output() onSubmitEvent = new EventEmitter<SaveExceptionConfigurationDetailRequest>();
  @Output() onCancelEvent = new EventEmitter<null>();

  @Input() exceptionType?: ExceptionType;
  @Input() description = '';
  @Input() savedBenefitDetailList: BenefitDetail[] = [];
  @Input() savedEntityDetailList: PropertyDetail[] = [];

  @Input() benefitOptionList: Option[] = [];
  @Input() additionalBenefitValueObj?: AdditionalInfo;
  outputMappingList: Option[] = [];
  validationIntervalList: Option[] = this.editExceptionComponentService.getValidationIntervalList;
  isShowEditingValidationToggler = false;
  @Input() isShowAdditionalBenefitValueTextBox = false;
  @Input() isShowBenefitBeginDateDropdown = false;

  @Input() entityList: Option[] = [];
  propertyList: Option[] = [];
  entityValueList: Option[] = [];
  @Input() isShowEntityListDropdown = false;

  @Input() memberEntityData: Option[] = [];
  comparisonDateList: Option[] = [];

  @Input() benefitHasInProcessingCalculation: string[] = [];
  @Input() isPropertiesEditable = true;
  @Input() isEditingValidation: boolean | null = null;

  rangeValue: RangeValue = {
    min: 1,
    max: 99,
  };

  editForm: FormGroup = this.initFormGroup;

  isFormDirty = false;
  isSurvivorNotFound = false;
  isDateValueValidation = false;

  private selectedEntityComponentId = '';
  private selectedPropertyId = '';
  private selectedAdditionalEntityValueId = '';

  constructor(
    public readonly route: ActivatedRoute,

    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly editExceptionComponentService: EditExceptionComponentService,
    private readonly editExceptionConfigurationComponentService: EditExceptionConfigurationComponentService,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (typeof this.exceptionType !== 'number') {
      return;
    }

    if (typeof this.exceptionType === 'number') {
      this.isSurvivorNotFound = this.exceptionType === ExceptionType.SurvivorNotFound;
      this.isDateValueValidation = this.exceptionType === ExceptionType.DateValueValidation;
      this.isShowEditingValidationToggler =
        this.editExceptionConfigurationComponentService.exceptionsHaveEditingValidationToggler.includes(
          this.exceptionType,
        );

      this.resetFormData();
    }
    if (this.isShowEditingValidationToggler && this.isDateValueValidation) {
      this.isEditingValidationControl.setValue(true);
    }
    if (
      this.isShowEditingValidationToggler &&
      this.editExceptionConfigurationComponentService.exceptionsHaveEditingValidationToggler
        .filter((item) => item === ExceptionType.DateValueValidation)
        .includes(this.exceptionType)
    ) {
      this.isEditingValidationControl.setValue(false);
    }
    if (this.isShowEntityListDropdown && this.entityList?.length && this.savedEntityDetailList?.length) {
      this.initEntityForm();
    }
    if (
      this.exceptionType === ExceptionType.MemberMinPensionPaymentsNotMet // 4
    ) {
      this.rangeValue.min = 0;
      this.rangeValue.max = 25;
    }
    if (typeof this.isEditingValidation === 'boolean') {
      this.isEditingValidationControl.setValue(this.isEditingValidation);
    }
    if (this.description?.length) {
      this.descriptionControl.setValue(this.description);
    }
    if (this.savedBenefitDetailList?.length) {
      this.initBenefitChipForm();
    }
  }

  ngOnInit(): void {
    this.listenFormDirty();
  }

  onSubmit(): void {
    if (this.isShowEntityListDropdown && !this.isEditFormValid) {
      return;
    }

    const formValue = this.editForm.getRawValue();
    const submitData: SaveExceptionConfigurationDetailRequest = this.manipulateSubmitData(formValue);
    this.onSubmitEvent.emit(submitData);
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.resetFormData();
          this.onCancelEvent.emit(null);
        }
      });
  }

  onChangeList(): void {
    // Remove the current [Property] dropdown list
    this.propertyControl.reset();
    this.propertyList = [];
    // Remove the current [Value] dropdown list
    this.additionalEntityValueControl.reset();
    this.entityValueList = [];
    if (this.isSurvivorNotFound) {
      this.additionalEntityValueForChildControl.reset();
    }

    const selectedEntityObj: Entity = this.entityControl.value;

    // If [Entity List] is not selected
    if (!selectedEntityObj) {
      return;
    }

    this.propertyList = selectedEntityObj.properties as any[];
  }

  onChangeProperty(): void {
    const selectedPropertyObj: EntityProperty = this.propertyControl.value;

    // If [Property] is not selected
    if (!selectedPropertyObj) {
      return;
    }

    this.entityValueList = selectedPropertyObj.propertyLookupValues as any[];
  }

  onChangeEntityValue(): void {
    this.entityValueList.forEach((option: Option) => {
      const isHide =
        option.value?.id === this.additionalEntityValueControl.value?.id ||
        option.value?.id === this.additionalEntityValueForChildControl.value?.id;
      option.isHide = isHide;
    });
  }

  onChangeBenefitOption(): void {
    const selectedBenefitOptionObj: BenefitOption | ExceptionOption.All = this.benefitOptionControl.value;

    // If Benefit Option is not selected
    if (!selectedBenefitOptionObj) {
      return;
    }

    if (selectedBenefitOptionObj !== ExceptionOption.All) {
      this.outputMappingList = (selectedBenefitOptionObj.outputMapping as unknown as Option[]) ?? [];
      this.comparisonDateList =
        ([...this.memberEntityData, ...selectedBenefitOptionObj.outputMapping] as unknown as Option[]) ?? [];
    }

    if (this.isShowAdditionalBenefitValueTextBox) {
      this.additionalBenefitValueControl.reset();
    }

    if (this.isShowBenefitBeginDateDropdown) {
      this.outputMappingControl.reset();
    }

    if (this.isDateValueValidation) {
      this.validationDateControl.reset();
      this.validationIntervalControl.reset();
      this.comparisonDateControl.reset();
    }
  }

  async onAddNewChip(): Promise<void> {
    const selectedBenefitOption: BenefitOption | ExceptionOption.All = this.benefitOptionControl.value;
    const newChipList: FormControl[] = [];

    if (!selectedBenefitOption) {
      return;
    }

    let addedBenefitDetail: BenefitDetail;
    if (selectedBenefitOption === ExceptionOption.All) {
      addedBenefitDetail = {
        benefitId: '',
        benefitName: 'All Benefit Options',
        benefitOptions: BenefitOptions.All,
        additionalInfos: [],
      };
    } else {
      addedBenefitDetail = {
        benefitId: selectedBenefitOption.id,
        benefitName: selectedBenefitOption.name,
        benefitOptions: BenefitOptions.Single,
        additionalInfos: [],
      };
    }

    if (
      !(await this.editExceptionComponentService.isBenefitOptionUsedForCalculation(
        'Add',
        addedBenefitDetail,
        this.savedBenefitDetailList,
        this.benefitHasInProcessingCalculation,
      ))
    ) {
      return;
    }

    const newChipControl = this.getNewChipBody(selectedBenefitOption);
    newChipList.push(newChipControl);

    //  Select [All] option
    if (selectedBenefitOption === ExceptionOption.All) {
      // Remove: all added chips
      this.benefitOptionChipListControl.clear();
      // Hide the [All] option, Show all other options from the dropdown list
      this.benefitOptionList.forEach((item) => {
        item.isHide = false;
      });
      const selectedOption = this.benefitOptionList.find((option) => option.value === ExceptionOption.All);
      if (selectedOption) {
        selectedOption.isHide = true;
      }
    }

    //  Select [Specific] option
    else if (!this.isDateValueValidation) {
      // Remove [All] option chip
      this.benefitOptionChipListControl.controls = this.benefitOptionChipListControl.controls.filter(
        ({ value }) => value.benefitOptions !== BenefitOptions.All,
      );
      // Hide selected option, Show [ALl] option from the dropdown list
      const allOption = this.benefitOptionList.find((option) => option.value === ExceptionOption.All);
      const selectedOption = this.benefitOptionList.find((option) => option.value?.id === selectedBenefitOption.id);

      if (allOption) {
        allOption.isHide = false;
      }
      if (selectedOption) {
        selectedOption.isHide = true;
      }
    }

    newChipList.forEach((item) => {
      this.benefitOptionChipListControl.push(item);
    });

    // Filter the Benefit Option dropdown list

    this.benefitOptionControl.reset();
    this.additionalBenefitValueControl.reset();
    this.outputMappingControl.reset();
    this.validationDateControl.reset();
    this.validationIntervalControl.reset();
    this.comparisonDateControl.reset();

    this.outputMappingList = [];
    this.comparisonDateList = [];
    this.isFormDirty = true;
  }

  async onRemoveChip(index: number): Promise<void> {
    const removedChipCtrl = this.benefitOptionChipListControl.at(index);
    let removedBenefitDetail: BenefitDetail = removedChipCtrl?.value;

    if (!removedBenefitDetail?.benefitId) {
      removedBenefitDetail = {
        benefitId: '',
        benefitName: 'All Benefit Options',
        benefitOptions: BenefitOptions.All,
        additionalInfos: [],
      };
    }

    if (
      !(await this.editExceptionComponentService.isBenefitOptionUsedForCalculation(
        'Remove',
        removedBenefitDetail,
        this.savedBenefitDetailList,
        this.benefitHasInProcessingCalculation,
      ))
    ) {
      return;
    }

    const removedChipOption: Option | undefined = this.benefitOptionList.find(
      (item) => item.value?.id === removedChipCtrl.value?.benefitId,
    );

    if (removedChipOption) {
      removedChipOption.isHide = false;
    }
    this.benefitOptionChipListControl.removeAt(index);
    this.isFormDirty = true;
  }

  /* START of Common components */
  get descriptionControl(): FormControl {
    return this.editForm?.get('description') as FormControl;
  }

  get benefitOptionControl(): FormControl {
    return this.editForm?.get('benefitOption') as FormControl;
  }

  get benefitOptionChipListControl(): FormArray {
    return this.editForm?.get('benefitOptionChipList') as FormArray;
  }
  /* END of Common components */

  /* START of Benefit relating components */
  get additionalBenefitValueControl(): FormControl {
    return this.editForm?.get('additionalBenefitValue') as FormControl;
  }

  get isEditingValidationControl(): FormControl {
    return this.editForm?.get('isEditingValidation') as FormControl;
  }

  get outputMappingControl(): FormControl {
    return this.editForm?.get('outputMapping') as FormControl;
  }

  get validationDateControl(): FormControl {
    return this.editForm?.get('validationDate') as FormControl;
  }

  get validationIntervalControl(): FormControl {
    return this.editForm?.get('validationInterval') as FormControl;
  }

  get comparisonDateControl(): FormControl {
    return this.editForm?.get('comparisonDate') as FormControl;
  }
  /* END of Benefit relating components */

  /* START of Entity List relating components */
  get entityControl(): FormControl {
    return this.editForm?.get('entity') as FormControl;
  }

  get propertyControl(): FormControl {
    return this.editForm?.get('property') as FormControl;
  }

  get additionalEntityValueControl(): FormControl {
    return this.editForm?.get('additionalEntityValue') as FormControl;
  }

  get additionalEntityValueForChildControl(): FormControl {
    return this.editForm?.get('additionalEntityValueForChild') as FormControl;
  }
  /* END of Entity List relating components */

  private getNewChipBody(benefitOption: BenefitOption | ExceptionOption.All): FormControl {
    const additionalInfos: AdditionalInfo[] = [];
    const outputMapping: AdditionalInfo = this.outputMappingControl.value;
    const additionalBenefitValue: string = this.additionalBenefitValueControl.value;

    if (additionalBenefitValue && this.additionalBenefitValueObj) {
      additionalInfos.push({
        name: this.additionalBenefitValueObj.name,
        type: this.additionalBenefitValueObj.type,
        value: additionalBenefitValue,
        unit: this.additionalBenefitValueObj.unit,
        displayValue: this.additionalBenefitValueObj.name,
      });
    }

    if (this.isShowBenefitBeginDateDropdown) {
      additionalInfos.push({
        name: 'Benefit Begin Date',
        type: EntityPropertyType.Date,
        value: outputMapping.value,
        displayValue: outputMapping.name,
      });
    }

    const validationDate: AdditionalInfo = this.validationDateControl.value;
    const validationInterval: number = this.validationIntervalControl.value;
    const comparisonDate: AdditionalInfo = this.comparisonDateControl.value;

    if (this.isDateValueValidation) {
      additionalInfos.push({
        name: validationDate.name,
        type: EntityPropertyType.Date,
        value: validationDate.value,
        displayValue: validationDate.name,
        source: validationDate.source,
      });
      additionalInfos.push({
        name: ComparisonOperatorSymbol[validationInterval],
        type: EntityPropertyType.Text,
        value: validationInterval?.toString() ?? '',
        displayValue: ComparisonOperatorLabel[validationInterval],
      });
      additionalInfos.push({
        name: comparisonDate.displayValue ?? '',
        type: EntityPropertyType.Date,
        value: comparisonDate.value,
        displaySubValue: comparisonDate.displaySubValue,
        displayValue: comparisonDate.name,
        source: comparisonDate.source,
      });
    }

    // Select [All] option
    let newChip: BenefitDetail;

    if (benefitOption === ExceptionOption.All) {
      newChip = {
        benefitName: 'All Benefit Options',
        benefitId: undefined,
        benefitOptions: BenefitOptions.All,
        additionalInfos,
      };
    }
    // Select [Specific] option
    else {
      newChip = {
        benefitName: benefitOption.name,
        benefitId: benefitOption.id,
        benefitOptions: BenefitOptions.Single,
        additionalInfos,
      };
    }

    return this.newChipControl(newChip);
  }

  private newChipControl(value?: BenefitDetail): FormControl {
    return this.fb.control(value);
  }

  private get initFormGroup(): FormGroup {
    return this.fb.group({
      description: [null],

      isEditingValidation: [null],

      benefitOption: [null],
      additionalBenefitValue: [null],
      outputMapping: [null],

      validationDate: [null],
      validationInterval: [null],
      comparisonDate: [null],

      entity: [null],
      property: [null],
      additionalEntityValue: [null],
      additionalEntityValueForChild: [null],

      benefitOptionChipList: this.fb.array([]),
    });
  }

  private resetFormData(): void {
    this.benefitOptionChipListControl.clear();
    this.editForm.reset();
    this.isFormDirty = false;
    this.outputMappingList = [];
    this.comparisonDateList = [];
    this.propertyList = [];
    this.entityValueList = [];
  }

  private initEntityForm(): void {
    const savedPropertyDetail: PropertyDetail = this.savedEntityDetailList[0];

    const savedEntityOption: Option | undefined = this.entityList?.find(
      (item) => item.value.entityComponentId === savedPropertyDetail.entityComponentId,
    );
    // Append saved [Entity Detail] data
    this.entityControl.setValue(savedEntityOption?.value ?? null);
    this.selectedEntityComponentId = (savedEntityOption?.value as Entity)?.entityComponentId ?? '';
    // Init [Entity Detail] dropdown list
    this.propertyList = savedEntityOption?.value?.properties ?? [];

    const savedPropertyOption: Option | undefined = savedEntityOption?.value?.properties?.find(
      (item: Option) => item?.value?.id === savedPropertyDetail?.entityPropertyId,
    );
    // Append saved [Property Detail] data
    this.propertyControl.setValue(savedPropertyOption?.value ?? null);
    this.selectedPropertyId = (savedPropertyOption?.value as EntityProperty)?.id ?? '';
    // Init [Property Detail] dropdown list
    this.entityValueList = savedPropertyOption?.value?.propertyLookupValues ?? [];

    const additionalInfos: AdditionalInfo[] = savedPropertyDetail?.additionalInfos;
    const { savedAdditionalEntityValueOption, savedAdditionalEntityValueChildOption } =
      this.editExceptionComponentService.getInitPropertyDetailAdditionalInfos(this.entityValueList, additionalInfos);

    if (additionalInfos?.length && savedAdditionalEntityValueOption) {
      // Hide saved [Entity Value Detail] data
      savedAdditionalEntityValueOption.isHide = true;
      // Append saved [Entity Value Detail] data
      this.additionalEntityValueControl.setValue(savedAdditionalEntityValueOption?.value ?? null);
      this.selectedAdditionalEntityValueId = (savedAdditionalEntityValueOption?.value as LookupInformation)?.id ?? '';
    }
    if (additionalInfos?.length > 1 && this.isSurvivorNotFound && savedAdditionalEntityValueChildOption) {
      // Hide saved [Entity Value for Child Detail] data
      savedAdditionalEntityValueChildOption.isHide = true;
      // Append saved [Entity Value for Child Detail] data
      this.additionalEntityValueForChildControl.setValue(savedAdditionalEntityValueChildOption?.value ?? null);
    }
    if (
      this.exceptionType &&
      [ExceptionType.SpouseNotFound, ExceptionType.MultipleSpousesFound, ExceptionType.SpouseMissingDOB].includes(
        this.exceptionType,
      )
    ) {
      this.entityControl.addValidators(this.entityIsEditableValidator());
      this.propertyControl.addValidators(this.propertyIsEditableValidator());
      this.additionalEntityValueControl.addValidators(this.additionEntityValueIsEditableValidator());
    }
  }

  private initBenefitChipForm(): void {
    this.benefitOptionChipListControl.clear();

    this.savedBenefitDetailList.forEach((item: BenefitDetail) => {
      item = {
        ...item,
        additionalInfos: this.editExceptionComponentService.getInitBenefitDetailAdditionalInfos(
          item.additionalInfos,
          this.exceptionType,
          this.isShowBenefitBeginDateDropdown,
          this.isDateValueValidation,
        ),
      };
      this.benefitOptionChipListControl.push(this.newChipControl(item));
    });
  }

  private get isEditFormValid(): boolean {
    this.entityControl.markAllAsTouched();
    this.propertyControl.markAllAsTouched();
    this.additionalEntityValueControl.markAllAsTouched();
    this.additionalEntityValueForChildControl.markAllAsTouched();

    return (
      this.entityControl.valid &&
      this.propertyControl.valid &&
      this.additionalEntityValueControl.valid &&
      this.additionalEntityValueForChildControl.valid
    );
  }

  private manipulateSubmitData(formValue: any): SaveExceptionConfigurationDetailRequest {
    const exceptionDescription: string = formValue.description;
    const benefitOptionChipList: BenefitDetail[] = formValue.benefitOptionChipList;

    const entity: Entity = formValue.entity;
    const property: EntityProperty = formValue.property;
    const additionalEntityValue: LookupInformation = formValue.additionalEntityValue;
    const additionalEntityValueForChild: LookupInformation = formValue.additionalEntityValueForChild;

    const additionalInfos: AdditionalInfo[] = [];

    if (additionalEntityValue) {
      additionalInfos.push({
        name: additionalEntityValue.text,
        type: property.type,
        value: additionalEntityValue.id,
      });
    }
    if (additionalEntityValueForChild) {
      additionalInfos.push({
        name: additionalEntityValueForChild.text,
        type: property.type,
        value: additionalEntityValueForChild.id,
      });
    }

    const exceptionBenefits: ExceptionBenefit[] = benefitOptionChipList.map(
      ({
        benefitName: benefitTypeName,
        benefitId: benefitTypeId,
        benefitOptions = BenefitOptions.Single,
        additionalInfos,
      }: BenefitDetail) => ({
        benefitTypeId,
        benefitTypeName,
        benefitOptions,
        benefitEntityId: benefitTypeId,
        additionalInfos: additionalInfos?.map((item) => ({
          name: item.displayValue ?? item.name ?? '',
          type: item.type,
          value: item.value,
          source: item?.source,
        })),
      }),
    );

    let exceptionProperties: ExceptionProperties[] = [];

    if (this.isShowEntityListDropdown) {
      exceptionProperties = [
        {
          entityComponentId: property?.entityComponentId,
          entityPropertyId: property?.id,
          listName: entity?.name,
          propertyName: property?.propertyName,
          additionalInfos,
        },
      ];
    }

    const isEditingValidation: boolean | null = formValue.isEditingValidation;

    return { exceptionDescription, exceptionBenefits, exceptionProperties, isEditingValidation };
  }

  private listenFormDirty(): void {
    this.editForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isFormDirty = this.editForm.dirty;
    });
  }

  private entityIsEditableValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('entity')?.value) {
        return null;
      }
      const { value: entity } = control.parent.get('entity') as FormControl;

      if (
        !this.isPropertiesEditable &&
        this.selectedEntityComponentId &&
        (entity as Entity).entityComponentId !== this.selectedEntityComponentId
      ) {
        return { entityIsUneditable: 'Exception is being used, cannot update this field.' };
      }
      return null;
    };
  }

  private propertyIsEditableValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('property')?.value) {
        return null;
      }
      const { value: property } = control.parent.get('property') as FormControl;

      if (
        !this.isPropertiesEditable &&
        this.selectedPropertyId &&
        (property as EntityProperty).id !== this.selectedPropertyId
      ) {
        return { entityIsUneditable: 'Exception is being used, cannot update this field.' };
      }
      return null;
    };
  }

  private additionEntityValueIsEditableValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('additionalEntityValue')?.value) {
        return null;
      }
      const { value: additionalEntityValue } = control.parent.get('additionalEntityValue') as FormControl;

      if (
        !this.isPropertiesEditable &&
        this.selectedAdditionalEntityValueId &&
        (additionalEntityValue as LookupInformation).id !== this.selectedAdditionalEntityValueId
      ) {
        return { entityIsUneditable: 'Exception is being used, cannot update this field.' };
      }
      return null;
    };
  }
}
