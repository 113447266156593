<div class="buyback-makeup-overview-container" id="buyback-makeup-overview-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <ptg-overview-header-entity></ptg-overview-header-entity>

  <div class="buyback-makeup-wrapper">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
      [subHeaderLeftTitle]="'Buyback/Make-up Eligibility'"
    ></ptg-sub-header>
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <ng-container>
      <div class="initiate-remittance-section">
        <div class="wrap-btn">
          <ptg-button
            [buttonName]="initiateRemittenanceBtnName"
            classInput="primary initiate-button"
            (clickButton)="onClickInitiateRemittenance()"
            [isDisabled]="
              !isEnableInitiateRemittanceBtn ||
              isInitiating ||
              allBuyBackMakeUpRecordsAreRemittanceInitiated ||
              buyBackMakeUpTableData.length === 0
            "
          ></ptg-button>
        </div>
        <span class="loading-icon" *ngIf="isInitiating">
          <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
          </mat-progress-spinner>
        </span>
      </div>
    </ng-container>

    <div class="buyback-makeup-overview-grid flex-grow overflow-hidden" *ngIf="totalBuyBackMakeUp > 0">
      <div class="header">
        <span class="title font-bold">{{ buyBackMakeUpDetailTableName }}</span>
      </div>
      <ptg-grid
        [data]="buyBackMakeUpDetailTableData"
        [columns]="columnsBuyBackMakeUpDetail"
        [totalRecords]="totalBuyBackMakeUpDetail"
        [isLoading]="isBuyBackMakeUpDetailLoading"
        [pageSize]="pageBuyBackMakeUpDetailSize"
        [pageNumber]="pageBuyBackMakeUpDetailIndex"
        [notFoundMessage]="noDataBuyBackMakeUpDetail"
        (sortChange)="onChangeSortBuyBackMakeUpDetail($event)"
        (pageChange)="onChangePageBuyBackMakeUpDetail($event)"
        [fitToParent]="true"
        [hideScrollbar]="false"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            *ngIf="currentBuyBackMakeUpStatusCode === 0"
            stopPropagation
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="handleEditBuybackMakeupYearRecord(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>

    <div class="buyback-makeup-overview-grid flex-grow overflow-hidden">
      <div class="flex-grow overflow-hidden">
        <div class="header flex">
          <span class="title font-bold buyback-makeup-table-name">{{ buyBackMakeUpTableName }}</span>
          <ptg-button
            *ngIf="totalBuyBackMakeUp === 0 || allBuyBackMakeUpRecordsAreRemittanceInitiated"
            buttonName="Add Buyback/Make-up"
            classInput="add-button"
            class="add-buyback-makeup"
            (clickButton)="openAddEditBuyBackMakeUpDialog()"
          >
            <mat-icon class="action-icon">add</mat-icon>
          </ptg-button>
        </div>
      </div>
      <ptg-grid
        [data]="buyBackMakeUpTableData"
        [columns]="columnsBuyBackMakeUp"
        [totalRecords]="totalBuyBackMakeUp"
        [isLoading]="isBuyBackMakeUpLoading"
        [pageSize]="pageBuyBackMakeUpSize"
        [pageNumber]="pageBuyBackMakeUpIndex"
        [notFoundMessage]="noDataBuyBackMakeUp"
        (sortChange)="onChangeSortBuyBackMakeUp($event)"
        (pageChange)="onChangePageBuyBackMakeUp($event)"
        (rowClick)="onClickBuyBackMakeUp($event)"
        [fitToParent]="true"
        [hideScrollbar]="false"
        [(currentRowIndex)]="currentRowIndex"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            *ngIf="row.statusCode === 0"
            stopPropagation
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="openAddEditBuyBackMakeUpDialog(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            *ngIf="row.statusCode === 0"
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveBuyBackMakeUp(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
