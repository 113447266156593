<div class="document-preview-container">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" (clickBreadcrumb)="onClickBreadcrumb()"></ptg-breadcrumb>
    <ng-container *ngIf="entityType === EntityType.Participant">
      <ptg-overview-header-entity
        [isShowChangeMemberDetailBtn]="false"
        [targetIdFromDialog]="targetId"
        [isSubHeader]="true"
        [shouldHideStatusDateProperty]="true"
        [shouldHideStatusEventProperty]="true">
      </ptg-overview-header-entity>
    </ng-container>
    <div class="info-edit">
      <div class="form">
        <div class="form-container">
          <div class="wrap-btn">
            <button mat-raised-button type="submit" class="submit-button" (click)="onSubmit()">Save Document</button>
            <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">
              Cancel
            </button>
          </div>
          <div class="form-content">
            <div class="column-section">
              <div class="half-row">
                <div class="value-container">
                  <span class="label truncate">Template</span>
                  <div class="value-item">{{templateName}}</div>
                </div>
              </div>
              <div class="half-row">
                <div class="value-container">
                  <span class="label truncate">File Extension</span>
                  <div class="value-item">{{fileTypeName}}</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="document-preview-content">
        <ngx-extended-pdf-viewer
          *ngIf="documentPreviewUrl"
          backgroundColor="#fff"
          [src]="documentPreviewUrl"
          [pageViewMode]="'infinite-scroll'"
          [showToolbar]="false"
          [showSidebarButton]="false"
          [showFindButton]="true"
          [showPagingButtons]="false"
          [showDrawEditor]="false"
          [showStampEditor]="false"
          [showTextEditor]="false"
          [showZoomButtons]="false"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showDownloadButton]="false"
          [showSecondaryToolbarButton]="false"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </ng-template>
</div>
