<div class="custom-table-container" [ngClass]="{ 'hide-scrollbar': hideScroll }">
  <table
    #table
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortDisabled]="!sortHeader && !onlySortDateTime"
    (matSortChange)="sortData($event)"
    class="mat-elevation-z8 custom-table"
    [ngClass]="{
      'default-padding':
        !isNotSetDefaultPadding &&
        !canDropDrag &&
        ![
          STYLE_COLUMN.CHECKBOX,
          STYLE_COLUMN.ICON_LOCK,
          STYLE_COLUMN.ICON_NOT_REGISTERED,
          STYLE_COLUMN.ICON_REGISTERED
        ].includes(styleDisplayedColumns[0])
    }"
    cdkDropList
    [cdkDropListDisabled]="!canDropDrag"
    (cdkDropListDropped)="dropTable($event)"
    [cdkDropListSortPredicate]="customPredicate()"
  >
    <ng-container *ngFor="let item of styleDisplayedColumns; index as i">
      <ng-container [ngSwitch]="item">
        <ng-container *ngSwitchCase="STYLE_COLUMN.CHECKBOX" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="dataTable?.length > 0">
              <mat-checkbox
                *ngIf="showSelectAllCheckBox"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              *ngIf="!row.dontShowCheckBox"
              (click)="$event.stopPropagation()"
              (change)="$event.checked ? checked(row) : unChecked(row)"
              [checked]="isChecked(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.NUMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header [disabled]="!sortHeader">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element" class="align-right">
            <ng-container *ngIf="!element.enable">
              <span
                [ngClass]="{
                  red_color:
                    element[displayedColumns[i]] > 0 && displayedColumns[i] === 'errors' && pageName === 'Notifications'
                }"
                >{{ element[displayedColumns[i]] }}
              </span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT" [matColumnDef]="displayedColumns[i]">
          <ng-container
            *ngIf="
              displayedColumns[i] !== 'description' &&
                displayedColumns[i] !== 'status' &&
                !isTableMemberSection &&
                !onlySortDateTime;
              else noSortColumn
            "
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #noSortColumn>
            <th
              mat-header-cell
              *matHeaderCellDef
              (mouseenter)="hoverCell($event)"
              (mouseleave)="outCell()"
              matTooltipClass="cs-tooltip"
              [matTooltipDisabled]="!isTruncated"
              [matTooltip]="headerColumns[i]"
            >
              {{ this.headerColumns[i] }}
            </th>
          </ng-template>

          <ng-container *ngIf="displayedColumns[i] === demoField; else commonText">
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element?.isDemo" [class.demo-cell]="element?.isDemo">
                <span class="first-element">{{ element[displayedColumns[i]] }}</span>
                <span class="second-element">{{ element?.isDemo ? '(Demo)' : '' }}</span>
              </div>
              <ng-container *ngIf="!element?.isDemo">{{ element[displayedColumns[i]] }}</ng-container>
            </td>
          </ng-container>
          <ng-template #commonText>
            <ng-container *ngIf="!needTooltip">
              <td mat-cell *matCellDef="let element">
                {{ element[displayedColumns[i]] }}
              </td>
            </ng-container>
            <ng-container *ngIf="needTooltip">
              <td
                (mouseenter)="hoverCell($event, displayedColumns[i])"
                (mouseleave)="outCell()"
                mat-cell
                *matCellDef="let element"
                matTooltipClass="cs-tooltip"
                [matTooltipDisabled]="!isTruncated"
                [matTooltip]="element[displayedColumns[i]]"
              >
                <ng-container *ngIf="!element.enable">
                  <span [ngClass]="{ gray_color: (element.archived || element.oder === -1) && pageName === 'Category' }"
                    >{{ element[displayedColumns[i]] }}
                  </span>
                </ng-container>
                <ng-container *ngIf="element.enable && pageName === 'Category'">
                  <ptg-textbox
                    [controlField]="formItems.controls[element.index]?.controls?.categoryName"
                    [maxLength]="100"
                    [isRequired]="true"
                    errorAsync="Category already exists."
                    errorRequire="Category is required."
                  ></ptg-textbox>
                </ng-container>
                <ng-container *ngIf="element.enable && pageName === 'Navigation'">
                  <pre>{{ valueBlank }}</pre>
                  <ptg-textbox
                    [controlField]="formItems.controls[element.index]?.controls?.navigation"
                    [maxLength]="255"
                    [isRequired]="true"
                    errorAsync="Navigation Label already exists."
                    errorRequire="Navigation Label is required."
                  ></ptg-textbox>
                </ng-container>
              </td>
            </ng-container>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT_HTML" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            (mouseenter)="hoverCell($event, displayedColumns[i])"
            (mouseleave)="outCell()"
            [tooltip]="element[displayedColumns[i]]"
            [hideDelay]="100"
            [tooltipClass]="isTruncated ? 'tooltip-html' : 'tooltip-hide'"
          >
            <p #textShow style="display: none" [innerHTML]="element[displayedColumns[i]]"></p>
            {{ textShow.innerText }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DATE_TIME" [matColumnDef]="displayedColumns[i]">
          <ng-container *ngIf="displayedColumns[i] === 'dateSent'; else SortColumn">
            <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #SortColumn>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-template>
          <td mat-cell *matCellDef="let element">
            {{ element[displayedColumns[i]] | date: (hideTime ? 'MM/dd/yyyy' : 'MM/dd/yyyy h:mm a') }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_NOT_REGISTERED" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-center">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <img
              *ngIf="
                (!element.directoryId && pageName !== 'Account') ||
                (!element.isEmailConfirmed && pageName === 'Account')
              "
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAdCAYAAADLnm6HAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFSSURBVEhL7ZTbToNAEIZ9/5fQxMTE6KWJXmr0wlYs1kOjQVrb2kOq1NJK6SF2zExYQimzLrikN3zJH2APk49llx3YMoVAIVAIsAKr1Q/Y1jkMnUbQkg+swNQbwm15H4zSXiixWEyhYV9B/fVSKa1mGZZLn+ZySD8BFri53l2TwCs+Y7tKbOuC5nFIBfAtRKGsEs9PpzSHQ1kAk0VCqwAmrYR2AUwaiVwEMKoSWgR63Qf4GFgUPIaieFRiMu6HY0S0Ccxmk6AFwsJJEnG0CVQrh1A1jyimcbAmgImvxP3dMY3FPi0CtccTKsQl/seM7gnsl5H6EyQx+nrfWAkh8S8BseFabyXotE022I/jkiReamd0zyEVcEedcCnTJCoxn3/TlUMqgPi+C2O3pxzns76xJ2T8KZAFPAlCwvOcoDWZXAQQlGg3jeCJJzcBVQqBLQsA/AJzct3QH78+fwAAAABJRU5ErkJggg=="
            />
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_LOCK" [matColumnDef]="displayedColumns[i]">
          <th class="lock-column" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortHeader">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon
              *ngIf="element.isLocked || element.status === true || element[displayedColumns[i]]"
              style="color: #cbc89f"
              >lock</mat-icon
            >
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_BIO" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-center">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <mat-icon *ngIf="element[displayedColumns[i]]">check</mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_DOWNLOAD" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="download-button" (click)="fnButtonDownload($event, element.file)">save_alt</mat-icon>
            {{ element[displayedColumns[i]] }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.LINK" [matColumnDef]="displayedColumns[i]">
          <ng-container *ngIf="configValueColumns && configValueColumns[i]?.isSortColumn; else noSortColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #noSortColumn>
            <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          </ng-template>
          <td
            mat-cell
            *matCellDef="let element"
            matTooltipClass="cs-tooltip"
            matTooltipPosition="below"
            [matTooltip]="element[displayedColumns[i]]"
            (mouseenter)="hoverCell($event, displayedColumns[i])"
            (mouseleave)="outCell()"
            [matTooltipDisabled]="!isTruncated"
          >
            <a
              *ngIf="!!element[displayedColumns[i]]; else defaultValue"
              href="javascript:void(0)"
              class="ignore-row"
              (click)="onClickLink(element)"
              >{{ element[displayedColumns[i]] }}</a
            >
            <ng-template #defaultValue>
              <span>{{ element[displayedColumns[i] + 'Default'] || '' }}</span>
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.FILE_SIZE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-right">{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element" class="align-right">
            {{ element[displayedColumns[i]] | fileSize }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.IMAGE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <img
              class="table-image"
              *ngIf="element[displayedColumns[i]]; else imageOutlet"
              [src]="element[displayedColumns[i]].uri"
              alt="image-member"
            />
            <ng-template #imageOutlet>
              <!-- <mat-icon>account_box</mat-icon> -->
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.STATUS" [matColumnDef]="displayedColumns[i]">
          <ng-container *ngIf="!sortStatus; else withStatus">
            <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #withStatus>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-template>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="statusWithIcon; else statusNoIconOutlet">
              <ng-container *ngIf="element[displayedColumns[i]] === 'Active'">
                <mat-icon class="icon-status" style="color: #199aef; vertical-align: middle">check_circle</mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'Retired'">
                <mat-icon class="icon-status" svgIcon="thumbs-up" style="vertical-align: middle"></mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'Inactive'">
                <mat-icon class="icon-status" style="color: #d1d1d1; vertical-align: middle">remove_circle</mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'DROP'">
                <mat-icon class="icon-status" style="color: #ed9303; vertical-align: middle">check_circle</mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'Sending'">
                <mat-icon class="icon-status" style="color: #2d6c71; vertical-align: middle">send</mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'In Review'">
                <mat-icon
                  class="icon-status"
                  svgIcon="in-review"
                  style="color: #fbc94a; vertical-align: middle"
                ></mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'Scheduled'">
                <mat-icon
                  class="icon-status"
                  svgIcon="scheduled"
                  style="color: #fd8a03; vertical-align: middle"
                ></mat-icon>
              </ng-container>
              <ng-container *ngIf="element[displayedColumns[i]] === 'Sent'">
                <mat-icon class="icon-status" style="color: #14c457; vertical-align: middle">check_circle</mat-icon>
              </ng-container>
              {{ element[displayedColumns[i]] }}
            </ng-container>
            <ng-template #statusNoIconOutlet>
              <mat-icon class="fund-status" [ngClass]="{ active: element[displayedColumns[i]] }"
                >fiber_manual_record</mat-icon
              >
              {{ element[displayedColumns[i]] ? 'Active' : 'Inactive' }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.CURRENCY" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-right">{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="align-right">
              <span style="color: #707070" *ngIf="element[displayedColumns[i]] | numberLocalDecimal: ''">$</span
              ><span>{{ element[displayedColumns[i]] | numberLocalDecimal: '' }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DECIMAL" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-right">{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="align-right">
              <span *ngIf="configValueColumns && configValueColumns[i]?.fractionalLengthInput; else decimalNoConfig">
                {{
                  element[displayedColumns[i]]
                    | numberDecimal
                      : { decimal: configValueColumns[i]?.fractionalLengthInput, isCheckCurrentDecimal: true }
                }}
              </span>
              <ng-template #decimalNoConfig>
                <span>{{ element[displayedColumns[i]] }}</span>
              </ng-template>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.WHOLE_NUMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header [disabled]="!sortHeader">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="align-right">
              <span>{{ element[displayedColumns[i]] | numberDecimal }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_VIEW" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button
              buttonName="View"
              classInput="view-button"
              (clickButton)="fnButtonView($event, element)"
            ></ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_LIST" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let idx = index">
            <ptg-button
              (mouseDownButton)="freeDragArea()"
              classInput="list-button ignore-row"
              [isDisabled]="!canDropDragStatic && element.isStatic"
            >
              <mat-icon
                class="ignore-row"
                aria-hidden="false"
                aria-label="drag-handle"
                svgIcon="drag-handle"
              ></mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_EDIT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="fnButtonView($event, element)">
              <mat-icon>edit</mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_EDIT_ROW" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="!element.enable && !element.isDeleted">
              <ng-container *ngIf="pageName === 'Category'">
                <ng-container *ngIf="!element.isUsed">
                  <ptg-button
                    classInput="delete-button"
                    (clickButton)="element.isDeleted = true; fnActionsRow($event, element, 'delete')"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </ng-container>
                <ng-container *ngIf="element.isUsed">
                  <ptg-button
                    classInput="archive-button"
                    (clickButton)="element.archived = true; fnActionsRow($event, element, 'archive')"
                  >
                    <mat-icon>archive</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="pageName === 'Property Display Config'">
                <ng-container *ngIf="!element.isUsed">
                  <ptg-button
                    (clickButton)="element.isUsed = true; fnActionsRow($event, element, 'addSortRow')"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-container>
              <ptg-button
                classInput="edit-button"
                (clickButton)="element.enable = true; fnActionsRow($event, element, 'edit')"
              >
                <mat-icon>edit</mat-icon>
              </ptg-button>
              <ng-container *ngIf="pageName === 'Property Display Config' && !element.isDeleted">
                <ptg-button
                  (clickButton)="element.isDeleted = true; fnActionsRow($event, element, 'remove')"
                  classInput="edit-button"
                >
                  <mat-icon>delete_forever</mat-icon>
                </ptg-button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="element.enable">
              <ptg-button
                classInput="yesno-button"
                (clickButton)="element.enable = false; fnActionsRow($event, element, 'close')"
              >
                <mat-icon>close</mat-icon>
              </ptg-button>
              <ng-container *ngIf="pageName === 'Navigation'">
                <ptg-button classInput="yesno-button" (clickButton)="fnActionsRow($event, element, 'checkmark')">
                  <mat-icon style="color: #196f57">check</mat-icon>
                </ptg-button>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_RESTORE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button
              classInput="restore-button"
              (clickButton)="element.archived = false; fnActionsRow($event, element, 'restore')"
            >
              <mat-icon>restore</mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_MEMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button buttonName="View" classInput="member-button" (clickButton)="fnButtonMember($event, element)">
              <mat-icon svgIcon="group-account" aria-hidden="false" aria-label="group-account-icon"></mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_FUND" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let fundIndex = index">
            <ptg-button
              buttonName="View"
              classInput="fund-button"
              (clickButton)="fnButtonFund($event, element)"
              buttonId="button-fund-{{ fundIndex }}"
            >
              <!-- <mat-icon svgIcon="fund" aria-hidden="false" aria-label="fund-icon"></mat-icon> -->
              <mat-icon>settings</mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_APP_CONTENT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let fundIndex = index">
            <ptg-button
              buttonName="View"
              classInput="fund-button"
              (clickButton)="fnButtonAppContent($event, element)"
              buttonId="button-fund-{{ fundIndex }}"
            >
              <!-- <mat-icon svgIcon="fund" aria-hidden="false" aria-label="fund-icon"></mat-icon> -->
              <mat-icon>phonelink_setup</mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_RESOURCE_CENTER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button
              buttonName="Resource Center"
              classInput="resourceCenter-button"
              (clickButton)="fnButtonResourceCenter($event, element)"
            >
              <mat-icon aria-hidden="false" aria-label="resourceCenter-icon" svgIcon="insert-drive-file"></mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_REMOVE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                (demoField === 'name' && element?.isDemo) ||
                (isHistory &&
                  (element.status === 'Invalid' || element.status === 'Canceled' || element.status === 'Erroneous')) ||
                haveRemove ||
                (pageName === 'Account' && element.email !== currentUserEmail) ||
                (pageName === 'Property' && element.isStatic === false) ||
                (pageName === 'Metadata' && element.isStatic === false)
              "
            >
              <ptg-button
                buttonName="Remove"
                classInput="remove-button ignore-row"
                (clickButton)="fnButtonRemove($event, element)"
              >
                <mat-icon class="ignore-row">delete_forever</mat-icon>
              </ptg-button>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_REMOVE_ACCOUNT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button
              buttonName="Remove"
              classInput="removeAcc-button ignore-row"
              (clickButton)="fnButtonRemove($event, element)"
            >
              <mat-icon
                class="ignore-row"
                svgIcon="remove-account"
                aria-hidden="false"
                aria-label="remove-account-icon"
              ></mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.GROUP_BUTTON" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element._identityKey || element._canRemove">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Icon-button with a menu"
                class="group-button ignore-row"
              >
                <mat-icon class="ignore-row">more_vert</mat-icon>
                <mat-icon class="ignore-row icon-drop-down" svgIcon="arrowDropDown"></mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="custom-group-button" xPosition="before">
                <ng-container>
                  <button mat-menu-item (click)="groupButtonFn.lockMember(element)" *ngIf="element._identityKey">
                    <ng-container *ngIf="!element._isLocked; else unlock">
                      <mat-icon style="color: #629dba">lock</mat-icon>
                      <span>Lock Participant</span>
                    </ng-container>

                    <ng-template #unlock>
                      <mat-icon style="color: #629dba">lock_open</mat-icon>
                      <span>Unlock Participant</span>
                    </ng-template>
                  </button>
                  <button mat-menu-item (click)="groupButtonFn.removeMember(element)" *ngIf="element._canRemove">
                    <mat-icon svgIcon="remove-account" aria-hidden="false" aria-label="remove-account-icon"></mat-icon>
                    <span>Remove Participant</span>
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_TOGGLE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-toggle-button
              [controlField]="formItems.controls[element.index]?.controls?.active"
              (valueChange)="fnActionsRow($event, element, 'changeToggle')"
              [single]="true"
            ></ptg-toggle-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DATE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                configValueColumns && configValueColumns[i]?.isShowNullIcon && !element[displayedColumns[i]];
                else hasValue
              "
              >-</ng-container
            >
            <ng-template #hasValue>
              {{ element[displayedColumns[i]] | date: 'MM/dd/yyyy' }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DATE_RANGE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.start | date: 'MM/dd/yyyy' }} - {{ element?.end | date: 'MM/dd/yyyy' }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ACTION" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="fnButtonView($event, element)">
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              *ngIf="
                !(
                  (pageName === 'Profile Overview View' && element.isStatic) ||
                  (pageName === 'Profile Header View' && element.isStatic)
                )
              "
              buttonName="Remove"
              classInput="edit-button"
              (clickButton)="fnButtonRemove($event, element)"
            >
              <mat-icon class="ignore-row">delete_forever</mat-icon>
            </ptg-button>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ADDRESS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] | address }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PERSON_NAME" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] | personName }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PHONE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] | phoneNumber }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.LOOKUP" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.EMPLOYER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DEPARTMENT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BINARY" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.EMAIL" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PHONE_NUMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] | phoneNumber }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.SSN_NUMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] | ssnNumber }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_MARK" [matColumnDef]="displayedColumns[i]">
          <ng-container *ngIf="!noSortIconMarkColumn; else noSortColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #noSortColumn>
            <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          </ng-template>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="check-circle" *ngIf="element.showMark">check_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_DISABLED" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.isDisabled" style="color: #707070; margin-left: 15px">domain_disabled</mat-icon>
          </td>
        </ng-container>

        <ng-container
          *ngSwitchCase="STYLE_COLUMN.PERSON_OUTLINE || STYLE_COLUMN.MISSING_ADDRESS"
          [matColumnDef]="displayedColumns[i]"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon
              *ngIf="
                element.representativePayee !== null &&
                (element.representativePayee?.address?.city !== null ||
                  element.representativePayee?.address?.country !== null ||
                  element.representativePayee?.address?.countryCode !== null ||
                  element.representativePayee?.address?.state !== null ||
                  element.representativePayee?.address?.stateCode !== null ||
                  element.representativePayee?.address?.street1 !== null ||
                  element.representativePayee?.address?.street2 !== null ||
                  element.representativePayee?.address?.zipCode !== null) &&
                (element.representativePayee?.name?.first !== null ||
                  element.representativePayee?.name?.last !== null ||
                  element.representativePayee?.name?.middle !== null ||
                  element.representativePayee?.name?.prefix !== null ||
                  element.representativePayee?.name?.suffix !== null)
              "
              class="person-outline"
              [matTooltip]="representativeTooltip + (element.representativePayee.name | customPersonName)"
              matTooltipClass="custom-tooltip"
              >person_outline</mat-icon
            >
            <mat-icon
              *ngIf="
                element.representativePayee !== null &&
                ((element.representativePayee?.address?.city === null &&
                  element.representativePayee?.address?.country === null &&
                  element.representativePayee?.address?.countryCode === null &&
                  element.representativePayee?.address?.state === null &&
                  element.representativePayee?.address?.stateCode === null &&
                  element.representativePayee?.address?.street1 === null &&
                  element.representativePayee?.address?.street2 === null &&
                  element.representativePayee?.address?.zipCode === null) ||
                  (element.representativePayee?.name?.first === null &&
                    element.representativePayee?.name?.last === null &&
                    element.representativePayee?.name?.middle === null &&
                    element.representativePayee?.name?.prefix === null &&
                    element.representativePayee?.name?.suffix === null))
              "
              class="missing-person-outline"
              [matTooltip]="'Missing Representative Info'"
              matTooltipClass="custom-tooltip"
              >person_outline</mat-icon
            >
            <mat-icon
              *ngIf="element.isMissingPaymentAddress"
              svgIcon="missing-address"
              class="missing-address"
              [matTooltip]="'Missing Payment Address'"
              matTooltipClass="custom-tooltip"
            ></mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.MISSING_ADDRESS" [matColumnDef]="displayedColumns[i]">
          <th [hidden]="true" mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td [hidden]="true" mat-cell *matCellDef="let element">
            <mat-icon
              *ngIf="element.isMissingPaymentAddress"
              svgIcon="missing-address"
              class="missing-address"
              [matTooltip]="'Missing Payment Address'"
              matTooltipClass="custom-tooltip"
            ></mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DEDUCTION_ICON_DISABLED" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.isDisabled" svgIcon="deduction-disable" style="margin-left: 15px"></mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.DESCRIPTION_ENABLE_SORT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">{{ element[displayedColumns[i]] }}</td>
        </ng-container>

        <!-- <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_NOTE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.headerColumns[i]}}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.hasNote" svgIcon="note-icon" class="note-icon"></mat-icon>
          </td>
        </ng-container> -->

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_NOTE" [matColumnDef]="displayedColumns[i]">
          <ng-container *ngIf="!noSortNoteColumn; else noSortColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          </ng-container>
          <ng-template #noSortColumn>
            <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          </ng-template>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.hasNote" svgIcon="note-icon" class="note-icon"></mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.NAME_PLUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="name-plus-wrap">
              <span>{{ element[displayedColumns[i]] ? element[displayedColumns[i]] : '-' }}</span>
              <mat-icon *ngIf="element?.isNewPayee || element?.isNew" class="icon-name-plus">add</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT_WITH_EMPTY" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element[displayedColumns[i]] ? element[displayedColumns[i]] : '-' }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT_PLUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="text-plus-wrap">
              <i>{{ element[displayedColumns[i]] }}</i>
              <ng-container *ngIf="!element.suspend && !element?.isNewPayee">
                <mat-icon
                  *ngIf="
                    (element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ||
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ===
                        0) &&
                    element[displayedColumns[i]] <
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)]
                  "
                  class="icon-number-arrow-down posAb"
                  >arrow_drop_down</mat-icon
                >
                <mat-icon
                  *ngIf="
                    (element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ||
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ===
                        0) &&
                    element[displayedColumns[i]] >
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)]
                  "
                  class="icon-number-arrow-up posAb"
                  >arrow_drop_up</mat-icon
                >
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.CURRENCY_PLUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="text-plus-wrap">
              <span>
                <i *ngIf="isNumericFunc(element[displayedColumns[i]])" style="color: #707070; font-style: normal">$</i
                ><span>{{ element[displayedColumns[i]] | numberLocalDecimal: '' }}</span>
              </span>
              <ng-container *ngIf="!element.suspend && !element?.isNewPayee">
                <mat-icon
                  *ngIf="
                    (element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ||
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ===
                        0) &&
                    element[displayedColumns[i]] <
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)]
                  "
                  class="icon-number-arrow-down posAb"
                  >arrow_drop_down</mat-icon
                >
                <mat-icon
                  *ngIf="
                    (element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ||
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)] ===
                        0) &&
                    element[displayedColumns[i]] >
                      element['prev' + displayedColumns[i][0].toUpperCase() + displayedColumns[i].substring(1)]
                  "
                  class="icon-number-arrow-up posAb"
                  >arrow_drop_up</mat-icon
                >
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.ICON_REGISTERED" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef class="align-center">
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element" class="align-center registered-icon">
            <mat-icon class="registered-icon" *ngIf="element?.[displayedColumns[i]]">account_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PAYMENT_TYPE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element[displayedColumns[i]] | paymentStatus }}
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.MULTIPLE_LINE_TEXT" [matColumnDef]="displayedColumns[i]">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let index = index">
            <div
              *ngIf="!element.enable; else editMode"
              [ngClass]="{ 'disabled-text': element.isDeleted }"
              class="multiple-line-text"
            >
              <span class="title">{{ element[displayedColumns[i]] }}</span>
              <span class="description">{{ element[displayedColumns[i] + 'Description'] }}</span>
            </div>

            <ng-template #editMode>
              <ptg-textbox
                [controlField]="formItems.controls?.[index]?.controls?.[displayedColumns[i]]"
                [maxLength]="+formItems.controls?.[index]?.value?.maxLength"
                customError="duplicatedValue"
                [placeholder]="headerColumns[i]"
              ></ptg-textbox>
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.SORT_TYPE" [matColumnDef]="displayedColumns[i]">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let index = index">
            <ng-container *ngIf="!element.isDeleted">
              <ptg-button
                (clickButton)="fnActionsRow($event, element, 'sortChange')"
                *ngIf="element.sortType !== undefined"
              >
                <mat-icon>{{ element[displayedColumns[i]] ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
              </ptg-button>
              <ptg-button
                (clickButton)="element.isDeleted = true; fnActionsRow($event, element, 'remove')"
                classInput="edit-button"
              >
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.MEMBER_STATUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-status-container">
              <mat-icon
                *ngIf="element?._typedValue?.iconName"
                class="icon-status"
                [ngStyle]="{ color: element?._typedValue?.color }"
                >{{ element?._typedValue?.iconName }}</mat-icon
              >
              <div class="status-name">{{ element[displayedColumns[i]] }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.REGISTER_STATUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element" (click)="fnRegisterButtonError(element)">
            <div
              class="icon-status-container"
              #tooltip="matTooltip"
              matTooltip="{{ element?.tooltipMessage }}"
              [matTooltipClass]="
                element?.highlightRed === true ? 'transaction-custom-tooltip' : 'transaction-custom-tooltip-posted'
              "
              [matTooltipDisabled]="!element?.tooltipMessage"
              matTooltipPosition="below"
              [ngStyle]="{ cursor: element?.hasError ? 'pointer' : 'unset' }"
            >
              <mat-icon *ngIf="element?.iconName" class="icon-status" [ngStyle]="{ color: element?.color }">{{
                element?.iconName
              }}</mat-icon>
              <div
                *ngIf="element?.highlightRed && element?.hasError"
                class="exclamation-mark"
                [ngStyle]="{ color: element?.color }"
              >
                <span><strong>&#33;</strong></span>
              </div>
              <div class="status-name">{{ element[displayedColumns[i]] }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.TRANSACTION_STATUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="fnButtonError(element)"
            [ngClass]="{ 'ignore-row': element?.iconName === 'remove_circle' || element?.iconName === 'pending' }"
          >
            <div class="icon-status-container">
              <mat-icon *ngIf="element?.iconName" class="icon-status" [ngStyle]="{ color: element?.color }">{{
                element?.iconName
              }}</mat-icon>

              <div class="status-name">{{ element[displayedColumns[i]] }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PAYMENT_STATUS" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'ignore-row': element?.iconName === 'remove_circle' || element?.iconName === 'pending' }"
          >
            <div
              class="icon-status-container"
              #tooltip="matTooltip"
              matTooltip="{{ element?.tooltipPayStatus }}"
              matTooltipClass="custom-tooltip {{ element?.className }}"
              matTooltipPosition="below"
            >
              <mat-icon *ngIf="element?.iconName" class="icon-status" [ngStyle]="{ color: element?.color }">{{
                element?.iconName
              }}</mat-icon>
              <div class="status-name">{{ element[displayedColumns[i]] }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.EDIT_CHECK_NUMBER" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let idx = index">
            <ng-container *ngIf="element.statusStep === PaymentStep.IssuePayment">
              <ng-container *ngIf="!element?.isEditMode">
                {{ element[displayedColumns[i]] }}
                <ptg-button
                  *ngIf="element?.enable && element?.enableEditCheckNumber"
                  classInput="edit-icon-button"
                  (clickButton)="fnControlEditCheckNumber(element, idx)"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </ng-container>
              <ng-container *ngIf="element?.isEditMode">
                <ptg-textbox
                  [controlField]="controlEditCheckNumber"
                  placeholder="Check Number"
                  [hasLabel]="true"
                  [checkMinMaxValue]="false"
                  [isPositive]="true"
                  [isDecimal]="false"
                  inputType="Number"
                  errorRequire="Check Number is required."
                ></ptg-textbox>
                <ptg-checkbox
                  [controlField]="updateSubsequent"
                  label="Update Subsequent"
                  class="edit-check"
                ></ptg-checkbox>
                <ptg-button classInput="yesno-button" (clickButton)="closeEditCheckNumber(element, idx)">
                  <mat-icon>close</mat-icon>
                </ptg-button>
                <ptg-button classInput="yesno-button" (clickButton)="saveEditCheckNumber(element)">
                  <mat-icon style="color: #196f57">check</mat-icon>
                </ptg-button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="element.statusStep !== PaymentStep.IssuePayment">{{
              element[displayedColumns[i]]
            }}</ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.EDIT_CHECK_NUMBER_PAYMENT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element; let idx = index">
            <ng-container *ngIf="element.statusStep === PaymentStep.Review">
              <ng-container *ngIf="!element?.isEditMode">
                {{ element[displayedColumns[i]] }}
                <ptg-button
                  *ngIf="element?.enableEditCheckNumber"
                  classInput="edit-icon-button"
                  (clickButton)="fnControlEditCheckNumber(element, idx)"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </ng-container>
              <ng-container *ngIf="element?.isEditMode">
                <ptg-textbox
                  [controlField]="controlEditCheckNumber"
                  placeholder="Check Number"
                  [hasLabel]="true"
                  [checkMinMaxValue]="false"
                  [isPositive]="true"
                  [isDecimal]="false"
                  inputType="Number"
                  errorRequire="Check Number is required."
                ></ptg-textbox>
                <ptg-checkbox
                  [controlField]="updateSubsequent"
                  label="Update Subsequent"
                  class="edit-check"
                ></ptg-checkbox>
                <ptg-button classInput="yesno-button" (clickButton)="closeEditCheckNumber(element, idx)">
                  <mat-icon>close</mat-icon>
                </ptg-button>
                <ptg-button classInput="yesno-button" (clickButton)="saveEditCheckNumber(element)">
                  <mat-icon style="color: #196f57">check</mat-icon>
                </ptg-button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="element.statusStep !== PaymentStep.Review">{{
              element[displayedColumns[i]]
            }}</ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.PERCENTAGE" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-right">{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="align-right">
              <span>{{ element[displayedColumns[i]] | numberLocalDecimal: '' }}</span
              ><span style="color: #707070" *ngIf="element[displayedColumns[i]] | numberLocalDecimal: ''">%</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.BALLOON" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element" class="balloon">
            <div
              *ngIf="element[displayedColumns[i]]?.value !== 0 && !element.suspend && !element?.isNewPayee"
              [class.positive]="element[displayedColumns[i]]?.value > 0"
              [class.negative]="element[displayedColumns[i]]?.value < 0"
            >
              <span *ngIf="element[displayedColumns[i]]?.value < 0">- </span>
              <span *ngIf="element[displayedColumns[i]]?.value > 0">+ </span>
              <span *ngIf="!element[displayedColumns[i]]?.preUnit">
                {{ Math.abs(element[displayedColumns[i]]?.value) | number }}
              </span>
              <span *ngIf="element[displayedColumns[i]]?.preUnit">
                {{ element[displayedColumns[i]]?.preUnit }}
                {{ Math.abs(element[displayedColumns[i]]?.value) | number: '1.2-2' }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.CHIP" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="element[displayedColumns[i]]"
              class="chip"
              [ngStyle]="{
                'background-color': element[displayedColumns[i]]?.bgColor || '',
                color: element[displayedColumns[i]]?.color || ''
              }"
            >
              {{ element[displayedColumns[i]]?.value }}
            </div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.COLOR" [matColumnDef]="displayedColumns[i]">
          <th class="align-center" mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td class="align-center" mat-cell *matCellDef="let element">
            <div class="color-div" [style.background]="element[displayedColumns[i]]"></div>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.LIST_STATUS" [matColumnDef]="displayedColumns[i]">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="pageName === 'Profile Overview View' || pageName === 'Profile Header View'"
          >
            {{ this.headerColumns[i] }}
          </th>
          <td mat-cell *matCellDef="let element" class="no-max-width">
            <span class="list-status">
              <span class="status-container" *ngFor="let status of element[displayedColumns[i]]; last as isLast">
                <mat-icon *ngIf="status?.iconName" class="icon-status" [ngStyle]="{ color: status?.color }">{{
                  status?.iconName
                }}</mat-icon>
                <span class="status-name">{{ status?.name }} {{ isLast ? '' : ',' }}</span>
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.RICH_TEXT" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element" class="no-max-width">
            <ptg-view-rich-text
              [title]="this.headerColumns[i]"
              [content]="element[displayedColumns[i]]"
            ></ptg-view-rich-text>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="STYLE_COLUMN.URL" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            <a
              *ngIf="!!element[displayedColumns[i]]; else defaultValue"
              href="javascript:void(0)"
              class="ignore-row"
              (click)="navigateByUrl(element)"
              >{{ element[displayedColumns[i]] }}</a
            >
            <ng-template #defaultValue>
              <span>{{ element[displayedColumns[i] + 'Default'] || '' }}</span>
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchDefault [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>{{ this.headerColumns[i] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element[displayedColumns[i]] }}
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="pageName !== 'Category' && pageName !== 'Navigation'">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isSticky"></tr>
    </ng-container>
    <tr
      mat-row
      *matRowDef="let row; let idx = index; columns: displayedColumns"
      cdkDrag
      [cdkDragData]="row"
      [cdkDragDisabled]="isDragDisabled"
      cdkDragLockAxis="y"
      [class.selected]="tableStriped && row?.id % 2 === 0"
      [class.target-row]="isHighlightRow"
      [class.current-row]="hasCurrentDetail && idx === currentRowIndex"
      [class.grab-row]="canDropDrag"
      [class.no-drag-row]="!canDropDragStatic && row.isStatic"
      [class.delete-row]="row.isDeleted"
      [class.highlight-lightergrey]="row.highlightLighterGrey"
      [class.highlight-yellow]="row.highlightYellow"
      [class.highlight-gray]="row.highlightGray"
      [class.highlight-red]="row.highlightRed"
      [class.italicized-font]="row.italicizedFont"
      (click)="fnActionRow($event, row, idx)"
    ></tr>
  </table>
</div>

<div id="loading-table" #loading *ngIf="isLoading">
  <div class="wrap-item">
    <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
    </mat-progress-spinner>
    <p>Loading...</p>
  </div>
</div>
<div *ngIf="!isLoading && !dataTable?.length" class="data-not-found">
  <div *ngIf="isError" class="warning-icon">
    <mat-icon class="material-icons-round">warning</mat-icon>
  </div>
  <div class="data-not-found-message">
    <ng-container *ngIf="!isError; else unexpectedErrorMessage">
      {{ dataNotFoundMessage }}
    </ng-container>
    <ng-template #unexpectedErrorMessage>
      <span class="error-name">{{ dataNotFoundMessage }}</span>
      <span>occurred, please try again later.</span>
    </ng-template>
  </div>
</div>
