import { Component, ViewChild } from '@angular/core';
import * as fromReducer from '@ptg-reducers';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { takeUntil } from 'rxjs/operators';
import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccidentParticipantsState } from '../../store/reducers/accident-participants.reducer';
import { AccidentClaim, AccidentHistory, AccidentStatus } from '../../services/models';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { AddParticipantAccidentPostCardComponent } from '../../components/add-participant-accident-post-card/add-participant-accident-post-card.component';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import {
  ACTION,
  STATE,
  SortType,
  ADMIN_PORTAL,
} from '@ptg-shared/constance/value.const';
import { clearAccidentHistoryAction, clearAccidentPostCardAction, getAccidentClaimsAction, getAccidentHistoriesAction, removeAccidentHistoryAction } from '../../store/actions';
import { addAccidentPostCardSelector, getAccidentClaimsSelector, getAccidentHistoriesSelector, removeAccidentHistorySelector } from '../../store/selectors';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AccidentType } from '../../constants';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { MenuItemTitle, MenuItemSubTitle } from '@ptg-member/constants';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { DatePipe } from '@angular/common';

const PAGE_SIZE_CONST = '-ptg-accident-claims-pageSize';

@Component({
  selector: 'ptg-accident-claims',
  templateUrl: './accident-claims.component.html',
  styleUrls: ['./accident-claims.component.scss']
})
export class AccidentClaimsComponent extends BaseComponent {
  @ViewChild(OverviewHeaderEntityComponent) overViewHeader!: OverviewHeaderEntityComponent;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Partipant List',
      url: '/member'
    },
    {
      name: 'Accident'
    }
  ];
  menuItemTitle: string = MenuItemTitle.Accident;
  menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;
  listAccidentHistory: AccidentHistory[] = [];
  listAccidentClaims: AccidentClaim[] = [];
  columnsHistory: Column[] = [
    {
      name: 'accidentNumber',
      header: {
        title: 'Accident ID',
      },
      truncate: true,
      sortable: true,
      width: '100px',
    },
    {
      name: 'accidentDate',
      header: {
        title: 'Accident Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'receiveDate',
      header: {
        title: 'Receive Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'injuryCode',
      header: {
        title: 'Injury',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'municipalityCode',
      header: {
        title: 'Municipality',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'accidentStatus',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'isFatal',
      header: {
        title: 'Fatal',
      },
      truncate: true,
      sortable: true
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ]

  columnsClaims: Column[] = [
    {
      name: 'accidentNumber',
      header: {
        title: 'Accident ID',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'accidentDate',
      header: {
        title: 'Accident Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'warrantRegisterNumber',
      header: {
        title: 'Warrant Register Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right
    },
    {
      name: 'warrantBatchNumber',
      header: {
        title: 'Warrant Batch Number',
      },
      align: Align.Right,
      truncate: true,
      sortable: true
    },
    {
      name: 'vendorName',
      header: {
        title: 'Vendor Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'vendorNumber',
      header: {
        title: 'Vendor Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: 'disbursementTypeName',
      header: {
        title: 'Disbursement Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'paymentDate',
      header: {
        title: 'Payment Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'paymentNumber',
      header: {
        title: 'Payment Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right
    },
    {
      name: 'amount',
      header: {
        title: 'Claim Amount',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
  ]
  isLoadingHistory: boolean = true;
  isLoadingClaims: boolean = true;
  sortInfoHistory: {} | any = {};
  sortInfoClaim: {} | any = {};
  lengthPgHistory: number | any;
  lengthPgClaim: number | any;
  pageSizeHistory!: number;
  pageIndexHistory: number = FIRST_PAGE;
  pageSizeClaim!: number;
  pageIndexClaim: number = FIRST_PAGE;
  currentFund: any = {};
  defaultPageSize: number = 50;
  memberId: string = '';
  bannerType: BannerType = BannerType.Hidden;
  accidentType!: AccidentType;
  message = '';
  isPortalUser!: boolean;
  employerId: string = '';
  constructor(
    private fundStore: Store<fromReducer.State>,
    private store: Store<AccidentParticipantsState>,
    private router: Router,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: Auth0Service,
    public datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isPortalUser = this.authService.Role === ADMIN_PORTAL;
    this.employerId = this.authService.employerId ?? '';

    this.getCurrentFundState();
    const accidentHistoryPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + AccidentType.AccidentHistory + PAGE_SIZE_CONST));
    const accidentClaimPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + AccidentType.AccidentClaim + PAGE_SIZE_CONST));
    this.pageSizeHistory = accidentHistoryPageSizeInSession === 0 ? this.defaultPageSize : accidentHistoryPageSizeInSession;
    this.pageSizeClaim = accidentClaimPageSizeInSession === 0 ? this.defaultPageSize : accidentClaimPageSizeInSession;
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.memberId = params.memberId;
      });
    this.store
      .pipe(select(getAccidentHistoriesSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoadingHistory = data?.isLoading;
          this.lengthPgHistory = data?.total;
          this.listAccidentHistory = data?.payload?.map((item: any) => {
            return {
              ...item,
              isFatal: item.isFatal === true ? 'Yes' : item.isFatal === false ? 'No' : '',
              accidentStatus: item?.accidentStatus === 0 ? 'Pending Review' : AccidentStatus[item?.accidentStatus],
              injuryCode:
                item?.injuryCode && item?.injuryCodeDescription
                  ? item.injuryCode + ' - ' + item.injuryCodeDescription
                  : '',
              municipalityCode: item.municipalityCode + ' - ' + item.municipalityName,
            }
          }) ?? [];
        }
      });
    this.getAccidentHistoryList();

    this.store
      .pipe(select(getAccidentClaimsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoadingClaims = data?.isLoading;
          this.lengthPgClaim = data?.total;
          this.listAccidentClaims = (data?.payload?.map((item: AccidentClaim) => {
            return {
              ...item,
              accidentDate: this.datePipe.transform(getDateString(item.accidentDate as string), 'MM/dd/yyyy'),
            }
          }) ?? []) as AccidentClaim[];
        }
      });
    this.getAccidentClaimList();

    this.store
      .pipe(select(removeAccidentHistorySelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          if (state?.accidentHistoryState?.state === STATE.FAIL) {
            if (state?.errorMsg) {
              showBanner.call(this, STATE.FAIL, 'Accident', state?.accidentHistoryState?.action, { customMessage: state?.errorMsg });
              this.store.dispatch(clearAccidentHistoryAction());
            }
          }
          else {
            showBanner.call(this, state?.accidentHistoryState?.state, 'Accident', state?.accidentHistoryState?.action);
          }
          if (state.accidentHistoryState.action === ACTION.REMOVE && state.accidentHistoryState.state === STATE.SUCCESS) {
            if (this.pageIndexHistory !== 1 && this.listAccidentHistory.length <= 1) {
              this.pageIndexHistory = this.pageIndexHistory - 1;
            }
            let sortType = SortType.DESC;
            let sortNames = 'AccidentId';
            if (this.sortInfoHistory?.active && this.sortInfoHistory?.direction) {
              sortNames = capitalizeFirstLetter(this.sortInfoHistory.active);
              sortType =
                this.sortInfoHistory.direction === 'desc' ? SortType.DESC : SortType.ASC;
            }
            const request = {
              pageNumber: this.pageIndexHistory,
              pageSize: this.pageSizeHistory,
              sortNames,
              sortType,
            }
            this.store.dispatch(
              getAccidentHistoriesAction({
                request: request,
                memberId: this.memberId
              })
            );
          }
          this.getAccidentHistoryList();
          this.getAccidentClaimList();
          this.store.dispatch(clearAccidentHistoryAction());
        }
      });
    this.checkStatusAddAccidentParticipantPostCard();
  }

  getCurrentFundState() {
    this.store.pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    ).subscribe(el => {
      this.defaultPageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
    });
  }

  getAccidentHistoryList() {
    let sortType = SortType.DESC;
    let sortNames = ''
    if (this.sortInfoHistory?.active && this.sortInfoHistory?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfoHistory.active);
      sortType =
        this.sortInfoHistory.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getAccidentHistoriesAction({
        request: {
          pageNumber: this.pageIndexHistory,
          pageSize: this.pageSizeHistory,
          sortNames,
          sortType,
        },
        memberId: this.memberId,
        municipalityId: this.employerId,
      })
    );
  }

  getAccidentClaimList() {
    let sortType = SortType.DESC;
    let sortNames = ''
    if (this.sortInfoClaim?.active && this.sortInfoClaim?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfoClaim.active);
      sortType =
        this.sortInfoClaim.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getAccidentClaimsAction({
        request: {
          pageNumber: this.pageIndexClaim,
          pageSize: this.pageSizeClaim,
          sortNames,
          sortType,
        },
        memberId: this.memberId,
        municipalityId: this.employerId,
      })
    );
  }

  onClickAddAccidentPostCard() {
    this.dialog.open(AddParticipantAccidentPostCardComponent, {
      panelClass: ['edit-popup', 'add-participant-accident-post-card-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      ariaLabel: 'Add New Accident Post Card',
      data: {
        accidentPostCard: null,
        memberId: this.memberId,
        isPortalUser: this.isPortalUser,
        employerId: this.employerId,
      }
    });
  }

  onChangeHistoryPage(event: PageEvent) {
    this.pageSizeHistory = event.pageSize;
    this.pageIndexHistory = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + AccidentType.AccidentHistory + PAGE_SIZE_CONST, event.pageSize.toString());
    this.getAccidentHistoryList();
  }

  onSortChangeHistory(sort: Sort) {
    this.sortInfoHistory = sort;
    this.getAccidentHistoryList();
  }

  onRowClick(row: any) {
    this.router.navigate([`/member/accident/${row.accidentId}/${this.memberId}`]);
  }

  onRemoveAccidentHistory(row: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Accident',
        text: 'Are you sure you want to remove this accident?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (row.accidentStatus !== 'Pending Review') {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: 'Cannot remove the Accident whose Status is not equal to ‘Pending Review‘.',
              type: ConfirmType.Warning,
              title: 'Error',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            }
          });
          return;
        }

        // @-> Not used this banner in Muni/Emp Portal Accident
        // if (!row.canModifyAction) {
        //   this.dialog.open(ConfirmPopupComponent, {
        //     panelClass: 'confirm-popup',
        //     data: {
        //       text: 'Cannot remove the Accident which was created by BVFF Admin.',
        //       type: ConfirmType.Warning,
        //       title: 'Error',
        //       cancelButtonTitle: 'Close',
        //       hideConfirmButton: true,
        //     }
        //   });
        //   return;
        // }

        this.store.dispatch(removeAccidentHistoryAction({
          memberId: this.memberId,
          accidentId: row.accidentId
        }))
      }
    });
  }

  onChangeClaimsPage(event: PageEvent) {
    this.pageSizeClaim = event.pageSize;
    this.pageIndexClaim = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + AccidentType.AccidentClaim + PAGE_SIZE_CONST, event.pageSize.toString());
    this.getAccidentClaimList();
  }

  onSortChangeClaims(sort: Sort) {
    this.sortInfoClaim = sort;
    this.getAccidentClaimList();
  }

  checkStatusAddAccidentParticipantPostCard() {
    this.store
      .pipe(select(addAccidentPostCardSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((accidentPostCardState) => {
        if (accidentPostCardState) {
          this.store.dispatch(clearAccidentPostCardAction());
          // Fail but have specified error from BE
          if (accidentPostCardState.state === STATE.FAIL && accidentPostCardState?.message?.error?.errorMessage) {
            const customMessage = accidentPostCardState?.message?.error?.errorMessage[1];
            showBanner.call(this, accidentPostCardState.state, 'Accident Post Card', accidentPostCardState.action, { customMessage });
            return;
          }
          // Success, then navigated to corresponding accident created
          if (accidentPostCardState.state === STATE.SUCCESS && accidentPostCardState?.accidentId) {
            this.router.navigate([`/member/accident/${accidentPostCardState.accidentId}/${this.memberId}`]);
            return;
          }
          // Fail by other causes
          showBanner.call(this, accidentPostCardState.state, 'Accident Post Card', accidentPostCardState.action);
        }
      });
  }
}
