import { Injectable } from '@angular/core';
import { EntityComponentMetadata, EntityMetadata, EntityPropertyMetadata, GetEntityMetadatasResponse } from './models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EntityMetadataService {
  Entities: EntityMetadata[] = [];

  constructor(private httpClient: HttpClient) {
    this.loadEntityMetadatas();
  }

  loadEntityMetadatas(): void {
    this.httpClient.get<GetEntityMetadatasResponse>(`${environment.apiUrl}/entity-new/entities-metadata`).subscribe(
      (response) => (this.Entities = response.entities),
      (error) => (this.Entities = []),
    );
  }

  getAllProperties(): EntityPropertyMetadata[] {
    return this.Entities.reduce(
      (allProperties: EntityPropertyMetadata[], entity: EntityMetadata) => [
        ...allProperties,
        ...entity.components.reduce(
          (componentProperties: EntityPropertyMetadata[], component: EntityComponentMetadata) => [
            ...componentProperties,
            ...component.entityProperties,
          ],
          [],
        ),
      ],
      [],
    );
  }

  getPropertyById(id: string): EntityPropertyMetadata | undefined {
    return this.getAllProperties().find((property) => property.id.toLowerCase() === id.toLowerCase());
  }
}
