<div
  class="captionbar"
  [ngClass]="{
    'cap-fund': router.url.includes('/fund-list') || router.url.includes('/employer/employer-list'),
    'cap-member': !router.url.includes('/fund-list') && !router.url.includes('/employer/employer-list')
  }"
>
  <div class="header-side" *ngIf="!checkPermissionService.isNoPermission">
    <span>PensionPro+</span>
    <button
      aria-label="menu close button"
      *ngIf="sidebarOpenedStatus === SidebarOpenedStatusEnum.Opened"
      mat-icon-button
      (click)="onCloseSideMenu()"
    >
      <mat-icon>menu_open</mat-icon>
    </button>
  </div>
  <div class="tabs">
    <div class="tab-group" *ngIf="!isHiddenTab">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngIf="!tab?.children?.length; else tabGroup">
          <ng-container
            *ptgCheckPermission="tab.permission"
            [ngTemplateOutletContext]="{ tab: tab }"
            [ngTemplateOutlet]="tabTemplate"
          >
          </ng-container>
        </ng-container>
        <ng-template #tabGroup>
          <ng-container *ptgCheckPermission="tab.permission">
            <ng-container
              *ngFor="let childTab of tab?.children"
              [ngTemplateOutletContext]="{ tab: childTab, isSelected: childTab?.isSelected }"
              [ngTemplateOutlet]="tabTemplate"
            >
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <ng-template #tabTemplate let-tabConfig="tab">
    <div
      (click)="selectTab(tabConfig)"
      *ngIf="(tabConfig?.url || tabConfig?.children?.length) && !tabConfig.isHidden"
      [ngClass]="[tabConfig?.isSelected ? 'selectedTab' : '', tabConfig?.tabName]"
      class="tab"
    >
      <ng-container *ptgCheckPermission="tabConfig?.permission">
        <mat-icon>{{ tabConfig?.tabIcon }}</mat-icon>
        <span class="truncate">{{
          tabConfig?.tabLabel?.length > 15 ? (tabConfig?.tabLabel | slice: 0 : 15) + '...' : tabConfig?.tabLabel
        }}</span>
      </ng-container>
    </div>
  </ng-template>

  <div class="right-actions">
    <button
      mat-stroked-button
      class="fund-button"
      *ngIf="
        !router.url.includes('/fund-list') &&
        !router.url.includes('/employer/employer-list') &&
        (authService.Role === ADMIN_SYSTEM ||
          authService.Role === ADMIN_FUND ||
          authService.isMultipleEmployerPortalUser$.value === true)
      "
      (click)="navigateToEmployerList()"
    >
      <mat-icon>list</mat-icon>
      {{ authService.isMunicipalityPortal$.value === true ? 'Municipality List' : 'Employer List' }}
    </button>

    <button
      mat-stroked-button
      class="fund-button"
      *ngIf="!router.url.includes('/fund-list') && authService.Role === ADMIN_SYSTEM"
      (click)="navigateFund()"
    >
      <mat-icon>list</mat-icon>
      Fund List
    </button>

    <button mat-stroked-button class="logout-button" (click)="logout()" name="Lagout" id="logout" tabindex="0">
      <mat-icon aria-hidden="true">logout</mat-icon>
      Logout
    </button>
  </div>
</div>
