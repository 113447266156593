import { EntityComponentType, EntityPropertyType, EntityType } from '../../types/enums';

export interface EntityMetadata {
  id: string;
  code: string;
  name: string;
  label: string;
  description: string;
  type: EntityType;
  entityPath: string;
  isStatic: boolean;
  isEnableVersioning: boolean;
  parentId: string;
  components: EntityComponentMetadata[];
}

export interface EntityComponentMetadata {
  id: string;
  name: string;
  tableName: string;
  importKey: string;
  type: EntityComponentType;
  editByCreatorOnly: boolean;
  removeByCreatorOnly: boolean;
  entityId: string;
  entityProperties: EntityPropertyMetadata[];
}

export interface EntityPropertyMetadata {
  id: string;
  name: string;
  columnName: string;
  importKey: string;
  type: EntityPropertyType;
  isStatic: boolean;
  entityComponentId: string;
  entityId: string;
  entityPropertyConfigs: EntityPropertyConfigMetadata[];
}

export interface EntityPropertyConfigMetadata {
  key: string;
  value: string;
  entityPropertyId: string;
}

export interface GetEntityMetadatasResponse {
  entities: EntityMetadata[];
  total: number;
}

export interface GetEntityMetaDataResponse {
  entities: EntityMetadata[];
}
