import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { EmployerOverviewHeaderComponent } from '@ptg-employer/components/employer-overview-header/employer-overview-header.component';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, GridComponent, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { SearchFilterDocumentsComponent } from '../../components/search-filter-documents/search-filter-documents.component';
import { DocumentFilter } from '../../services/models/documents.model';
import { EntityType } from '../../types/enums/entity-type.enum';

@Component({
  selector: 'ptg-individual-document-list',
  templateUrl: './individual-document-list.component.html',
  styleUrls: ['./individual-document-list.component.scss']
})
export class IndividualDocumentListComponent extends BaseComponent {
  readonly EntityType = EntityType;
  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent;
  @ViewChild(EmployerOverviewHeaderComponent) overviewEmployerComponent!: EmployerOverviewHeaderComponent;
  @ViewChild('gridIndividualDocumentList') gridIndividualDocumentList!: GridComponent<any>;
  @Input() dataTable: (any & Row)[] = [];
  @Input() sortInfo: any = {};
  @Input() totalRecords!: number | any;
  @Input() pageSize: number = 50;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() entityType!: EntityType;
  @Input() entityId!: string;
  @Input() isLoading!: boolean;
  @Input() message!: string;
  @Input() bannerType!: BannerType;
  @Input() isLoadingInfoBanner!: boolean;
  @Input() isHideCloseButton!: boolean;
  @Input() employerName!: string;
  @Input() isEmployerPortal!: boolean;
  @Input() functionButtons: FunctionButtonConfig[] = [];
  @Input() canDownloadDocument: boolean = true;
  @Input() canEditDocument: boolean = true;
  @Input() canRemoveDocument: boolean = true;

  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() downloadDocumentEvent = new EventEmitter();
  @Output() downloadMultipleDocumentEvent = new EventEmitter();
  @Output() exportDocumentEvent = new EventEmitter();
  @Output() removeMultipleDocumentEvent = new EventEmitter();
  @Output() removeDocumentEvent = new EventEmitter();
  @Output() editDocumentEvent = new EventEmitter();
  @Output() applyFilterEvent = new EventEmitter();
  @Output() generateDocumentEvent = new EventEmitter();
  @Output() onChangeEmployerInHeader = new EventEmitter();
  @Output() bannerTypeChange: EventEmitter<BannerType> = new EventEmitter();

  currentFilters!: DocumentFilter | null;

  isApplyMunicipalityFilter: boolean = false;
  columns: Column[] = [
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'uploadedDate',
      header: {
        title: 'Uploaded Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      // type: ColumnType.Link,
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action'
      },
    }
  ];
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Document List',
    },
  ];

  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };

  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];
  selectedFilter: string = this.filterOptions[0].value;
  listRecordSelected: any = [];
  constructor(
    private dialog: MatDialog,
    ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employerName) {
      this.employerName = changes.employerName.currentValue || '';
    }
  }

  editFilterDialog() {
    // edit filter
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {
        currentFilter: this.currentFilters
      }
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
  }

  clearFilter() {
    this.currentFilters = null;
    this.applyFilterEvent.emit(null);
  }

  onFilterSelect() {
    // Open add new filter popup
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {}
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
    return;
  }

  emitFunction(event: FunctionButtonData) {
    switch (event.buttonName) {
      case 'Upload': {
        this.uploadDocumentEvent.emit();
        break;
      }
      case 'Download': {
        this.downloadMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      case 'Export': {
        this.exportDocumentEvent.emit();
        break;
      }
      case 'Remove': {
        this.removeMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      case 'Generate': {
        this.generateDocumentEvent.emit();
        break;
      }
      default: {
        break;
      }
    }
  }

  onChangeSelection() {
    // Prepare list record for emit any actions (Download, Remove,...)
    this.listRecordSelected = this.gridIndividualDocumentList.selection.selected
      .filter((row: any & Row) => row.checked && !row.hideCheckBox);

    // Disabled Download and Remove buttons in case no record checked
    const downloadBtn = this.functionButtons.find(item => item.buttonName === 'Download');
    if (downloadBtn) downloadBtn.isDisabled = !this.gridIndividualDocumentList.selection.selected.length;
    const removeBtn = this.functionButtons.find(item => item.buttonName === 'Remove');
    if (removeBtn) removeBtn.isDisabled = !this.gridIndividualDocumentList.selection.selected.length;
  }

  downloadDocument(row: any) {
    this.downloadDocumentEvent.emit(row)
  }

  onEditIndividualDocument(row: any) {
    this.editDocumentEvent.emit(row);
  }

  onRemoveIndividualDocument(row: any) {
    this.removeDocumentEvent.emit(row);
  }

  onChangeSort(event: Sort) {
    this.sortChangeEvent.emit(event);
  }

  onChangePage(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }

  onChangeEmployerInHeaderEmit() {
    this.onChangeEmployerInHeader.emit();
  }

  closeBanner() {
    this.bannerTypeChange.emit(BannerType.Hidden);
  }
}
