<div class="estimator-config-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6" *ngIf="!isLoading">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="entity-initiation-configuration custom-class">
      <ng-container>
        <div class="row-button">
          <button mat-raised-button type="button" class="submit-button" [disabled]="!canSubmit" (click)="onSubmit()">
            Save
          </button>
          <button mat-raised-button type="button" (click)="onCancel()" class="cancel-button">Cancel</button>
        </div>
      </ng-container>
      <form class="edit-form" [formGroup]="formData">
        <div class="input-container">
          <span class="title">Entity</span>
          <span class="value">{{ entityHeaderDetail?.entityName }}</span>
        </div>
        <div class="input-container">
          <ptg-textbox
            [controlField]="entityLabelCtrl"
            placeholder="Entity Label"
            [hasLabel]="true"
            [isRequired]="true"
            [maxLength]="250"
            (valueChange)="markAsDataChanged()"
          ></ptg-textbox>
        </div>
        <div class="content-container">
          <div class="box-container add-property-section">
            <div class="box">
              <div class="title">{{ entityConfigs.title }}</div>
              <div class="form-container">
                <ptg-select
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="availableEntityOptions"
                  [controlField]="propertyNameCtrl"
                  [placeholder]="'Property Name'"
                  [isMultipleLineOption]="false"
                  panelClass="property-name-dropdown"
                  (changeOptionValue)="changeProperty()"
                >
                </ptg-select>
                <ptg-textbox
                  [controlField]="labelCtrl"
                  [hasLabel]="true"
                  [maxLength]="150"
                  customError="duplicatedValue"
                  [placeholder]="entityConfigs.label || ''"
                >
                </ptg-textbox>
                <ptg-button
                  [buttonName]="entityConfigs.buttonName"
                  classInput="add-button"
                  type="button"
                  (clickButton)="addProperty()"
                >
                  <mat-icon>add</mat-icon>
                </ptg-button>
              </div>
            </div>
          </div>
          <div class="box-container sort-property">
            <div class="box">
              <div
                class="title"
                [ngClass]="{
                  'no-data': !entityTable || entityTable.length === 0
                }"
              >
                {{ orderTitle.title }}
              </div>
              <ptg-grid
                #sortPropertyTable
                [data]="entityTable"
                [columns]="orderColumns"
                [notFoundMessage]="''"
                [isLoading]="isLoading"
                [fitToParent]="true"
                [paginable]="false"
                [allowDragDrop]="true"
                [hideHeader]="true"
                [inlineEditable]="true"
                [softDeletable]="true"
                (softDelete)="onSoftDeleteSectionConfig($event)"
                (rowDrop)="changeItem($event)"
                (change)="markAsDataChanged()"
              >
                <ng-template cellContent [columnName]="'columnName'" let-row>
                  <ng-container>
                    <div class="multiple-line-text">
                      <span class="caption">{{ row.columnName }}</span>
                      <span class="description">{{ row.columnNameDescription }}</span>
                    </div>
                  </ng-container>
                </ng-template>
              </ptg-grid>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
