<div class="new-cart-container">
  <h3>Add New Card</h3>
  <form [formGroup]="editForm">
    <div class="form-control-container">
      <ptg-textbox
        placeholder="Card Name"
        errorAsync="Card Name already exists."
        [controlField]="editForm.get('cardName')"
        [hasLabel]="true"
        [maxLength]="250"
        [isRequired]="true"
      ></ptg-textbox>
      <ptg-select
        (changeOptionValue)="onchangeEntity()"
        [controlField]="editForm.get('entityId')"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listEntity"
        class="full-width"
        placeholder="Entity"
        [isRequired]="true"
      >
      </ptg-select>
      <ptg-select
        [controlField]="editForm.get('entityComponentId')"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listName"
        class="full-width"
        placeholder="List Name"
        [class.disabled]="!editForm.value['entityId']"
      >
      </ptg-select>
    </div>

    <div class="wrap-btn">
      <button mat-raised-button type="button" class="btn-create-continue" (click)="formSubmit$.next(true)">
        Create & Continue
      </button>
      <button mat-raised-button type="button" class="btn-create-add" (click)="formSubmit$.next(false)">
        Create & Add Another
      </button>
      <button mat-raised-button type="button" class="btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
  </form>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
