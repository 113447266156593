<ptg-dialog-header [header]="data.name" prefix="Upload"></ptg-dialog-header>

<div class="flex flex-col p-6 gap-6">
  <ptg-banner
    *ngIf="bannerType"
    [(bannerType)]="bannerType"
    [message]="message"
    [isHideCloseButton]="true"
  ></ptg-banner>

  <div class="upload-file">
    <button mat-raised-button type="button" (click)="onNoClick()">Cancel</button>
    <div class="banner" *ngIf="isUploading">
      <mat-icon style="color: #a8a8a8">cloud_upload</mat-icon>
      <span style="vertical-align: super">Uploading File...</span>
    </div>
    <div>
      <ptg-select
        *ngIf="data.target === 'Metadata'"
        placeholder="Key Column"
        [controlField]="uniqueKey"
        [listData]="listUniqueKey"
        [isOptionObj]="true"
        [isRequired]="true"
        errorRequire="Key Column is required."
        [isMultipleLineOption]="true"
      ></ptg-select>
    </div>

    <ptg-upload
      (onUploadAction)="uploadFile($event)"
      [checkPattern]="checkPattern"
      [errMsg]="'Unsupported file name or file format.'"
      [typeFile]="supportedFileTypes"
      [accept]="acceptFile"
    ></ptg-upload>
    <div *ngIf="data?.target === 'AppContent' && data?.listNoun?.length" class="info-box">
      <p>
        Uploading a file will add all {{ data.listNoun[0] }} in the file to the existing {{ data.listNoun[1] }} list.
      </p>
      <p>
        To edit {{ data.listNoun[2] }}, you must add {{ data.listNoun[3] }} and then delete the old
        {{ data.listNoun[4] }} record using the admin.
      </p>
      <p>
        Currently, the system will not identify duplicate records when adding
        {{ data.listNoun[5] }}.
      </p>
      <mat-icon style="color: #2d6c71">info</mat-icon>
    </div>
  </div>
</div>
