<div *ngIf="selectedItem" class="box">
  <div class="detail-container">
    <div class="detail" *ngFor="let property of propertyDisplayed" class="{{ property?.propertyName }}">
      <span class="label" id="label-{{ property.propertyName }}">{{ property.label }}</span>
      <span class="value truncate" id="value-{{ property.propertyName }}">
        <ng-container
          *ngIf="
            selectedItem?.[property.propertyName] ||
              selectedItem?.[property.propertyName] === 0 ||
              selectedItem?.[property.propertyName] === '0' ||
              selectedItem?.[property.propertyName] === false;
            else blankData
          "
        >
          <ng-container [ngSwitch]="property.type">
            <ng-container *ngSwitchCase="DATA_TYPE.TYPE_BOOLEAN">
              {{ selectedItem?.[property.propertyName] ? 'Yes' : 'No' }}
            </ng-container>
            <ng-container *ngSwitchCase="DATA_TYPE.TYPE_DATE">
              {{ selectedItem?.[property.propertyName] | date: 'MM/dd/yyyy' }}
            </ng-container>
            <ng-container *ngSwitchCase="DATA_TYPE.TYPE_CURRENCY">
              <div class="align-right">
                <span style="color: #707070">$</span
                ><span>{{ selectedItem?.[property.propertyName] | numberLocalDecimal: ' ' }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DATA_TYPE.TYPE_PERCENTAGE">
              <div class="align-right">
                <span>{{ selectedItem?.[property.propertyName] | numberLocalDecimal: ' ' }}</span
                ><span>%</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DATA_TYPE.TYPE_STATUS">
              <mat-icon
                *ngIf="selectedItem?._typedValue?.iconName"
                class="icon-status"
                [ngStyle]="{ color: selectedItem?._typedValue?.color }"
                >{{ selectedItem?._typedValue?.iconName }}</mat-icon
              >
              <div class="icon-name">
                {{ selectedItem?.[property.propertyName] }}
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <!-- Binding the template from outside (if any) -->
              <ng-container
                [ngTemplateOutlet]="getContentTemplate(property.propertyName) || defaultTemplate"
                [ngTemplateOutletContext]="{ $implicit: selectedItem }"
              ></ng-container>

              <!-- Transform display data with "cell" function (if any) -->
              <ng-template #defaultTemplate>
                {{ selectedItem?.[property.propertyName] }}
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #blankData>
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: selectedItem }"
            [ngTemplateOutlet]="getContentTemplate(property.propertyName) || defaultTemplate"
          ></ng-container>
          <ng-template #defaultTemplate>-</ng-template>
        </ng-template>
      </span>
    </div>
  </div>
</div>
