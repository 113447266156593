<div class="card-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="pt-6 pb-20 px-12" *ngIf="!isLoading">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    <div class="wrap-btn mb-8 mt-4">
      <button mat-raised-button class="submit-button" (click)="onSaveData()">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <form *ngIf="editForm" [formGroup]="editForm">
      <!-- Card Info -->
      <div class="grid grid-cols-3 gap-8">
        <ptg-textbox
          placeholder="Card Name"
          errorAsync="Card Name already exists."
          [controlField]="editForm.get('cardName')"
          [hasLabel]="true"
          [maxLength]="250"
          [isRequired]="true"
          width="100%"
        ></ptg-textbox>
      </div>
      <div class="detail grid grid-cols-3 gap-8">
        <div class="flex flex-col mb-4">
          <span class="label text-sm">Entity</span>
          <span class="value text-lg">
            <ng-container>{{ this.cardDetailData?.entityName }}</ng-container>
          </span>
        </div>
      </div>
      <div class="detail grid grid-cols-3 gap-8">
        <ptg-select
          [listData]="includeAttachmentAtData"
          [controlField]="editForm.get('includeAttachmentAt')"
          placeholder="Include Attachment"
          [isOptionObj]="true"
          width="100%"
        ></ptg-select>
      </div>
      <div *ngIf="cardDetailData?.entityComponentId" class="detail grid grid-cols-3 gap-8">
        <ptg-select
          [listData]="listNameData"
          [controlField]="editForm.get('listName')"
          placeholder="List Name"
          [isDisabled]="true"
          width="100%"
        ></ptg-select>
        <ptg-toggle-button
          [controlField]="editForm.get('enableDragDrop')"
          label="Enable Drag-and-Drop Sorting"
          class="pt-3"
        ></ptg-toggle-button>
      </div>
      <div *ngIf="cardDetailData?.entityComponentId" class="detail grid grid-cols-3 gap-8">
        <ptg-textbox
          placeholder="List Label"
          [controlField]="editForm.get('listLabel')"
          [hasLabel]="true"
          [maxLength]="250"
          width="100%"
        ></ptg-textbox>
      </div>
      <!-- Card Info -->
    </form>

    <ul class="flex flex-wrap border-b mb-6 tabs-header">
      <li class="mr-3" *ngFor="let item of listTabs">
        <span
          class="cursor-pointer inline-block p-3"
          [ngClass]="{
            active: currentTab === item
          }"
          (click)="onChangeTab(item)"
        >
          {{ item }}
        </span>
      </li>
    </ul>
    <div
      *ngIf="cardDetailData?.entityComponentId"
      class="grid grid-cols-3 gap-8"
      [ngClass]="{ hidden: currentTab !== 'List-Detail View' }"
    >
      <div>
        <div class="card">
          <div class="p-4 font-bold text-lg border-b">Add Column</div>
          <div class="p-4">
            <ptg-select
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="entityListDetailData"
              [controlField]="addPropertyForm.get('propertyName')"
              placeholder="Property Name"
              width="100%"
              (changeOptionValue)="onFilterBySelectListDetail($event)"
              [isMultipleLineOption]="true"
            ></ptg-select>
            <ptg-radio-button
              [controlField]="addPropertyForm.get('propertyItem')"
              [listOption]="entityComponentItemData"
              [isObject]="true"
            ></ptg-radio-button>
            <ptg-textbox
              placeholder="Column Name"
              [controlField]="addPropertyForm.get('label')"
              [hasLabel]="true"
              [maxLength]="150"
              width="100%"
            ></ptg-textbox>
            <ptg-button (click)="addListDetail()" classInput="add-button">
              <mat-icon>add</mat-icon>
              Add column
            </ptg-button>
          </div>
        </div>
      </div>
      <!-- Order Columns -->
      <div>
        <div class="card">
          <div class="p-4 font-bold text-lg border-b">Order Columns</div>
          <ptg-grid
            #gridOrderColumns
            [data]="orderColumns"
            [columns]="configuredListColumns"
            [paginable]="false"
            [notFoundMessage]="''"
            [allowDragDrop]="true"
            [hideHeader]="true"
            [inlineEditable]="true"
            [softDeletable]="true"
            (softDelete)="onOrderDelete($event)"
          >
            <ng-template cellContent columnName="name" let-row>
              <div class="multiple-line-text">
                <span class="caption">{{ row.name }}</span>
                <span class="description">{{ row.subtext }}</span>
              </div>
            </ng-template>

            <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
              <ng-container
                *ngIf="!editForm?.get('enableDragDrop')?.value && !(row.editing || row.deleted || row.isSortColumn)"
              >
                <ptg-button (clickButton)="onClickMove2Sort(row)" classInput="edit-button">
                  <mat-icon>arrow_right_alt</mat-icon>
                </ptg-button>
              </ng-container>
              <ng-container *ngIf="!(row.editing || row.deleted)">
                <ptg-button
                  (clickButton)="onClickToggleShowOnTableList(row, !row.showOnTableList)"
                  classInput="edit-button"
                >
                  <mat-icon matTooltip="Show/Hide on Table List" matTooltipClass="custom-tooltip">
                    {{ row.showOnTableList ? 'visibility' : 'visibility_off' }}
                  </mat-icon>
                </ptg-button>
              </ng-container>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
      <!-- Order Columns -->
      <!-- Sort Row -->
      <div>
        <div class="card" *ngIf="!editForm?.get('enableDragDrop')?.value">
          <div class="p-4 font-bold text-lg border-b">Sort Rows</div>
          <ptg-grid
            #gridSortRow
            [data]="sortRow"
            [columns]="configuredCardColumns"
            [paginable]="false"
            [notFoundMessage]="''"
            [allowDragDrop]="true"
            [hideHeader]="true"
            [softDeletable]="true"
          >
            <ng-template cellContent columnName="name" let-row>
              <div class="multiple-line-text">
                <span class="caption">{{ row.name }}</span>
                <span class="description">{{ row.subtext }}</span>
              </div>
            </ng-template>

            <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
              <ng-container *ngIf="!(row.editing || row.deleted)">
                <ptg-button *ngIf="row.sortType === 0" (clickButton)="onClickSort(row, 1)" classInput="edit-button">
                  <mat-icon>arrow_upward</mat-icon>
                </ptg-button>
                <ptg-button *ngIf="row.sortType === 1" (clickButton)="onClickSort(row, 0)" classInput="edit-button">
                  <mat-icon>arrow_downward</mat-icon>
                </ptg-button>
              </ng-container>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
      <!-- Sort Row -->
    </div>
    <div
      *ngIf="cardDetailData?.entityComponentId"
      class="grid grid-cols-3 gap-8"
      [ngClass]="{ hidden: currentTab !== 'List-Summary View' }"
    >
      <div>
        <div class="card">
          <div class="p-4 font-bold text-lg border-b">Add Column</div>
          <div class="p-4">
            <ptg-select
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="entityListSummaryData"
              [controlField]="addPropertyForm.get('propertyName')"
              placeholder="Property Name"
              width="100%"
              (changeOptionValue)="onFilterBySelectListSummary($event)"
              [isMultipleLineOption]="true"
            ></ptg-select>
            <ptg-radio-button
              [controlField]="addPropertyForm.get('propertyItem')"
              [listOption]="entityComponentItemData"
              [isObject]="true"
            ></ptg-radio-button>
            <ptg-textbox
              placeholder="Column Name"
              [controlField]="addPropertyForm.get('label')"
              [hasLabel]="true"
              [maxLength]="150"
              width="100%"
            ></ptg-textbox>
            <ptg-button buttonName="Add Column" type="button" (clickButton)="addListSummary()" classInput="add-button">
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>
      </div>

      <!-- Order Columns -->
      <div class="col-span-2">
        <div class="card">
          <div class="p-4 font-bold text-lg border-b">Order Columns</div>
          <ptg-grid
            #gridSummaryOrderColumns
            [data]="summaryOrderColumns"
            [columns]="configuredSummaryColumns"
            [paginable]="false"
            [notFoundMessage]="''"
            [allowDragDrop]="true"
            [hideHeader]="true"
            [inlineEditable]="true"
            [softDeletable]="true"
            (softDelete)="onColumnSummaryDelete($event)"
          >
            <ng-template cellContent columnName="name" let-row>
              <div class="multiple-line-text">
                <span class="caption">{{ row.name }}</span>
                <span class="description">{{ row.subtext }}</span>
              </div>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
      <!-- Order Columns -->
    </div>
    <div [ngClass]="{ hidden: currentTab !== 'Properties' }">
      <div class="grid grid-cols-3 gap-8 mb-3">
        <div>
          <ptg-select
            [listData]="propertyDisplayData"
            [controlField]="editForm?.get('propertyDisplay')"
            placeholder="Property Display"
            [isOptionObj]="true"
            width="97%"
            class="display-select"
          ></ptg-select>
          <ptg-textbox
            placeholder="Detail View Label"
            [controlField]="editForm?.get('detailViewLabel')"
            [hasLabel]="true"
            [maxLength]="150"
            width="97%"
          ></ptg-textbox>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-8">
        <div>
          <div class="card">
            <div class="p-4 font-bold text-lg border-b">Add Property</div>
            <div class="p-4">
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="propertyTypes"
                [controlField]="addPropertyForm.get('type')"
                placeholder="Type"
                width="100%"
                (changeOptionValue)="onSelectType()"
              ></ptg-select>
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="entityPropertiesData"
                [controlField]="addPropertyForm.get('propertyName')"
                placeholder="Property Name"
                width="100%"
                (changeOptionValue)="onFilterBySelectProperties($event)"
                [isMultipleLineOption]="true"
                *ngIf="addPropertyForm.get('type')?.value !== -1"
              ></ptg-select>
              <ptg-radio-button
                [controlField]="addPropertyForm.get('propertyItem')"
                [listOption]="entityComponentItemData"
                [isObject]="true"
              ></ptg-radio-button>
              <ptg-textbox
                placeholder="Label"
                [controlField]="addPropertyForm.get('label')"
                [hasLabel]="true"
                [maxLength]="150"
                width="100%"
                *ngIf="addPropertyForm.get('type')?.value !== -1"
              ></ptg-textbox>
              <ptg-select
                [listData]="toColumns"
                [controlField]="addPropertyForm.get('toColumn')"
                placeholder="To Column"
                width="100%"
              ></ptg-select>
              <div class="flex justify-between items-center">
                <ptg-toggle-button
                  [controlField]="addPropertyForm.get('includeInSummaryView')"
                  label="Include in Summary"
                  width="100%"
                  *ngIf="addPropertyForm.get('type')?.value !== -1"
                ></ptg-toggle-button>
                <ptg-button
                  buttonName="Add to Card"
                  type="button"
                  (clickButton)="addProperty()"
                  classInput="add-button"
                >
                  <mat-icon>add</mat-icon>
                </ptg-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Detail View Column -->
        <div>
          <div class="card mb-4">
            <div class="p-4 font-bold text-lg border-b">Detail View Column 1</div>
            <ptg-grid
              #gridViewColumn1
              id="gridViewColumn1"
              [connectedTo]="['gridViewColumn2', 'gridViewColumn3', 'gridViewColumn4']"
              [data]="detailViewColumn[1]"
              [columns]="configuredCardColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (outsideRowDrop)="onOutsideRowDrop($event)"
              (softDelete)="onColumnDelete($event)"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <!-- <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button> -->
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
          <div class="card mb-4">
            <div class="p-4 font-bold text-lg border-b">Detail View Column 2</div>
            <ptg-grid
              #gridViewColumn2
              id="gridViewColumn2"
              [connectedTo]="['gridViewColumn1', 'gridViewColumn3', 'gridViewColumn4']"
              [data]="detailViewColumn[2]"
              [columns]="configuredCardColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (outsideRowDrop)="onOutsideRowDrop($event)"
              (softDelete)="onColumnDelete($event)"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <!-- <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button> -->
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
          <div class="card mb-4">
            <div class="p-4 font-bold text-lg border-b">Detail View Column 3</div>
            <ptg-grid
              #gridViewColumn3
              id="gridViewColumn3"
              [connectedTo]="['gridViewColumn2', 'gridViewColumn1', 'gridViewColumn4']"
              [data]="detailViewColumn[3]"
              [columns]="configuredCardColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (outsideRowDrop)="onOutsideRowDrop($event)"
              (softDelete)="onColumnDelete($event)"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <!-- <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button> -->
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
          <div class="card mb-4">
            <div class="p-4 font-bold text-lg border-b">Detail View Column 4</div>
            <ptg-grid
              #gridViewColumn4
              id="gridViewColumn4"
              [connectedTo]="['gridViewColumn2', 'gridViewColumn3', 'gridViewColumn1']"
              [data]="detailViewColumn[4]"
              [columns]="configuredCardColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (outsideRowDrop)="onOutsideRowDrop($event)"
              (softDelete)="onColumnDelete($event)"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <!-- <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button> -->
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
        <!-- Detail View Column -->

        <!-- Summary View -->
        <div>
          <div class="card mb-4">
            <div class="p-4 font-bold text-lg border-b">Summary View</div>
            <ptg-grid
              #gridSummaryView
              [data]="summaryView"
              [columns]="configuredCardSummaryColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <!-- <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button> -->
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
        <!-- Summary View -->
      </div>
    </div>
    <!-- Version History -->
    <div [ngClass]="{ hidden: currentTab !== 'Version History' }">
      <div class="grid grid-cols-3 gap-8 mb-3">
        <div>
          <ptg-textbox
            placeholder="List Label"
            [controlField]="editForm?.get('listLabel')"
            [hasLabel]="true"
            [maxLength]="150"
            width="100%"
          ></ptg-textbox>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-8">
        <div>
          <div class="card">
            <div class="p-4 font-bold text-lg border-b">Add Column</div>
            <div class="p-4">
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="propertyTypesVersion"
                [controlField]="addPropertyForm.get('type')"
                placeholder="Type"
                width="100%"
                (changeOptionValue)="onSelectTypeVersion()"
              ></ptg-select>
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="entityVersionData"
                [controlField]="addPropertyForm.get('propertyName')"
                placeholder="Property Name"
                width="100%"
                (changeOptionValue)="onFilterBySelectVersion()"
                [isMultipleLineOption]="true"
              ></ptg-select>
              <ptg-radio-button
                [controlField]="addPropertyForm.get('propertyItem')"
                [listOption]="entityComponentItemData"
                [isObject]="true"
              ></ptg-radio-button>
              <ptg-textbox
                placeholder="Column Name"
                [controlField]="addPropertyForm.get('label')"
                [hasLabel]="true"
                [maxLength]="150"
                width="100%"
              ></ptg-textbox>
              <ptg-button buttonName="Add column" type="button" (clickButton)="addVersion()" classInput="add-button">
                <mat-icon>add</mat-icon>
              </ptg-button>
            </div>
          </div>
        </div>
        <!-- Order Columns -->
        <div>
          <div class="card">
            <div class="p-4 font-bold text-lg border-b">Order Columns</div>
            <ptg-grid
              #gridOrderColumnsVersion
              [data]="orderColumnsVersion"
              [columns]="configuredVersionColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onOrderDeleteVersion($event)"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container
                  *ngIf="!editForm?.get('enableDragDrop')?.value && !(row.editing || row.deleted || row.isSortColumn)"
                >
                  <ptg-button (clickButton)="onClickMove2SortVersion(row)" classInput="edit-button">
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
        <!-- Order Columns -->
        <!-- Sort Row -->
        <div>
          <div class="card" *ngIf="!editForm?.get('enableDragDrop')?.value">
            <div class="p-4 font-bold text-lg border-b">Sort Rows</div>
            <ptg-grid
              #gridSortRowVersion
              [data]="sortRowVersion"
              [columns]="configuredCardColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [softDeletable]="true"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.subtext }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!(row.editing || row.deleted)">
                  <ptg-button
                    *ngIf="row.sortType === 0"
                    (clickButton)="onClickSortVersion(row, 1)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_upward</mat-icon>
                  </ptg-button>
                  <ptg-button
                    *ngIf="row.sortType === 1"
                    (clickButton)="onClickSortVersion(row, 0)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_downward</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
        <!-- Sort Row -->
      </div>
    </div>
    <!-- Version History -->
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
