import { AfterViewChecked, Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import {
  DisplayedTabName,
  IGNOTE_URL,
  IGNOTE_URL_SEARCH,
  STRING_QUERY_PARAM,
} from '../../../layout/constance/layout.const';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';

import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import * as ParticipantSearchConfigurationActions from '@ptg-member/store/actions/participant-search-configuration.actions';
import { PropertyTypeLabel } from '@ptg-member/constance/metadata.const';
import { PropertyType, PropertyTypeEntity } from '@ptg-member/constance/metadataPropertyType.const';
import * as fromMember from '@ptg-member/store/reducers';
import { setRegisterFilterAction } from '@ptg-processing/features/transaction-register/store/actions';
import { ProcessingState } from '@ptg-processing/store/reducers';
import { environment } from 'src/environments/environment';
import { Auth0Service } from '../../../auth/services/auth0.service';
import { doubleArrowDownIcon, doubleArrowUpIcon, personSearch } from '../../../constance/listIcons.const';
import { ADMIN_FUND, ADMIN_SYSTEM } from '../../../constance/value.const';
import { SearchResult } from '../../../layout/models/search.models';
import { CheckPermissionService } from '../../../services/check-permission.service';
import { deepClone, getCurrentFundData } from '../../../utils/common.util';
import * as FundListActions from '../../../../fund-list/actions/fund-list.actions';
import * as fromReducer from '../../../../reducers';
import { PERMISSION_KEY } from '../../../constance/permission.const';
import { HistoryService } from '../../../services/history.service';
import { HeaderActions, LayoutActions } from '../../actions';
import * as fromLayout from '../../reducers';
import { SCREEN_ID } from '@ptg-member/constance/lookupTable.const';
import { GetEntitiesQuery } from '@ptg-entity-management/services/models';
import { EntityState } from '@ptg-entity-management/store/reducers';
import { getEntitiesAction } from '@ptg-entity-management/store/actions';
import { SearchConfigurationDisplayConfig } from '@ptg-member/types/models/participant-search-configuration.model';
import { getModuleConnectionsSelector } from '@ptg-entity-management/store/selectors';
import { PARTICIPANT_ENTITY_CONNECTION_NAME } from '@ptg-member/constance/member-list.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { EmployerActions } from '@ptg-employer/actions';
import { getModuleConnectionsAction } from '@ptg-entity-management/store/actions';
import { MyMonitoringService } from '@ptg-shared/services/azure-insights.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MemberListActions } from '@ptg-member/store/actions';
import {
  clearGetEmployerInfoForHeaderStateAction,
  getEmployerInfoForHeaderAction,
} from '@ptg-shared/layout/actions/layout.actions';
import { getEmployerInfoForHeaderSelector } from '../../reducers';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { STATUS_COLUMNKEY } from '@ptg-entity-management/constants';

@Component({
  selector: 'ptg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('participantSearchTrigger')
  participantSearchTrigger!: MatAutocompleteTrigger;

  @Input() shouldHideSearchResultHeader: boolean = false;

  showHeader = true;
  valueSearch: string = '';
  username: string = '';
  tenant: string = '';
  logoUrl: string = '';
  listFund: FundModel[] = [];
  search$ = new BehaviorSubject('');
  isLoading = true;
  ADMIN_FUND = ADMIN_FUND;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  IGNOTE_URL = IGNOTE_URL;
  activeFund: any;
  PERMISSION_KEY = PERMISSION_KEY;
  propertyTypeEntity = PropertyTypeEntity;
  searchResultColumns: string[] = [];
  searchResultColumnTypes: string[] = [];
  searchResultList: Record<string, any>[] = [];
  unsubscribe$ = new Subject<void>();
  hasConfig: boolean = false;
  isError: boolean = false;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  entityId: string = '';
  isSelectedMember = false;
  selectedTabIndex: number = 0;
  employerName: string = '';

  constructor(
    private store: Store<fromReducer.State>,
    private searchStore: Store<fromLayout.State>,
    public authService: Auth0Service,
    public router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public checkPermissionService: CheckPermissionService,
    private historyService: HistoryService,
    private memberStore: Store<fromMember.MemberState>,
    private processingStore: Store<ProcessingState>,
    private renderer: Renderer2,
    public entityStore: Store<EntityState>,
    private myMonitoringService: MyMonitoringService,
    private layoutService: LayoutService,
  ) {
    iconRegistry.addSvgIconLiteral('personSearch', sanitizer.bypassSecurityTrustHtml(personSearch));
    iconRegistry.addSvgIconLiteral('doubleArrowDownIcon', sanitizer.bypassSecurityTrustHtml(doubleArrowDownIcon));
    iconRegistry.addSvgIconLiteral('doubleArrowUpIcon', sanitizer.bypassSecurityTrustHtml(doubleArrowUpIcon));
  }

  ngOnInit(): void {
    this.checkParticipantSearchConfig();
    this.username = this.authService.name.split(' ')[0];

    this.store.pipe(select(fromReducer.selectFundListState)).subscribe((el) => {
      this.tenant = el.currentFund?.name;
      this.listFund = el.allFund || [];
      if (this.activeFund?.id !== el.currentFund?.id) {
        this.store.dispatch(
          FundListActions.updateFundLayout({
            updateLayout: { brandRefreshed: new Date().toUTCString() },
          }),
        );
      }
      this.activeFund = el.currentFund;
      // this.activeFund.currentFund = el.currentFund;
      // this.activeFund.Id = el.currentFund.id;
      // this.activeFund.Key = el.currentFund.key;
      // this.activeFund.refreshBrand();
    });

    this.store.pipe(select(fromReducer.selectBrandRefreshed)).subscribe((el) => {
      this.logoUrl = `${environment.blobUrl}${this.activeFund?.key}/icons/logo_64x64.png?${new Date().getTime()}`;

      let cssLink: HTMLLinkElement = document.getElementById('corporateBrand') as HTMLLinkElement;
      cssLink.href = `${environment.blobUrl}${this.activeFund?.key}/styles/admin_app.css?${new Date().getTime()}`;
    });

    this.searchStore.pipe(select(fromLayout.selectSearchState)).subscribe((searchState) => {
      let searchData: SearchResult = deepClone(searchState.searchResult || {});
      this.searchResultColumns = (searchData.columns || []).map((item) => item.columnName);
      this.searchResultColumnTypes = (searchData.columns || []).map((item) => item.type);
      this.searchResultList = (searchData.members || []).map((member, index) => {
        return {
          ...this.getColumnValue(searchData, index),
          Id: member.Id,
        };
      });
      this.isLoading = searchState.isLoading;
      this.isError = searchState.isError;
    });

    this.search$.pipe(debounceTime(500)).subscribe((el) => {
      this.searchStore.dispatch(HeaderActions.clearSearchList());
      if (el && el.length > 1) {
        this.searchStore.dispatch(
          HeaderActions.getSearchRequest({
            searchValue: el,
            searchType: this.selectedTabIndex,
            screenId: SCREEN_ID.ParticipantSearch,
            entityId: this.entityId,
            employerId: this.authService.employerId,
          }),
        );
      }
    });

    this.router.events.subscribe((el) => {
      if (el instanceof NavigationEnd && el.url === '/employer/employer-list' && this.valueSearch) {
        this.searchStore.dispatch(HeaderActions.clearSearchList());
        this.valueSearch = '';
        this.selectedTabIndex = 0;
        this.store.dispatch(
          LayoutActions.closeTab({
            tabName: DisplayedTabName.SearchedParticipant,
          }),
        );
      }
      if (el instanceof NavigationEnd && el.url === '/employer/employer-list'){
        this.store.dispatch(
          LayoutActions.closeTab({
            tabName: DisplayedTabName.IndividualParticipant,
          }),
        );
      }
      if (el instanceof NavigationStart) {
        this.myMonitoringService.startNavigationEvent(el.url);
      } else if (el instanceof NavigationEnd) {
        this.myMonitoringService.endNavigationEvent(el.url);
        this.myMonitoringService.logPageView();
        if (this.layoutService.currentEmployer$.value?.name) {
          this.employerName = this.layoutService.currentEmployer$.value?.name;
        } else {
          this.employerName = '';
        }
      }
      if (el instanceof NavigationEnd && (el.url === '/employer/employer-list' || el.url === '/fund-list')) {
        this.employerName = '';
      }
    });
    this.memberStore
      .pipe(select(getModuleConnectionsSelector))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((entities) => {
        if (entities?.payload) {
          const connection = (entities.payload.items || []).find(
            (x) => x.moduleKey === PARTICIPANT_ENTITY_CONNECTION_NAME,
          );
          if (connection) {
            this.entityId = connection?.entityId || '';
            this.memberStore.dispatch(
              ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntity({
                entityId: this.entityId,
              }),
            );
          }
        }
      });
    this.getCurrentEmployer();
    this.getOverviewURL();
    if (this.layoutService.currentEmployer$.value?.name) {
      this.employerName = this.layoutService.currentEmployer$.value?.name;
    }
    this.registerGetEmployerForHeaderSelector();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked() {
    this.participantSearchTrigger?.updatePosition();
  }

  searchMember(value: string) {
    this.search$.next(value);
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    this.search$.next(this.valueSearch);
  }

  getOverviewURL() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu && state?.menu.length > 0 && this.isSelectedMember) {
          this.isSelectedMember = false;
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = overviewView?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${
            overviewView.id
          }/${overviewView.overviewViewId}/${state.memberId}?${STRING_QUERY_PARAM.SEARCH}&entityReferenceLinkedId=${
            overviewView.entityId
          }`;

          this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
          this.store.dispatch(
            LayoutActions.selectTab({
              tab: DisplayedTabName.SearchedParticipant,
              url,
            }),
          );
        }
      });
  }

  selectMember(option: any) {
    this.participantSearchTrigger.closePanel();

    this.store.dispatch(
      LayoutActions.setHistory({
        history: { urlBeforeSearch: this.router.url },
      }),
    );
    this.isSelectedMember = true;
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({ memberId: option.Id, entityReferenceLinkedId: option?.entityId }),
    );
  }

  changeTenant(tenant: string) {
    this.memberStore.dispatch(ParticipantSearchConfigurationActions.getParticipantSearchConfiguration());
    this.searchStore.dispatch(HeaderActions.clearSearchList());
    this.valueSearch = '';
    this.selectedTabIndex = 0;

    this.store.dispatch(EmployerActions.setEmployerFilter({ filters: [] }));
    this.memberStore.dispatch(MemberListActions.setMemberFilter({ filters: [] }));

    // Just only need update fundKey once
    //this.activeFund.updateMetadata();
    //this.activeFund.updateNavigation();
    this.store.dispatch(
      FundListActions.updateFundLayout({
        updateLayout: { metadataUpdated: new Date().toUTCString() },
      }),
    );
    this.store.dispatch(
      FundListActions.updateFundLayout({
        updateLayout: { navigationUpdated: new Date().toUTCString() },
      }),
    );
    const currentFund = this.listFund.find((el: any) => el.name === tenant) as FundModel;
    this.store.dispatch(FundListActions.selectFund(getCurrentFundData(currentFund)));
    this.redirectAfterChangeFund();

    //clear Transaction Register Filter
    this.processingStore.dispatch(setRegisterFilterAction({ filters: [] }));

    if (this.router.url.includes('entity-management')) {
      if (this.router.url.includes('benefit-mapping')) {
        this.router.navigateByUrl(`entity-management/benefit-mapping`);
      } else {
        this.router.navigateByUrl(`entity-management/entities`);
        this.getDataEntity();
      }
    }
  }

  private redirectAfterChangeFund() {
    const url = this.router.url;
    let absPath = url.split('/')[1];
    switch (absPath) {
      case 'access-management':
        const redirectUrl = '/access-management/roles';
        this.navigateByUrlCustom(redirectUrl);
        break;

      case 'page':
        this.navigateByUrlCustom('/fund-management/navigation');
        break;

      case 'fund-management': {
        let page = url.split('/')[2];
        switch (page) {
          case 'navigation':
          case 'preferences':
          case 'app-content':
            this.navigateByUrlCustom('/fund-management/navigation');
            break;

          case 'resource-center':
            this.navigateByUrlCustom('/fund-management/resource-center');
            break;

          case 'notification':
            this.navigateByUrlCustom('/fund-management/notification');
            break;

          case 'member-metadata':
          case 'theme':
          default:
            this.navigateByUrlCustom('/fund-management/theme');
            break;
        }
        break;
      }

      case 'organization':
        this.navigateByUrlCustom('/organization/board-management');
        break;
      case 'estimator':
        this.navigateByUrlCustom('/estimator');
        break;
      case 'processing': {
        let page = url.split('/')[2];
        switch (page) {
          case 'off-cycle-payments':
          case 'lump-sum-payment-history':
            this.navigateByUrlCustom('/processing/off-cycle-payments');
            break;
          case 'report':
            this.navigateByUrlCustom('/processing/report/register-summary');
            break;

          case 'next-payroll':
          case 'pension-payroll':
          case 'next-payment':
          case 'payment-history':
          case 'register':
          case 'service-provider':
          case 'payment-deductions':
          default:
            this.navigateByUrlCustom('/processing/next-payroll');
            break;
        }
        break;
      }

      case 'member': {
        let page = url.split('/')[2];
        switch (page) {
          case 'bulk':
            this.navigateByUrlCustom('/member/bulk/mixed');
            break;

          case 'benefit':
            this.navigateByUrlCustom('/member/benefit');
            break;

          case 'report':
            this.navigateByUrlCustom('/member/report/generated');
            break;

          case 'navigation':
          case 'detail':
          default:
            this.navigateByUrlCustom('/member');
            break;
        }
        break;
      }
      case 'employer':
        let page = url.split('/')[2];
        switch (page) {
          case '':
            this.navigateByUrlCustom('tolerance/parameter');
            break;
          default:
            this.navigateByUrlCustom('/employer/employer-list');
            break;
        }
    }
  }

  private navigateByUrlCustom(urlRedirect: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlRedirect]);
    });
  }

  controlHeader() {
    this.showHeader = !this.showHeader;
    if (this.showHeader) {
      this.renderer.addClass(document.body, 'header-move-down');
      this.renderer.removeClass(document.body, 'header-move-up');
    } else {
      this.renderer.addClass(document.body, 'header-move-up');
      this.renderer.removeClass(document.body, 'header-move-down');
    }
  }

  getColumnValue(searchResult: SearchResult, index: number) {
    const currentMember = searchResult.members[index];
    return searchResult.columns.reduce((result, currentColumn) => {
      const columnKey = currentColumn.columnKey;
      if ([STATUS_COLUMNKEY.StatusEvent, STATUS_COLUMNKEY.StatusName].includes(columnKey)) {
        currentColumn.type = PropertyTypeEntity.TYPE_STATUS;
      }
      let value = '';
      let typedValue: any = {
        IconName: currentMember.IconName,
        Color: currentMember.Color,
        EventName: currentMember.EventName,
      };
      if (!currentColumn.listSubField.length) {
        value = currentMember[columnKey];
        if (value && currentColumn.type === PropertyTypeEntity.TYPE_DATE_TIME) {
          value = DateTime.fromISO(value, { zone: 'utc' }).toLocal().toFormat('MM/dd/yyyy hh:mm a');
        }
        if (currentColumn.type === PropertyTypeEntity.TYPE_ADDRESS) {
          typedValue.isSubFieldEmpty = true;
        }
      } else {
        value = currentColumn.listSubField.reduce((fieldValue, currentItem) => {
          let currentSubItemValue = currentMember[currentItem.key];
          switch (currentColumn.type) {
            case PropertyTypeEntity.TYPE_STATUS:
            case PropertyTypeEntity.TYPE_PERSON_NAME: {
              if (currentItem.key === PropertyTypeEntity.TYPE_STATUS_DATE && currentSubItemValue) {
                currentSubItemValue = DateTime.fromISO(currentSubItemValue).toFormat('MM/dd/yyyy');
              }
              if (fieldValue === null) {
                fieldValue = '';
              }
              fieldValue += `${!fieldValue || !currentSubItemValue ? '' : ', '}${currentSubItemValue || ''}`;
              break;
            }
            case PropertyTypeEntity.TYPE_ADDRESS: {
              if (fieldValue === null) {
                fieldValue = {};
              }
              fieldValue[currentItem.option.charAt(0).toLocaleLowerCase() + currentItem.option.substring(1)] =
                currentSubItemValue;
            }
          }
          return fieldValue;
        }, null as any);
      }
      let type = deepClone(currentColumn.type);
      if (type === PropertyTypeEntity.TYPE_PERSON_NAME) {
        type = PropertyTypeLabel.Text;
      }
      result[currentColumn.columnName] = {
        value,
        type,
        typedValue: typedValue,
      };
      return { ...result, noProfile: currentMember.NoProfile, entityId: currentMember.entityId };
    }, {} as any);
  }

  checkParticipantSearchConfig() {
    this.memberStore
      .pipe(select(fromMember.selectParticipantSearchPropertyDisplayConfigurationsEntity), takeUntil(this.unsubscribe$))
      .subscribe((propertyDisplayConfigurations) => {
        this.hasConfig = (deepClone(propertyDisplayConfigurations) as SearchConfigurationDisplayConfig[]).some(
          (config) => config.orderRow !== null,
        );
      });
    this.store
      .pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$))
      .subscribe((currentFund) => {
        if (currentFund?.id && !IGNOTE_URL_SEARCH.includes(this.router.url)) {
          this.memberStore.dispatch(getModuleConnectionsAction());
        }
      });
  }

  getDataEntity(): void {
    let query: GetEntitiesQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortField: 'Name',
      sortType: 0,
    };

    this.entityStore.dispatch(getEntitiesAction({ query: query }));
  }

  getEmployerInfoForHeader() {
    if (this.authService.employerId) {
      this.store.dispatch(
        getEmployerInfoForHeaderAction({
          employerId: this.authService.employerId,
        }),
      );
    }
  }

  registerGetEmployerForHeaderSelector() {
    this.store.pipe(select(getEmployerInfoForHeaderSelector)).subscribe((data) => {
      if (data) {
        if (data.success === true) {
          this.employerName = data?.payload?.name ?? '';
        }
        this.store.dispatch(clearGetEmployerInfoForHeaderStateAction());
      }
    });
  }

  getCurrentEmployer() {
    this.store.pipe(select(fromReducer.selectCurrentEmployerState), takeUntil(this.unsubscribe$)).subscribe((ele) => {
      if (ele) {
        this.employerName = ele.name;
      }
    });
  }
}
