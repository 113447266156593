<ptg-dialog class="recalculate-survivor-benefit-dialog" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    <ptg-overview-header-entity #overViewHeader [targetIdFromDialog]="data.memberId"></ptg-overview-header-entity>
  </ng-template>

  <ng-template dialogBody>
    <div class="flex flex-col gap-6">
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

      <div class="flex gap-2">
        <ptg-button classInput="primary" buttonName="Save" (click)="onSubmit()" [isDisabled]="isLoading"></ptg-button>
        <ptg-button
          classInput="tertiary"
          buttonName="Cancel"
          (click)="onCancel()"
          [isDisabled]="isLoading"
        ></ptg-button>
      </div>

      <form [formGroup]="formData" *ngIf="!isLoading">
        <ng-container *ngIf="propertyValueFromPriorStepsFormArray?.length">
          <div
            class="flex flex-col benefit-other-component-container mb-4"
            *ngIf="propertyValueFromPriorStepsFormArray?.length"
          >
            <div
              *ngFor="let property of propertyValueFromPriorStepsFormArray.controls; let index = index"
              class="value-container flex"
            >
              <ptg-dynamic-input
                [class]="'w-1/2'"
                [controlField]="$any(property.get('value'))"
                [formControlDetail]="propertyValueFromPriorStepConfigs[index]"
              ></ptg-dynamic-input>
            </div>
          </div>
        </ng-container>
      </form>

      <div id="loading-check" class="is-loading-section" *ngIf="isLoading">
        <div class="wrap-item">
          <mat-progress-spinner
            role="progressbar"
            aria-label="Progress Spinner"
            diameter="50"
            mode="indeterminate"
          ></mat-progress-spinner>
          <p>Loading...</p>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
