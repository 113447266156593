<div class="participant-detail-dialog-container">
  <div class="dialog-title-container">
    <div class="dialog-title-information">
      <span class="dialog-title">{{
        participantRelationshipDetail.entityRelationshipType ? 'Non-participant Detail' : 'Participant Detail'
      }}</span>
      <div class="view-profile-container" (click)="viewProfile()">
        <mat-icon>person</mat-icon>
        <span>View Profile</span>
      </div>
    </div>
    <div class="close-dialog-container" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="participant-detail-container" *ngIf="!isLoading">
    <div class="detail-item" *ngFor="let item of detailData">
      <span class="label">{{ item.label }}</span>
      <span class="value">
        <ptg-view-value
          [data]="{ value: item.value, type: item.type, typedValue: item?.typedValue }"
          [isSeparateLineAddress]="true"
        ></ptg-view-value>
      </span>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
