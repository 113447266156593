import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as RelatedPersonActions from '../../store/actions/related-person.action';
import { GetListRelatedPersonQuery, History } from '../../types/models';
import { RelatedPerson } from '@ptg-member/types/models/related-person.model';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { EntityProfileFixType } from '@ptg-entity-management/types/enums';
import { LIST_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';
import { EntityViewItem } from '@ptg-entity-management/services/models';

const PAGE_SIZE_CONST = '-ptg-related-person-pageSize';

@Component({
  selector: 'ptg-related-person-entity',
  templateUrl: './related-person-entity.component.html',
  styleUrls: ['./related-person-entity.component.scss']
})
export class RelatedPersonEntityComponent extends BaseComponent {
  @Input() memberId!: string;
  @Input() screenId!: string;
  @Input() configCard!: EntityViewItem;

  readonly EntityProfileFixType = EntityProfileFixType;
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;
  columns: Column[] = [
    {
      name: 'relationship',
      sortable: true,
      header: {
        title: 'Relationship',
      },      
    },
    {
      name: 'personName',
      header: {
        title: 'Related Person',
      },
      sortable: true,
      type: ColumnType.EntityReference,
      disableLink: true
    },
  ];

  relatedPersonData: RelatedPerson[] = [];
  sortInfo?: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex!: number;
  memberStatusHistoryId?: string;
  currentFund: any = {};
  isLoading = false;
  personId?: string;
  cardTitle:string = "Related Person";

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) {
    super()
   }

  ngOnInit(): void {
    super.ngOnInit();

    this.getCardTitle();
    this.getCurrentFund();
    this.getData();
    this.getRelatedPersonSelector();
    this.getOverviewURL();
  }

  getCardTitle() {
    this.store.pipe(select(fromMember.selectEntityViewItems), takeUntil(this.unsubscribe$)).subscribe((el) => {
      const relatedPersonEntity = el?.payload?.entityViewItems.find((x) => x.listLabel === 'Related Person');
      this.cardTitle =
      relatedPersonEntity && relatedPersonEntity.cardName ? relatedPersonEntity?.cardName : 'Related Person';
    });
  }

  getOverviewURL() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const entityId = state?.memberNavigationList.entityId;
        const deepestProfileTargetId = state?.memberNavigationList.deepestProfileTargetId;

        if (state?.menu && state?.menu.length > 0 && this.personId && deepestProfileTargetId) {
          const overviewView = state.memberNavigationList as any;
          const url = `${overviewView?.isOverviewDetailView 
            ? '/member/detail-view/true' 
            : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${deepestProfileTargetId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${entityId}`;

          this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
          this.store.dispatch(
            LayoutActions.selectTab({
              tab: DisplayedTabName.IndividualParticipant,
              url,
            }),
          );
        }
      });
  }

 
  getRelatedPersonSelector() {
    this.memberStore
    .pipe(
      select(fromMember.selecRelatedPersonState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      this.lengthPg = state?.relatedPersonList.total;
      this.isLoading = state?.isLoading;
      this.relatedPersonData = state?.relatedPersonList?.relatedPersons ?? [];
    });
  }

  getCurrentFund() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = null;
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListRelatedPersonQuery = {
      screenId: this.screenId,
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      ...(this.sortInfo && sortNames ? {
        sortNames: sortNames,
        sortType: sortType,
      } : {})
    };
    
    this.currentRowIndex = 0;
    this.memberStore.dispatch(
      RelatedPersonActions.getRelatedPersonList({ query })
    );
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    
    this.getData();
  }

  onEditMemberStatus(statusHistory?: History) {
    // TODO: Out-Scope
  }

  selectRow(event: any) {
    // TODO: Out-Scope

  }

  addStatusHistory() {
    // TODO: Out-Scope

  }

  manageStatusHistory() {
    // TODO: Out-Scope

  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
