
import '../app/shared/auth/extensions/auth0.extension';

export const environment = {
  production: false,
  devTeam: window['ngEnv'].dev.team,
  apiUrl: `https://${window['ngEnv'].dev.apiUrl}`,
  blobUrl: `https://${window['ngEnv'].dev.blobUrl}/media/`,
  memberAppUrl: `https://${window['ngEnv'].dev.memberAppUrl}/f`,
  appInsights: {
    instrumentationKey: window['ngEnv'].appInsightsKey
  },
  auth0Config: {
    config: {
      apiUrl: `https://${window['ngEnv'].dev.apiUrl}`,
      domain: 'auth.ptgppplus.org',
      clientId: "In3sPNhAb2iyEUcFLXFqvq59HYzLzdsD",
      audience: 'https://ptgportaldevuat.onmicrosoft.com/ptg-admin',
      redirectUri: window.location.origin,
      errorPath: '/error',
      httpInterceptor: {
        allowedList: [`*`],
      },
      appKey: window['ngEnv'].dev.appKey,
      databaseConnection: 'Username-Password-Authentication',
      devTeam: window['ngEnv'].dev.team,
    },
    claimNamespace: 'https://ptg.pension.com/'
  },
  stripePublicKey: window['ngEnv'].stripePublicKey,
  sawLogoutUrl: window['ngEnv'].sawLogoutUrl,
};

declare global {
  interface Window { ngEnv: any; }
}
