<div class="member-detail-display" [ngClass]="{ 'section-non-list': !isList }">
  <div class="detail-header">
    <span class="title truncate">{{ isList ? 'Selected ' : '' }}{{ menuItemName }} </span>
    <div class="space-line"></div>
    <div class="edit-btn" *ngIf="!isBenefitCard" (click)="edit()">
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
    <div class="edit-btn" (click)="remove()" *ngIf="isList && !isBenefitCard">
      <mat-icon>delete_forever</mat-icon>
      <span class="title-edit"> Remove</span>
    </div>
  </div>
  <div class="detail-content">
    <div
      class="first-row"
      [ngClass]="{
        column4:
          attachmentType === IncludeAttachmentType.CardLevel || attachmentType === IncludeAttachmentType.NoAttachment
      }"
      *ngFor="let item of listValue"
    >
      <div class="item">
        <label>{{ item?.title }}</label>
        <p class="value">
          <ng-container [ngSwitch]="item.type">
            <span *ngSwitchCase="EntityPropertyType.Currency">{{ (item.value | numberLocalDecimal: '$') || '-' }}</span>
            <span *ngSwitchCase="EntityPropertyType.Date">{{ item.value || '-' }}</span>
            <span *ngSwitchCase="EntityPropertyType['Person Name']">{{
              (item.value | personName: item.options) || '-'
            }}</span>
            <div class="value w-100" *ngSwitchCase="EntityPropertyType.Address">
              {{ item.value || '-' }}
            </div>
            <span *ngSwitchCase="EntityPropertyType.Decimal">{{
              (item.value
                | numberDecimal: { decimal: item?.configs?.fractionalLengthInput, isCheckCurrentDecimal: true }) || '-'
            }}</span>
            <span *ngSwitchCase="EntityPropertyType['Whole Number']">{{ (item.value | numberDecimal) || '-' }}</span>
            <span *ngSwitchCase="EntityPropertyType.Lookup">{{ item.value || '-' }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Binary">{{
              item?.value === null || item?.value === undefined
                ? '-'
                : item?.value
                  ? item?.configs?.affirmative
                  : item?.configs?.negative
            }}</span>
            <span *ngSwitchCase="EntityPropertyType.Percentage"
              >{{ (item.value | numberLocalDecimal: '') || '-'
              }}{{ (item.value | numberLocalDecimal: '') ? '%' : '' }}</span
            >
            <span *ngSwitchCase="EntityPropertyType.Employer">{{ item.value || '-' }}</span>
            <span *ngSwitchCase="EntityPropertyType.Tier">{{ item.value || '-' }}</span>
            <span class="value" *ngSwitchCase="EntityPropertyType.Email">
              {{ (item.visibilityOff ? item?.value : item?.valueUnMasked) || '-' }}
            </span>
            <ng-container *ngSwitchCase="EntityPropertyType.RichText">
              <ptg-view-rich-text
                [title]="item?.title"
                [content]="item.value"
                [defaultContent]="'-'"
              ></ptg-view-rich-text>
            </ng-container>
            <span *ngSwitchCase="EntityPropertyType['Date Time']">{{
              (item?.value | date: 'MM/dd/yyyy hh:mm a') || '-'
            }}</span>
            <span *ngSwitchDefault>
              <span *ngIf="!item.masked; else ssn">
                {{ item.value || '-' }}
              </span>
              <ng-template #ssn>
                <span style="color: #b1b1b1" *ngIf="item.visibilityOff && item.value">XXX-XX-</span>
                <span>{{ item.visibilityOff ? item.value : item.valueUnMasked || '-' }}</span>
              </ng-template>
            </span>
          </ng-container>
          <span>
            &nbsp;
            <mat-icon *ngIf="item?.masked && item.value" class="masked-icon" matSuffix (click)="onClickIcon(item)">{{
              item.visibilityOff ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
