<ptg-dialog
  class="add-profile-dialog"
  [footerPosition]="'top'"
  [footerAlign]="'left'"
  [isFullScreen]="false"
  [title]="'Add New Profile'"
>
  <ng-template dialogBody>
    <form class="edit-form" *ngIf="!isLoading" [formGroup]="editForm">
      <div class="input-container">
        <div class="flex gap-2 group-control">
          <ptg-textbox
            [controlField]="nameCtrl"
            placeholder="Profile Name"
            [hasLabel]="true"
            errorAsync="Profile Name already exists."
            [maxLength]="100"
          ></ptg-textbox>
          <ptg-select
            [controlField]="entityCtrl"
            [listData]="listEntity"
            [isOptionObj]="true"
            placeholder="Entity"
            (changeOptionValue)="changeEntity()"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
        </div>
        <div class="flex gap-2 group-control">
          <ptg-select
            [controlField]="overviewViewCtrl"
            [listData]="listOverviewView"
            [isOptionObj]="true"
            placeholder="Overview - View"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
          <ptg-select
            [controlField]="overviewHeaderCtrl"
            [listData]="listOverviewHeader"
            [isOptionObj]="true"
            placeholder="Overview - Header"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
        </div>
        <div class="flex gap-2 group-control">
          <ptg-select
            [controlField]="subPageHeaderCtrl"
            [listData]="listSubPageHeader"
            [isOptionObj]="true"
            placeholder="Sub-page Header"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
        </div>
      </div>

      <div class="row-button">
        <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">
          Create & Continue
        </button>
        <button mat-raised-button type="button" class="secondary" (click)="formSubmit$.next(false)">
          Create & Add Another
        </button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
    </form>
    <div id="loading-form" *ngIf="isLoading">
      <div class="wrap-item">
        <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
