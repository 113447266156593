<div class="payment-detail">
  <div class="control-bar">
    <span class="name">{{ isHistory ? 'Transactions' : 'Payments' }}</span>
    <div
      *ngIf="
        !isHistory &&
        (!offCyclePayment ||
          (offCyclePayment && offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
  </div>
  <div style="overflow-x: auto">
    <table mat-table [dataSource]="dataSource" class="table-content">
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ 'Payment ' + (element?.index + 1) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionId">
        <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.transactionId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="postedTime">
        <th mat-header-cell *matHeaderCellDef>Posted Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.postedTime | date: 'MM/dd/yyyy hh:mm a' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentType">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.paymentType === 0 ? 'Check' : 'Direct Deposit' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef>% of Net Pay</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.percentage | numberDecimal: { decimal: 2 } }} <span style="color: #707070">%</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          <span style="color: #707070">$</span>
          {{ element?.amount | numberDecimal: { decimal: 2 } }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentContent?.account">
        <th mat-header-cell *matHeaderCellDef>Account #</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element?.paymentContent?.account">
            {{ element?.paymentContent?.bankName }} -
            <span style="color: #707070">{{ element?.paymentContent?.account | mask: true }}</span
            >{{
              (element?.paymentContent?.account | mask: false) +
                (element?.paymentContent?.accountType === 0 ? ' - Checking' : ' - Savings')
            }}
          </span>
          <span *ngIf="!element?.paymentContent?.account"> - </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
