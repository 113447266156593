<div class="generate-document-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" (clickBreadcrumb)="onClickBreadcrumb()"></ptg-breadcrumb>
  <ng-container *ngIf="entityType === EntityType.Participant">
    <ptg-overview-header-entity
      [isShowChangeMemberDetailBtn]="false"
      [targetIdFromDialog]="targetId"
      [isSubHeader]="true">
    </ptg-overview-header-entity>
  </ng-container>
  <form [formGroup]="generateDocForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="wrap-btn">
        <button mat-stroked-button type="submit" class="submit-button">Save</button>
        <button mat-stroked-button type="button" class="cancel-button" (click)="onCancel()">
          Cancel
        </button>
      </div>
      <div class="input-container">
        <ptg-select
          [placeholder]="'Template'"
          [controlField]="generateDocForm.get('template')"
          [listData]="listTemplate"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-template"
          customError="errMsgTemplate"
          (changeOptionValue)="onSelectTemplate($event)"
        ></ptg-select>
        <ptg-select
          [placeholder]="'File Extension'"
          [controlField]="generateDocForm.get('fileType')"
          [listData]="listType"
          [isOptionObj]="true"
          [isRequired]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
          class="select-type"
        ></ptg-select>
      </div>
      <div class="pb-3">
        <ptg-toggle-button
          [controlField]="generateDocForm.get('isShowPreview')"
          label="Show Preview"
        ></ptg-toggle-button>
      </div>
    </div>
  </form>
</div>
