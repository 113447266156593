import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';
import { ACTION_COLUMN, Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName, ItemMenuType } from '@ptg-shared/layout/constance/layout.const';
import { Menu, MenuItem } from '@ptg-shared/layout/models/layout.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { HistoryService } from '@ptg-shared/services/history.service';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { BaseComponent } from '@ptg-shared/components';
import { NextPaymentService } from '@ptg-processing/features/service-provider/services';

import { MemberBenefitListActions, MemberListActions } from '../../store/actions';
import { EstablishBenefitComponent } from '../../components/establish-benefit/establish-benefit.component';
import {
  FilterPropertyType,
  ICON_REGISTER_FIELDS,
  ICON_STATUS_FIELDS,
  IsEstablished,
  Operator,
} from '../../constance/member-list.const';
import { ColumnConfig, MemberList, MemberListQuery } from '../../types/models';
import * as fromMember from '../../store/reducers';
import { State } from '../../store/reducers/member-list.reducer';
import { MemberBenefitService } from '../../services/member-benefit.service';
import { MemberDetailService } from '../../services/member-detail.service';
import { MetadataSectionService } from '../../services/metadata-detail.service';
import { getColumConfig } from '../../helper';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import { SCREEN_ID } from '@ptg-member/constance/lookupTable.const';

const PAGE_SIZE_CONST = '-ptg-members-benefit-eligible-pageSize';

@Component({
  selector: 'ptg-members-benefit-eligible',
  templateUrl: './members-benefit-eligible.component.html',
  styleUrls: ['./members-benefit-eligible.component.scss'],
})
export class MembersBenefitEligibleComponent extends BaseComponent {
  columns: Column[] = [];
  errorMsg: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Benefit Eligible Participants ',
      url: '',
    },
  ];
  settings: Breadcrumb[] = [
    {
      name: 'Defined Benefits',
      url: '/member/benefit/defined-benefits',
    },
  ];
  isLoading: boolean = true;
  membersBenefitsData: (any & Row)[] = [
    {
      firstName: 'A',
      lastName: 'B',
    },
  ];
  lengthPg!: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  isLoadingMetadataSection: boolean = true;
  memberData: {
    demographicMetadata: any;
    memberList: MemberList | undefined;
  } = {
    demographicMetadata: null,
    memberList: undefined,
  };
  memberListStore$: Observable<State>;
  query!: MemberListQuery;
  currentFund: any = {};
  sortInfo: {} | any = {};
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private memberBenefitListService: MemberBenefitService,
    private store: Store<fromReducer.State>,
    private dialog: MatDialog,
    private metadataSectionService: MetadataSectionService,
    private historyService: HistoryService,
    private memberDetailService: MemberDetailService,
    private nextPaymentService: NextPaymentService,
    private router: Router,
  ) {
    super();
    this.memberListStore$ = memberStore.pipe(select(fromMember.selectMemberListState));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? 50;
      this.pageSize =
        Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0
          ? this.pageSize
          : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));

      if (this.currentFund.id !== el.id) {
        setTimeout(() => {
          this.getData();
        }, 100);
      }
      this.currentFund = el;
    });

    this.memberListStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.isLoading = el.isLoading;
      // Get an error message (if any)
      if (!el.memberList.success && el.memberList.error) {
        this.errorMsg = el.memberList.error.statusText;
      }
      this.memberData.memberList = el.memberList;
      this.lengthPg = el.memberList.total;
      this.mapMemberMetadata();
    });

    this.metadataSectionService
      .getMetadataSection('demographics')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (demographicMetadata) => {
          this.memberData.demographicMetadata = demographicMetadata;
          this.isLoadingMetadataSection = false;
          this.mapMemberMetadata();
        },
        () => {
          this.isLoadingMetadataSection = false;
        },
      );

    this.store
      .pipe(select(fromMember.selectMemberEstablishBenefitState), takeUntil(this.unsubscribe$))
      .subscribe((ele) => {
        if (ele && ele.state === 'Success') {
          this.store.dispatch(MemberBenefitListActions.clearMemberBenefitState());
          this.checkNextPaymentMenuConfigured(ele.memberId);
        }
      });
  }

  checkNextPaymentMenuConfigured(memberId: string) {
    this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId: memberId }));

    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          let listMenuItem: MenuItem[] = [];
          state.menu.forEach((item: Menu) => (listMenuItem = listMenuItem.concat(item.menuItems || [])));
          let url = '';
          const nextPaymentMenu = listMenuItem.find((item) => item?.type === ItemMenuType.NextPayment);
          url = nextPaymentMenu ? nextPaymentMenu.routerLink + memberId : '/member/detail/' + memberId;

          this.historyService.isBackNextPayroll = false;
          sessionStorage.setItem('isBackNextPayroll', this.historyService.isBackNextPayroll.toString());

          this.memberDetailService.memberId = memberId;
          this.nextPaymentService.memberId = memberId;

          this.store.dispatch(LayoutActions.selectTab({ tab: DisplayedTabName.IndividualParticipant, url }));
        }
      });
  }

  getData() {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = this.columns.find((column) => column.name === this.sortInfo.active)?.header?.title || '';
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.query = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
      listFilterInfo: [
        {
          propertyName: '',
          propertyKey: IsEstablished,
          itemKey: '',
          sectionName: '',
          options: '',
          type: FilterPropertyType.Text,
          values: ['true'],
          operator: Operator.NotEquals,
        },
      ],
      screenId: SCREEN_ID.ParticipantBenefitEligible,
    };
    this.memberStore.dispatch(
      MemberListActions.getMemberList({
        query: this.query,
        idClient: this.currentFund.id,
      }),
    );
  }
  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  mapMemberMetadata() {
    if (this.isLoading || this.isLoadingMetadataSection) {
      return;
    }
    this.columns = this.getConfigColumns(this.memberData.memberList?.columns || []);
    if (this.memberData.memberList?.members && this.memberData.demographicMetadata?.properties) {
      const data = JSON.parse(JSON.stringify(this.memberData.memberList.members)) || [];
      this.membersBenefitsData = data.map((member: any) => {
        member._canRemove = stringToBoolean(member._canRemove);
        if (member.namePrefix?.length) {
          member.firstName = `${member.firstName} (${member.namePrefix})`;
        }
        if (member.nameSuffix?.length) {
          member.lastName = `${member.lastName}, ${member.nameSuffix}`;
        }
        return {
          ...member,
          [ICON_REGISTER_FIELDS.registerIconColor]: '#707070',
          [ICON_REGISTER_FIELDS.registerIconName]: member.Fixed_registered ? 'account_circle' : '',
          [ICON_STATUS_FIELDS.statusIconName]: member._typedValue?.iconName,
          [ICON_STATUS_FIELDS.statusIconColor]: member._typedValue?.color,
          [ICON_STATUS_FIELDS.statusIconLabel]: member._typedValue?.name,
          [ICON_STATUS_FIELDS.eventIconLabel]: member._typedValue?.event,
        };
      });
      return;
    }
    this.membersBenefitsData = [];
  }
  getConfigColumns(columns: ColumnConfig[]) {
    const configColumns = [] as Column[];
    if (!columns.length) {
      return configColumns;
    }
    columns.forEach((column: any) => {
      const columConfig = getColumConfig(column.type, column.configValue, column.columnKey);
      configColumns.push({
        name: column.columnKey,
        header: {
          title: column.columnName,
        },
        sortable: !(column.type === PropertyType.TYPE_REGISTER),
        align:
          columConfig.type === ColumnType.Decimal
            ? Align.Right
            : column.type === PropertyType.TYPE_REGISTER
              ? Align.Center
              : undefined,
        type: columConfig.type,
        templateArgs: columConfig.templateArgs,
        truncate: true,
      });
    });
    configColumns.push({
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    });
    return configColumns;
  }

  onEditClick(event: any) {
    this.dialog.open(EstablishBenefitComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: event._memberId,
      },
    });
  }

  changePagging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }
}
