import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export interface Option {
  displayValue: string;
  value: any;
  iconConfig?: IconConfig;
  isHide?: boolean;
  valueDescription?: string;
  additionalDataConfig?: AdditionalDataConfig;
  extraData?: any;
  valueShow?: any;
  code?: string;
  displayValueStyle?: string;
  hasSeparator?: boolean;
}

export interface IconConfig {
  icon?: string;
  color?: string;
  isSvg?: boolean;
  iconFirst?: boolean;
}

export interface AdditionalDataConfig {
  value: string;
  style?: string;
}

@Component({
  selector: 'ptg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() listData: any[] | Option[] = [];
  @Input() errorRequire?: string;
  @Input() errorDuplicated?: string;
  @Input() errorAsync?: string;
  @Input() isDisabled?: boolean;
  @Input() isCheckChange?: boolean;
  @Input() isOptionObj?: boolean;
  @Input() class?: string;
  @Input() isSetMaxWidthSelect = false;
  @Input() customValidator?: ValidatorFn | ValidatorFn[];
  @Input() isRequired: boolean = false;
  @Input() isMultipleLineOption: boolean = false;
  @Input() panelClass: string = '';
  @Input() hasNoneValue: boolean = false;
  @Input() customError: string = '';
  @Input() noneValue: string = '(Blank)';
  @Input() hintText: string = '';
  @Input() isShowHintText: boolean = false;
  @Input() compareWithFunction: { (o1: any, o2: any): boolean } = (o1, o2) => o1 === o2;
  @Input() width?: string;
  @Input() isDisabledSelect?: boolean;
  @Input() isShowValue?: boolean;
  @Input() onlyTruncateTitle: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() isClearError: boolean = true;
  @Output() changeOptionValue = new EventEmitter();
  @Output() changeControlValue = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() showHintText: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('select') select: any;

  selected!: Option | undefined;
  isSelected: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.addValidation();
    this.setSelectedValue();
    setTimeout(() => {
      let inputWithAria = document.querySelectorAll('[aria-expanded="false"]');
      let inputRoleTag = document.querySelectorAll('[role="combobox"]');
      if (inputRoleTag.length > 0) {
        (function () {
          for (let i = 0; i < inputRoleTag.length; i++) {
            inputRoleTag[i].removeAttribute('role');
            // inputRoleTag[i].setAttribute('style', 'border: 2px solid blue;');
          }
        })();
      }
      if (inputWithAria.length > 0) {
        (function () {
          for (let i = 0; i < inputWithAria.length; i++) {
            inputWithAria[i].removeAttribute('aria-autocomplete');
            inputWithAria[i].removeAttribute('aria-expanded');
            inputWithAria[i].removeAttribute('aria-required');
            inputWithAria[i].removeAttribute('aria-labelledby');
          }
        })();
      }
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isDisabled && changes.isDisabled.currentValue) {
      this.controlField?.disable();
    } else if (changes && changes.isDisabled && !changes.isDisabled.currentValue) {
      this.controlField?.enable();
    }

    if (this.isOptionObj && changes.listData) {
      this.selected = this.listData?.find((data) => this.compareWithFunction(data.value, this.controlField.value));
    }
    if (changes.isRequired) {
      if ((this.isRequired || this.errorRequire) && this.isClearError) {
        this.controlField.addValidators(Validators.required);
        this.errorRequire = this.errorRequire ? this.errorRequire : `${this.placeholder} is required.`;
        this.controlField.updateValueAndValidity({ emitEvent: false });
      } else {
        this.controlField.removeValidators(Validators.required);
        this.controlField.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  ngAfterViewChecked() {
    // fix position for select overlay
    this.select._overlayDir.positions = [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ];
  }

  changeOption(row?: any) {
    this.isSelected = true;
    if (this.isOptionObj) {
      this.selected = this.listData?.find((data) => this.compareWithFunction(data.value, this.controlField.value));
    }
    this.changeOptionValue.emit(row);
  }
  onFocusValue() {
    this.onFocus.emit(true);
  }
  onFocusOutValue() {
    this.onFocusOut.emit(false);
  }
  addValidation() {
    if (this.customValidator) {
      this.controlField.addValidators(this.customValidator);
    }

    if ((this.isRequired || this.errorRequire) && this.isClearError) {
      this.controlField.addValidators(Validators.required);
      this.errorRequire = this.errorRequire ? this.errorRequire : `${this.placeholder} is required.`;
    }

    if (this.controlField.hasValidator(Validators.required)) {
      this.errorRequire = this.errorRequire || `${this.placeholder} is required.`;
    }
  }

  setSelectedValue() {
    if (this.isOptionObj) {
      this.controlField.valueChanges.subscribe((value: any) => {
        this.selected = this.listData?.find((data) => this.compareWithFunction(data.value, value));
        this.changeControlValue.emit();
      });
    }
  }
}
