<div class="header-metadata" *ngIf="overviewData || profileHeaders" [ngClass]="{ 'profile-header': isProfileHeader }">
  <div class="left-group">
    <div class="option">
      <button mat-button (click)="changeMemberDetail()" class="option-icon" [disabled]="navigationDisabled.previous">
        <mat-icon style="color: #707070">expand_less</mat-icon>
      </button>
      <button mat-button (click)="changeMemberDetail(true)" class="option-icon" [disabled]="navigationDisabled.next">
        <mat-icon style="color: #707070">expand_more</mat-icon>
      </button>
    </div>
  </div>
  <div class="main-group">
    <ng-container *ngIf="!isProfileHeader; else demographics">
      <div class="left-main-group">
        <div class="content">
          <div class="name">
            <span class="label">{{ overviewData?.[0]?.label }}</span>
            <span class="value">{{ overviewData?.[0]?.value | personName }}</span>
          </div>
          <div class="display-column">
            <span class="label">{{ overviewData?.[1]?.label }}</span>
            <div class="value-status">
              <div class="event-container">
                <ng-container *ngIf="overviewData?.[1]?.value">
                  <mat-icon class="icon-status" [ngStyle]="{ color: overviewData?.[1]?.config?.color }">
                    {{ overviewData?.[1]?.config?.iconName }}
                  </mat-icon>
                  <span class="event-name">{{ overviewData?.[1]?.value }}</span>
                </ng-container>
              </div>
              <ptg-button classInput="edit-button" (clickButton)="onEditMemberStatusEvent()">
                <mat-icon>edit</mat-icon>
              </ptg-button>
            </div>
          </div>
        </div>
      </div>
      <div class="right-main-group">
        <div class="header-button">
          <ng-container *ngIf="identityKey || canRemove || actionItems?.length">
            <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
              <mat-icon class="actions-icon">more_vert</mat-icon>
              <span class="menu-text">Actions</span>
              <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="member-action-menu">
              <ng-container *ngIf="!isLocked; else unlock">
                <button mat-menu-item (click)="lockAccount(true)" *ngIf="identityKey">
                  <mat-icon style="color: #629dba">lock</mat-icon>
                  Lock Account
                </button>
              </ng-container>
              <ng-template #unlock>
                <button mat-menu-item (click)="lockAccount(false)" *ngIf="identityKey">
                  <mat-icon style="color: #629dba">lock_open</mat-icon>
                  Unlock Account
                </button>
              </ng-template>
              <button mat-menu-item (click)="removeMember()" *ngIf="canRemove">
                <mat-icon svgIcon="remove-account" aria-hidden="false" class="actions-icon"></mat-icon>
                Remove Participant
              </button>

              <button *ngFor="let item of actionItems" mat-menu-item (click)="onClickActionItem(item.id)">
                <mat-icon class="actions-icon">{{ item.icon }}</mat-icon>
                {{ item.title }}
              </button>
            </mat-menu>
          </ng-container>
        </div>
        <!-- <div class="header-button">
            <button mat-button [matMenuTriggerFor]="menuHistory" class="menu-button">
              <mat-icon class="actions-icon">history</mat-icon>
              <span class="menu-text">Member History</span>
              <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menuHistory="matMenu" class="member-action-menu">
              <button mat-menu-item (click)="statusHistory()"> <mat-icon aria-hidden="false" class="actions-icon">history</mat-icon>
                Status History
              </button>
              <button mat-menu-item (click)="serviceHistory()"><mat-icon aria-hidden="false" class="actions-icon">upload_file</mat-icon>
                Service History
              </button>
              <button mat-menu-item (click)="earnHistory()"><mat-icon aria-hidden="false" class="actions-icon">upload_file</mat-icon>
                Ded/Earns History
              </button>
              <button mat-menu-item (click)="earningSummary()"><mat-icon aria-hidden="false" class="actions-icon">upload_file</mat-icon>
                Earnings Summary
              </button>
            </mat-menu>
          </div> -->
      </div>
    </ng-container>

    <ng-template #demographics>
      <ng-container *ngIf="profileHeaders?.length; else noProfileConfig">
        <div class="demographics-data" *ngFor="let item of profileHeaders">
          <span class="label">{{ item.columnName }}</span>
          <ng-container [ngSwitch]="item?.type">
            <span class="value" *ngSwitchCase="'Currency'">{{ (item.value | numberLocalDecimal: '$') || '-' }}</span>
            <span class="value" *ngSwitchCase="'Percentage'"
              >{{ (item.value | numberLocalDecimal: '') || '-'
              }}{{ (item.value | numberLocalDecimal: '') ? '%' : '' }}</span
            >
            <span class="value" *ngSwitchCase="'Date'">{{
              !item.value ? '-' : (item.value | date: 'MM/dd/yyyy')
            }}</span>
            <span class="value" *ngSwitchCase="'Person_Name'">{{
              !item.value ? '-' : (item.value | personName: item?.typedValue)
            }}</span>
            <span class="value" *ngSwitchCase="'Address'">{{ !item.value ? '-' : (item.value | address) }}</span>
            <span class="value" *ngSwitchCase="'Decimal'">{{
              !item.value && item.value !== 0
                ? '-'
                : (item.value
                  | numberDecimal
                    : {
                        decimal: item?.typedValue?.fractionalLengthInput,
                        isCheckCurrentDecimal: true
                      })
            }}</span>
            <span class="value" *ngSwitchCase="'Whole_Number'">{{ (item.value | numberDecimal) || '-' }}</span>
            <span class="value" *ngSwitchCase="'Employer'">{{ item.value || '-' }}</span>
            <span class="value" *ngSwitchCase="'Tier'">{{ item.value || '-' }}</span>
            <span class="value" *ngSwitchCase="'Status'">
              <div class="icon-status-container">
                <mat-icon
                  *ngIf="item?.value && item?.typedValue?.iconName"
                  class="icon-status"
                  [ngStyle]="{ color: item?.typedValue?.color }"
                  >{{ item?.typedValue?.iconName }}</mat-icon
                >
                <div class="status-name">
                  {{ !item.value ? '-' : item.value }}
                </div>
              </div>
            </span>
            <span class="value" *ngSwitchCase="'SSN'">{{ (item.value | ssnNumber) || '-' }}</span>
            <ng-container *ngSwitchCase="'RichText'">
              <ptg-view-rich-text
                [title]="item?.columnName"
                [content]="item.value"
                [defaultContent]="'-'"
              ></ptg-view-rich-text>
            </ng-container>
            <span class="value" *ngSwitchCase="'DateTime'">{{
              !item.value ? '-' : (item?.value | date: 'MM/dd/yyyy hh:mm a')
            }}</span>
            <span class="value" *ngSwitchDefault>{{ item.value || '-' }}</span>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #noProfileConfig>
        <div class="demographics-data no-config">
          <span>No Properties Configured.</span>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div class="right-group" *ngIf="!isProfileHeader">
    <button (click)="close()" mat-button class="btn-close">
      <mat-icon class="icon-close">close</mat-icon>
      <span class="label">Close</span>
    </button>
  </div>
</div>
