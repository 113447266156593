<div class="header">
  <div class="icon-div"><mat-icon>delete_forever</mat-icon></div>
  <span class="title">Remove Item</span>
</div>
<ng-container *ngIf="!isLoading">
  <div class="content" *ngIf="isReplace; else confirm">
    <p>Removing this Value requires replacement for all places using it, is this your intention?</p>
    <ptg-select
      placeholder="Replacement Value"
      [controlField]="editForm.get('replacementValue')"
      [listData]="lookupTableOptions"
      [isOptionObj]="true"
      [isSetMaxWidthSelect]="true"
    ></ptg-select>
  </div>

  <div class="footer">
    <button
      mat-raised-button
      (click)="onSubmit()"
      [disabled]="isReplace && !editForm.value?.['replacementValue']"
      class="submit-button"
    >
      Yes
    </button>
    <button mat-stroked-button (click)="onNoClick()">No</button>
  </div>
</ng-container>

<div id="loading-table" *ngIf="isLoading">
  <div class="wrap-item">
    <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
    </mat-progress-spinner>
    <p>Loading...</p>
  </div>
</div>

<ng-template #confirm>
  <div class="content">
    <p>Are you sure you want to remove this Value: {{ data?.lookupValueDescription }}?</p>
  </div>
</ng-template>
