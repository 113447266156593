import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CalculationParameter } from '@ptg-member/features/calculation/services/models';
import { CalculationState } from '@ptg-member/features/calculation/store';
import { CalculationParameterType } from '@ptg-member/features/calculation/types/enums';
import { SectionConfig } from '@ptg-member/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  ACTION_COLUMN,
  Align,
  Column,
  getValidatorsFromColumns,
  GridComponent,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { Option } from '@ptg-shared/controls/select/select.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Component({
  selector: 'ptg-add-parameter-configuration',
  templateUrl: './add-parameter-configuration.component.html',
  styleUrls: ['./add-parameter-configuration.component.scss'],
})
export class AddParameterConfigurationComponent extends BaseComponent implements OnChanges {
  readonly CalculationParameterType = CalculationParameterType;
  readonly ACTION_COLUMN = ACTION_COLUMN;

  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<CalculationParameter>;
  @ViewChild('sortRowTable')
  gridviewRow!: GridComponent<CalculationParameter>;

  parameterTypeOptions: Option[] = [
    {
      displayValue: 'Input',
      value: CalculationParameterType.Input,
    },
    {
      displayValue: 'Output',
      value: CalculationParameterType.Output,
    },
  ];

  @Input() isLoading: boolean = false;
  @Input() parameterNameOptions!: Option[];
  @Input() propertyOptions!: Option[];
  @Input() inputOptions!: Option[];
  @Input() outputOptions!: Option[];
  @Input() configurations: CalculationParameter[] = [];
  @Input() sortRowSection!: SectionConfig;
  @Input() labelMaxLength: number = 150;
  @Input() canSelectMultipleOption: boolean = false;
  @Input() canSelectMultipleTimes: boolean = false;
  @Input() sortable: boolean = true;
  @Output() onSubmitEvent: EventEmitter<CalculationParameter[]> = new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();

  orderColumns: Column[] = [
    {
      name: 'label',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) => `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.labelMaxLength),
          message: (error: any, fieldName: string) => `Exceed the ${error.requiredLength} character limit.`,
        },
      },
      controlArgs: {
        placeholder: 'Parameter Label',
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  orderParameterDataTable: (CalculationParameter & Row)[] = [];
  listInitiateOrPendingApproval: (CalculationParameter & Row)[] = [];
  formData: FormGroup = this.fb.group({
    clientId: this.layoutService.fundId,
    type: this.fb.control('', [Validators.required]),
    calculationParamMappingId: '',
    parameterName: this.fb.control('', [Validators.required]),
    label: this.fb.control('', [Validators.required, Validators.maxLength(this.labelMaxLength)]),
    overrideOnInput: '',
    benefitOptionName: '',
  });
  isMultipleLineOption: boolean = false;
  canSubmit: boolean = false;
  isDragDrop: boolean = false;
  saveToPropertyOptions!: Option[];
  overrideOnInputOptions!: Option[];
  initiatedOrPendingApprovalErrorMessage: string = '';

  isShowGranulation: boolean = false;
  granulationValue!: number;
  isDisableField: boolean = true;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public calculationStore: Store<CalculationState>,
    public router: Router,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configurations) {
      this.getDataTable();
    }

    if (changes.inputOptions || changes.propertyOptions || changes.outputOptions) {
      this.changeParameterType();
      this.getOverrideOnInputOptions();
    }
    if (changes.labelMaxLength) {
      this.formData.get('label')?.addValidators(Validators.maxLength(this.labelMaxLength));
    }
  }

  ngOnInit(): void {
    this.changeParameterType();
  }

  getDataTable() {
    this.orderParameterDataTable = this.configurations
      .map((config) => this.createInlineEditFormControls(config))
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.listInitiateOrPendingApproval = this.configurations.filter((item) => item.isInitiatedOrPendingApproval);
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.canSubmit = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.orderParameterDataTable = this.gridview.dataSource;
      return;
    }
  }

  onSoftDeleteConfig(row: CalculationParameter & Row): void {
    row.deleted = true;
    const sortItem = this.orderParameterDataTable.find(
      (item) =>
        (item.id && row.id && item.id === row.id) ||
        (item.type === row.type && item.calculationParamMappingId === row.calculationParamMappingId),
    );
    if (sortItem) {
      sortItem.deleted = true;
    }
  }

  resetAddConfigForm() {
    this.formData.reset({
      clientId: this.layoutService.fundId,
      type: '',
      calculationParamMappingId: '',
      parameterName: '',
      label: '',
      overrideOnInput: '',
      benefitOptionName: '',
    });
    this.isShowGranulation = false;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDataTable();
        this.resetAddConfigForm();
        this.getOverrideOnInputOptions();
        this.canSubmit = false;
        this.isDisableField = true;
      }
    });
  }

  changeParameterType() {
    this.parameterNameOptions = deepClone(
      this.formData.get('type')?.value === CalculationParameterType.Input
        ? this.getParameterNameOptions()
        : deepClone(this.outputOptions).filter(
            (item) =>
              !this.orderParameterDataTable.find((parameter) => parameter.calculationParamMappingId === item.value.id),
          ),
    ).sort((a: any, b: any) => a.displayValue - b.displayValue);

    this.isDisableField = !this.formData.get('type')?.value;
    this.formData.get('label')?.setValue('');
    this.getOverrideOnInputOptions();
  }

  addParameter() {
    this.formData.markAllAsTouched();
    if (!this.formData.valid) {
      return;
    }
    const formValue = deepClone(this.formData.value);
    const isInitiatedOrPendingApproval = formValue.parameterName.isInitiatedOrPendingApproval;
    if (isInitiatedOrPendingApproval) {
      this.formData.get('parameterName')?.setErrors({ isInitiatedOrPendingApproval: true });
      this.initiatedOrPendingApprovalErrorMessage = formValue.parameterName.benefitEntityName;
      return;
    }
    const type = formValue.parameterName.aggregationId ? CalculationParameterType.Aggregation : formValue.type;
    const parameterName =
      formValue.parameterName.propertyName || formValue.parameterName.calculationName || formValue.parameterName.name;
    const description = [
      parameterName,
      CalculationParameterType[formValue.type],
      formValue.parameterName.benefitEntityName,
    ]
      .filter((x) => !!x)
      .join('/');
    const newRecord: CalculationParameter = {
      id: formValue.id,
      clientId: formValue.clientId,
      calculationType: 0,
      type,
      calculationParamMappingId: formValue.parameterName.id,
      label: formValue.label,
      order: formValue.order,
      overrideOnInput: formValue.overrideOnInput.id,
      orderColumn: formValue.orderColumn,
      benefitOptionName: formValue.parameterName.benefitEntityName,
      parameterName,
      description,
      options: this.granulationValue,
    };
    this.orderParameterDataTable = [...this.orderParameterDataTable, this.createInlineEditFormControls(newRecord)];
    this.resetAddConfigForm();
    this.changeParameterType();
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  onSubmit() {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    if (this.checkIsInitiateOrPendingApprovalEdited()) {
      const ALERT_MESSAGE = `Unable to edit or remove parameter when Benefit Option is already initiated.`;
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        disableClose: true,
        autoFocus: false,
        data: {
          text: ALERT_MESSAGE,
          title: 'Warning',
          cancelButtonTitle: 'Close',
          type: ConfirmType.Confirm,
          hideConfirmButton: true,
        },
      });

      return;
    }
    const calculationParameters = this.getSubmitData();
    this.onSubmitEvent.emit(calculationParameters);
    this.resetAddConfigForm();
    this.canSubmit = false;
  }

  checkIsInitiateOrPendingApprovalEdited() {
    const calculationParameters = this.getSubmitData();

    return calculationParameters.some((savingItem) =>
      this.listInitiateOrPendingApproval.some(
        (item) =>
          savingItem.id === item.id &&
          (savingItem.order !== item.order ||
            savingItem.label?.trim()?.toLowerCase() !== item.label?.trim()?.toLowerCase()),
      ),
    );
  }

  getSubmitData() {
    let order = 1;
    const result: (CalculationParameter & Row)[] = this.orderParameterDataTable.map(
      (item: CalculationParameter & Row) => {
        const obj = {
          ...item,
          orderColumn: item.deleted ? -1 : order,
          order: item.deleted ? -1 : order,
          label: item.form?.value?.label,
        };
        order += item.deleted ? 0 : 1;
        delete obj.form;
        return obj;
      },
    );
    return result;
  }

  onChangeOrderColumns() {
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  changeParameterName() {
    const formValue = this.formData?.value;
    const label =
      formValue.parameterName.propertyName || formValue.parameterName.calculationName || formValue.parameterName.name;
    this.formData.get('label')?.setValue(label);
    this.getOverrideOnInputOptions();
    this.isShowGranulation =
      formValue.parameterName.dataType && parseInt(formValue.parameterName.dataType) === EntityPropertyType.Text;
  }

  getOverrideOnInputOptions() {
    const formValue = this.formData.value;
    const isInputMapping = this.formData.get('type')?.value === CalculationParameterType.Input;
    this.overrideOnInputOptions = deepClone(this.inputOptions).filter((item) => {
      return (
        !this.isAdded(item) && (!isInputMapping || (isInputMapping && formValue.parameterName?.id !== item.value.id))
      );
    });
  }

  changeOverrideOnInput() {
    const isInputMapping = this.formData.get('type')?.value === CalculationParameterType.Input;
    if (!isInputMapping) {
      return;
    }
    this.parameterNameOptions = this.getParameterNameOptions();
  }

  private getParameterNameOptions() {
    const isInputMapping = this.formData.get('type')?.value === CalculationParameterType.Input;
    const formValue = this.formData.value;
    return deepClone(this.inputOptions)
      .filter((item) => {
        return (
          (!isInputMapping || (isInputMapping && formValue.overrideOnInput?.id !== item.value.id)) &&
          !this.isAdded(item)
        );
      })
      .sort((a: any, b: any) => a.displayValue - b.displayValue);
  }

  private isAdded(item: Option) {
    return this.orderParameterDataTable.find(
      (parameter) =>
        parameter.overrideOnInput === item.value.id || parameter.calculationParamMappingId === item.value.id,
    );
  }

  private createInlineEditFormControls(calculationParameter: CalculationParameter): CalculationParameter {
    return {
      ...calculationParameter,
      form: this.fb.group({
        label: this.fb.control(
          calculationParameter.label,
          getValidatorsFromColumns(this.orderColumns[0].name, this.orderColumns, calculationParameter as any),
        ),
      }),
    } as any;
  }

  compareWithFunction(o1: any, o2: any) {
    return o1?.id === o2?.id;
  }

  changeGranulationValue(event: any) {
    this.granulationValue = event;
  }
}
