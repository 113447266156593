import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { AsyncFunction, Holiday } from '@ptg-shared/types/models/common.model';
import { isNumeric } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { DateTime } from 'luxon';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'M/d/yyyy',
  },
  display: {
    dateInput: 'MM/dd/y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'MMMM y',
  },
  minDate: new Date(1900, 0, 2),
  maxDate: new Date(9999, 11, 31),
};

export const MY_DATE = {
  minDate: new Date(1900, 0, 2),
  maxDate: new Date(9999, 11, 31),
};

@Component({
  selector: 'ptg-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: class CustomAdapter extends LuxonDateAdapter {
        override parse(
          value: any,
          parseFormat: string | string[]
        ): DateTime | any {
          if (typeof value !== 'string') {
            return value;
          }
          if (typeof parseFormat === 'string') {
            const selectedDate = DateTime.fromFormat(value, parseFormat);
            if (selectedDate.isValid) {
              return selectedDate;
            } else {
              return value;
            }
          }
          if (Array.isArray(parseFormat) && parseFormat.length) {
            for (let i = 0; i < parseFormat.length; i++) {
              const selectedDate = DateTime.fromFormat(value, parseFormat[i]);
              if (selectedDate.isValid) {
                return selectedDate;
              }
            }
            return value;
          }
          return value;
        }
      },
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() class?: string;
  @Input() minDate = MY_DATE.minDate;
  @Input() maxDate = MY_DATE.maxDate;
  @Input() checkMinDate?: boolean;
  @Input() isDisable?: boolean;
  @Input() isValidation?: boolean;

  @Input() minTime!: any;
  @Input() errorRequire?: string;
  @Input() errorRange?: string;
  @Input() filterWeekends?: boolean = false;
  @Input() errorMaxDate?: string;
  @Input() errorMinDate?: string =
    'Chosen date must not be less than current date.';
  @Input() errorHoliday?: string;
  @Input() customError: string = '';
  @Input() errorAsync?: string;
  @Input() asyncFn?: AsyncFunction;
  @Input() filterHolidays?: Holiday[];

  @Output() changeValue = new EventEmitter();
  @Output() onOutFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() onInput: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMouseDownEmitter: EventEmitter<any> = new EventEmitter<any>();

  errorInvalid? = 'Invalid Date format.';
  day = 0;
  constructor() {}

  ngOnInit(): void {
    if (this.minDate === MY_DATE.minDate) {
      this.errorMinDate = 'Invalid Date format.';
    }
    if (this.asyncFn && this.asyncFn.function && this.asyncFn.fieldCheck) {
      this.controlField.addAsyncValidators(
        checkApiValidator(
          this.asyncFn.function,
          this.asyncFn.fieldCheck,
          this.asyncFn.editValue,
          this.asyncFn.config
        )
      );
    }

    if (this.isValidation) {
      this.errorMinDate = this.errorMaxDate = 'The input does not meet the validation.';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRequired) {
      if (this.isRequired) {
        this.controlField.addValidators(Validators.required);
        this.errorRequire = this.errorRequire
          ? this.errorRequire
          : `${this.placeholder} is required.`;
      } else {
        this.controlField.removeValidators(Validators.required);
      }
      this.controlField.updateValueAndValidity({ emitEvent: false });
    }
    if (changes?.isDisabled) {
      if (changes.isDisabled.currentValue) {
        this.controlField?.disable();
      } else {
        this.controlField?.enable();
      }
    }

    if (this.isDisabled) {
      this.controlField?.disable();
    }
  }

  weekendsDatesFilter = (d: Date | null): boolean => {
    if (this.filterWeekends) {
      const day = d ? new Date(d.valueOf()): new Date();
      var holidays: Date[] = [];
      if (this.filterHolidays && d != null) {
        holidays = this.holidaysFilter(day);
        if (day.getDay() === 0 || day.getDay() === 6){
          this.errorHoliday = this.placeholder + ' must not be on the weekend.';
        }
        else {
          this.errorHoliday = holidays.length > 0 ? this.errorHoliday || `${this.placeholder} must not be on the bank holiday.` : '';
        }
        return day.getDay() !== 0 && day.getDay() !== 6 && !(holidays.length > 0 && holidays.find(x => (x.getDay() !== 0 && x.getDay() !== 6) && (x.getDay() === day.getDay() && x.getMonth() === day.getMonth() && x.getFullYear() === day.getFullYear())));
      }
      return day.getDay() !== 0 && day.getDay() !== 6;
      // Prevent Saturday and Sunday from being selected.
    } else {
      return true;
    }

  };

  holidaysFilter = (fullDate: Date): Date[] => {
    const days: Date[] = [];
    if (this.filterHolidays) {
      this.filterHolidays.forEach(holiday => {
        var holidayDay = new Date(holiday.holidayDate);
        if (holidayDay.getTime() === fullDate.getTime()) {
          days.push(holidayDay);
        }
      });
    }
    return days;
  }

  onChangeValue(event: any) {
    this.changeValue.emit(event.value);
  }

  onKeypress(event: any) {
    const position = event?.srcElement?.selectionStart;
    if (
      event.code === 'Space' &&
      (position === 0 || position === event?.target?.value?.length)
    ) {
      event.preventDefault();
    }
  }

  onFocusOut(event: any) {
    this.onOutFocus.emit(event.value);
  }

  handleInput(event: any) {
    this.onInput.emit(event.value);
  }

  onMouseDown(event: any): void {
    this.onMouseDownEmitter.emit(event);
  }
}
