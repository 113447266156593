import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { debounceTime, filter, finalize, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, of, timer } from 'rxjs';

import {
  getDateString,
  getValueWithoutFormat,
  isEmpty,
  isString,
  setFractionalLength,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import { BaseComponent } from '@ptg-shared/components';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { LookupTableType, ValidationType, ValidationOperator } from '@ptg-shared/types/enums';
import {
  BaseActionState,
  DateValidationValue,
  FieldData,
  FormControlDetail,
  OptionValue,
  PropValidation,
} from '@ptg-shared/types/models';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { AutocompleteComponent } from '@ptg-shared/controls/autocomplete';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { deepClone, toCamelCase } from '@ptg-shared/utils/common.util';
import { Option } from '@ptg-shared/controls/select/select.component';
import { ENTITY_ID_GUID, ENTITY_PERSON_GUID, GUID_EMPTY } from '@ptg-shared/constance';
import { checkUnique } from '@ptg-shared/validators/checkUnique.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';

import { EntityDataService, EntityPropertyService } from '@ptg-entity-management/services';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import {
  clearGetDetailsEntityDatasStateAction,
  clearSearchEntityDatasStateAction,
  getDetailsEntityDatasAction,
  searchEntityDatasAction,
  getEntityMetadataAction,
} from '@ptg-entity-management/store/actions';
import {
  DetailOptionStatus,
  EntityEntityComponent,
  GetEntityMetaDataResponse,
  InitiationProperty,
  SearchResult,
} from '@ptg-entity-management/services/models';
import { EntityInitiationPropertyValue } from '@ptg-entity-management/services/models';
import {
  FixedPropertyKey,
  LIST_INPUT_ADDRESS,
  LIST_INPUT_PERSON_NAME,
  LIST_INPUT_STATUS,
  LIMIT_PHONE_NUMBER,
  UnitedStates,
  PERSON_PROPERTY_MAPPING,
  ENTITY_MAPPING
} from '@ptg-entity-management/constants';
import { EntityState } from '@ptg-entity-management/store/reducers';
import {
  detailsEntityDatasSelector,
  getEntityMetadataSelector,
  searchEntityDatasSelector,
} from '@ptg-entity-management/store/selectors';
import { PropertyTypeEntity } from '@ptg-member/constance/metadataPropertyType.const';
import { DateTime } from 'luxon';
import { PropertyTypeLabel } from '@ptg-member/constance/metadata.const';

import * as fromMember from '../../../member/store/reducers';
import { AddressHistoryAction, clearSetMemberEventStateAction } from '@ptg-member/store/actions';
import { RelatedPerson } from '@ptg-member/types/models';
import { isEINProperty } from '@ptg-member/helper';
import { EmployerCheckDateOfBirthRequest } from '@ptg-employer/models/employer.model';

@Component({
  selector: 'ptg-edit-property-entity-reference',
  templateUrl: './edit-property-entity-reference.component.html',
  styleUrls: ['./edit-property-entity-reference.component.scss'],
})
export class EditPropertyEntityReferenceComponent extends BaseComponent {
  @ViewChild('autoSearch') autoSearch!: AutocompleteComponent<any>;
  @Input() property!: InitiationProperty;
  @Input() cardInfor!: {
    id: string;
    isSummaryView: boolean;
    isList: boolean;
  };
  @Input() screenId: any;
  @Input() listUnique!: { name: string; value: any }[];
  @Input() uniqueOtherReference!: { parent: string; name: string; value: any }[];
  @Input() recordData!: any[];
  @Input() isEdit!: boolean;
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controls: FormControlDetail[] = [];

  @Output() onRecordChange: EventEmitter<any> = new EventEmitter();
  @Output() entityRealSender: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddNew: EventEmitter<any> = new EventEmitter<boolean>();

  isLoading: boolean = true;

  readonly EntityPropertyType = EntityPropertyType;
  readonly MY_DATE = MY_DATE;
  readonly ENTITY_PERSON_GUILD = ENTITY_PERSON_GUID;
  readonly UnitedStates = UnitedStates;

  title?: string;
  isSaving = false;
  isMemberER: boolean = false;

  listAllStatus: any[] = [];
  listStatusEvent: Option[] = [];
  isNoPropertyConfig = false;
  haveAddressOrPerson = false;
  isShowAddNewToggle = true;

  currentMemberDetailUnmask: any;
  relatedPersonList: RelatedPerson[] = [];
  datePropValidation: PropValidation[] = [];
  dateValidationValue: DateValidationValue[] = [];

  optionsEntity: RadioOption[] = [];

  @Input() entityCtrl = new FormControl();
  @Input() isMuniPortal!: boolean;
  entityReal = '';
  addToggleCtrl = new FormControl(false);
  entityComponentId = '';

  // Search Member
  @Input() recordId?: string = '';
  @Input() targetId?: string = '';
  searchContent: string = '';
  searchResultColumns: string[] = [];
  searchResultList: Record<string, any>[] = [];
  isSearching = true;
  isSearchError = false;
  listEntityComponent: EntityEntityComponent[] = [];
  validatorPrevious: any = {};
  dataSource: EntityInitiationPropertyValue[] | undefined;
  formSubmit$ = new Subject<boolean>();
  dataSourceDefault: EntityInitiationPropertyValue[] = [];
  allEntityProperties!: Record<string, any>;
  initiationProperties: InitiationProperty[] | undefined;
  constructor(
    public dialogRef: MatDialogRef<EditPropertyEntityReferenceComponent>,
    private entityPropertyService: EntityPropertyService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private entityStore: Store<EntityState>,
    private memberStore: Store<fromMember.MemberState>,
    private entityDataService: EntityDataService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Receive initiation properties from source screen
    this.title = `${this.isEdit ? 'Edit ' : 'Add '}${this.property.label}`;

    // Check this property is Member ER
    this.isMemberER = this._checkIsMemberER(this.property);

    this._loadOptionsEntity(this.property.options);
    this._getEntityMetaDataSelector();
    this._searchParticipantsSelector();
    this._detailsEntityDatasSelector();
    this._getRelatedPersonSelector();
  }
  private _checkIsMemberER(prop: InitiationProperty): boolean {
    if (!prop?.configs?.entityReference)
      return false;

    const propConfig = JSON.parse(prop.configs.entityReference);
    let entityReferences = propConfig?.entityreferences ?? propConfig?.EntityReferences; // TO DO: BE NEED TO RETURN COSISTENT DATA
    return entityReferences?.some((x: any) => x.entityid?.toLocaleLowerCase() === ENTITY_MAPPING.Member || x.EntityId?.toLocaleLowerCase() === ENTITY_MAPPING.Member);
  }

  private _getEntityMetaDataSelector() {
    this.entityStore.select(getEntityMetadataSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isLoading = !state ? true : state?.isLoading;
        this._getEntityMetadata(state);
      });
  }

  private _searchParticipantsSelector() {
    this.entityStore.pipe(select(searchEntityDatasSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      this.isSearching = !!state?.isLoading;
      this.isSearchError = !!state?.error;
      if (state && !state.isLoading && state.success && state.payload) {
        let searchData: SearchResult = deepClone(state.payload.searchResult);
        this.searchResultColumns = (searchData.columns ?? []).map((item) => item.columnName);
        this.searchResultList = (searchData.members ?? []).map((result, index) => {
          return {
            ...this._getColumnValue(searchData, index),
            id: result.Id,
            entityId: result.entityId,
          };
        });
      } else {
        this.searchResultList = [];
      }
    });
  }

  private _detailsEntityDatasSelector() {
    this.entityStore.pipe(select(detailsEntityDatasSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.payload?.record) {
        if (state.payload.propertyId !== this.property.entityPropertyId) {
          return;
        }

        if (state?.payload?.record) {
          this._mappingEntityReferenceData(state.payload.record);
        }
      }
    });
  }

  private _mappingEntityReferenceData(data: any) {
    this.dataSourceDefault = [];
    let values = deepClone(data);
    if (this.allEntityProperties) {
      values = Object.keys(values).map((i) => {
        return {
          ...this.allEntityProperties[i],
          value: values[i],
          recordId: this.recordId,
        };
      });

      this._setValue(values);
    }

    //enable property after select
    this.controls.forEach((f) => {
      if (f.formControl !== undefined) {
        if (!stringToBoolean(f.configs?.readOnly)) {
          f.formControl.enable();
        }
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined && !stringToBoolean(f.configs?.readOnly)) {
            x.formControl.enable();
          }
        });
      }
    });
  }

  private _getEntityMetadata(state?: BaseActionState<GetEntityMetaDataResponse>) {
    if (!state?.isLoading && state?.success && state?.payload && !this.allEntityProperties) {

      this.allEntityProperties = {};
      state?.payload.entities.forEach((i) => {
        const arrayData = [...(i.components || [])];
        arrayData.forEach(({ entityProperties }: { entityProperties: any }) => {
          entityProperties.forEach((j: any) => {
            this.allEntityProperties[j.id] = j;
          });
        });
      });

      const efProperties = this.property.options?.find((item: any) => item.id === this.entityCtrl.value);
      if (efProperties?.properties?.length > 0) {
        const properties = efProperties?.properties?.filter((x: any) => x.type !== EntityPropertyType.Calculation &&
          x.type !== EntityPropertyType.Aggregation && x.type !== EntityPropertyType.System);
        this.isNoPropertyConfig = false;
        this.entityComponentId = properties[0].entityComponentId;
        this.haveAddressOrPerson = properties.findIndex((item: any) => item.type === EntityPropertyType.Address || item.type === EntityPropertyType['Person Name']) > -1;
        this._loadPropertiesToForm(this.entityCtrl.value, properties);
        this.dataSourceDefault = [];
        if (this.dataSource && !this.property.initiationValue) {
          this._setValue(this.dataSource);
        }
        this._checkInitiationProperties(efProperties.properties);
      } else {
        this.isNoPropertyConfig = true;
      }

      if ((this.property.initiationValue && this.recordId)) {
        this.getDataReference(null, this.recordId);
      }
    }
  }

  private _loadOptionsEntity(entityReference: any): void {
    this.optionsEntity = entityReference.map((item: any) => {
      return {
        label: item.label,
        value: item.id,
      };
    });

    this.dataSource = this.property.referenceDatas ?? [];

    if (this.dataSource && this.dataSource?.length > 0) {
      const selectedRecord = this.dataSource.find((x) => x.entitySelectedId && x.recordLinkedId);
      this.entityCtrl.setValue(selectedRecord?.entitySelectedId || this.optionsEntity[0].value);
      this.recordId = selectedRecord?.recordLinkedId;
      this.onRecordChange.emit({
        id: this.property?.entityPropertyId,
        value: this.recordId,
      });
    } else {
      const linkedEntity = (this.recordData || []).find(
        (item) =>
          item.entityId === this.property.entityId &&
          item.entityPropertyId === this.property.entityPropertyId &&
          item.entityComponentId == this.property.entityComponentId
      );

      this.recordId = linkedEntity?.recordLinkedId;
      this.onRecordChange.emit({
        id: this.property?.entityPropertyId,
        value: this.recordId,
      });
      this.entityCtrl.setValue(linkedEntity?.entityReferenceLinkedId || this.optionsEntity[0].value);
    }
    this.entityReal = this.entityCtrl.value;

    const getEntityOptionSelected = this.property.options.find((item) => item.id === this.entityCtrl.value);

    if (getEntityOptionSelected?.allowAddNew) {
      const onePropertyHavingValue = this.dataSource?.find((x) => x.value);
      this.addToggleCtrl.setValue(onePropertyHavingValue?.value && !this.recordId && !this.property.initiationValue);
      this.isShowAddNewToggle = true;
    } else {
      this.isShowAddNewToggle = false;
    }

    this.entityStore.dispatch(getEntityMetadataAction());

    this.entityRealSender.emit(this.entityReal);
  }

  private _loadPropertiesToForm(entityId: string, properties: InitiationProperty[]): void {
    [...properties].forEach((prop) => {
      if (prop.type === EntityPropertyType.Address || prop.type === EntityPropertyType['Person Name']) {
        // Address type | Person name type
        const getMember = this.property.options.find((item) => item.id === entityId);
        let listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS);
        if (getMember && !getMember.isAddressHistory) {
          listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS).filter((item: any) => {
            return item?.key !== 'effectTo' && item?.key !== 'effectFrom';
          });
        }
        let defaultCountry = '';
        const childFields =
          prop.type === EntityPropertyType.Address
            ? deepClone(listInputAddressDefault)
            : deepClone(LIST_INPUT_PERSON_NAME);
        childFields.forEach((field: FieldData) => {
          field.formControl = new FormControl(null);
          field.lstOption = [];

          if (prop.options?.length) {
            if (field.type === 'select' && prop.configs?.prefixList === 'true') {
              field.lstOption = prop.options.map((x: any) => ({
                displayValue: x.text,
                value: x.id,
              }));
            } else if (field.key === 'state') {
              field.lstOption = prop.options
                .filter((option: OptionValue) => option.type === LookupTableType.State)
                .map((option: OptionValue) => ({
                  displayValue: option.text,
                  value: option.id,
                }));
            } else if (field.key === 'country') {
              field.lstOption = prop.options
                .filter((option: OptionValue) => option.type === LookupTableType.Country)
                .map((option: OptionValue) => ({
                  displayValue: option.text,
                  value: option.id,
                  code: option.description,
                }));
              defaultCountry = field.lstOption.find(item => item.code === this.UnitedStates)?.value;
              field.formControl.setValue(defaultCountry ? defaultCountry : null);
            }
          }
          const fromElement = childFields?.find((el) => el.key === 'effectFrom');
          const toElement = childFields?.find((el) => el.key === 'effectTo');
          fromElement?.formControl?.addValidators(this._effectiveDate(fromElement, toElement));

          if (stringToBoolean(prop.configs?.readOnly) || (!this.addToggleCtrl.value && !this.recordId)) {
            field.formControl.disable();
          }
          if (prop.type === EntityPropertyType.Address && defaultCountry && prop.configs?.required === 'false') {
            childFields?.forEach((item: FieldData) => {
              if (!['effectTo', 'street2'].includes(item.key)) {
                item.isSetRequired = true;
                item?.formControl?.addValidators(Validators.required);
                item?.formControl?.updateValueAndValidity();
              }
            });
          }
        });

        this.controls.push({
          name: prop.entityPropertyId,
          label: prop.label,
          type: prop.type,
          lstChildFormControl: childFields,
          isRequired: prop.configs?.required === 'true',
          data: prop,
          configs: prop.configs,
        } as FormControlDetail);
      } else if (prop.type === EntityPropertyType.Status) {
        // Status type | Person name type
        const childFields = deepClone(LIST_INPUT_STATUS);
        childFields.forEach((field: FieldData) => {
          field.formControl = new FormControl();
          field.lstOption = [];

          if (prop.options?.length && field.key === 'status') {
            field.lstOption = prop.options
              .filter((item) => item.active)
              ?.map((item) => {
                return {
                  value: item.id,
                  displayValue: item.name,
                };
              });
            this.listAllStatus = prop.options;
          }

          if (stringToBoolean(prop.configs?.readOnly) || (!this.addToggleCtrl.value && !this.recordId)) {
            field.formControl.disable();
          }
        });

        this.controls.push({
          name: prop.entityPropertyId,
          label: prop.label,
          type: prop.type,
          lstChildFormControl: childFields,
          data: prop,
        } as FormControlDetail);
      } else {
        // One input type
        const formControl = new FormControl(null);
        const controlDetail: FormControlDetail = {
          name: prop.entityPropertyId,
          label: prop.label,
          type: prop.type,
          lstOption: [],
          formControl: formControl,
          isRequired: prop.configs?.required === 'true',
          maxLength: 'none',
        };

        // validate by property config
        const isExistingMinVal =
          stringToBoolean(prop.configs?.inclusiveInRange) &&
          isString(prop.configs?.minInput) &&
          !isNaN(Number(prop.configs?.minInput));
        const isExistingMaxVal =
          stringToBoolean(prop.configs?.inclusiveInRange) &&
          isString(prop.configs?.maxInput) &&
          !isNaN(Number(prop.configs?.maxInput));
        const isExistingMaxLengthVal =
          stringToBoolean(prop.configs?.maximumLength) &&
          isString(prop.configs?.maxLengthInput) &&
          !isNaN(Number(prop.configs?.maxLengthInput));
        const isExistingFracLengthVal =
          stringToBoolean(prop.configs?.fractionalLength) &&
          isString(prop.configs?.fractionalLengthInput) &&
          !isNaN(Number(prop.configs?.fractionalLengthInput));

        if (isExistingMinVal) {
          controlDetail.min = prop.configs?.minInput.trim() ? prop.configs?.minInput.trim() : undefined;
        }
        if (isExistingMaxVal) {
          controlDetail.max = prop.configs?.maxInput.trim() ? prop.configs?.maxInput.trim() : undefined;
        }
        if (isExistingMaxLengthVal && !isExistingMinVal && !isExistingMaxVal) {
          controlDetail.maxLength = prop.configs?.maxLengthInput;
        }
        let propTransformedType = prop.type;
        if (isExistingFracLengthVal) {
          const fractionalLength = Number(prop.configs?.fractionalLengthInput);
          if (fractionalLength === 0) {
            propTransformedType = EntityPropertyType['Whole Number'];
          } else {
            const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`);
            formControl.addValidators(Validators.pattern(rgx));
            controlDetail.fractionalLength = fractionalLength.toString();
          }
        }

        switch (prop?.type) {
          case EntityPropertyType.Currency:
            formControl.setValue(null);
            break;
          case EntityPropertyType.Decimal:
            if (!isExistingFracLengthVal) {
              propTransformedType = EntityPropertyType['Whole Number'];
            }

            break;
          case EntityPropertyType.Percentage:
            formControl.addValidators(Validators.min(0));
            controlDetail.min = 0;

            formControl.addValidators(Validators.max(100));
            controlDetail.max = 100;

            const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
            formControl.addValidators(Validators.pattern(rgx));
            controlDetail.fractionalLength = 2;
            break;
          case EntityPropertyType.Date:
            let minDate: Date | undefined = undefined;
            let maxDate: Date | undefined = undefined;

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (prop.configs?.excludeFutureDates?.toLowerCase() == 'true') {
              currentDate.setHours(24);
              maxDate = currentDate;
            }

            if (prop.configs?.excludePastDates?.toLowerCase() == 'true') {
              currentDate.setHours(-24);
              minDate = currentDate;
            }

            if (prop.configs?.dateValidation?.toLowerCase() == 'true') {
              controlDetail.isValidation = true;
              const rules = JSON.parse(prop.configs.dateValidationExpressions);
              if (rules?.length > 0) {
                rules.forEach((rule: any) => {
                  switch (rule.validationType) {
                    case ValidationType.Year:
                      const yearValidation = +rule.value;
                      if (yearValidation) {
                        const vaidationDate = new Date();
                        vaidationDate.setFullYear(vaidationDate.getFullYear() - yearValidation);
                        if (rule.validation === ValidationOperator.LessThan) {
                          let dateVal = new Date(vaidationDate);
                          dateVal.setHours(0);
                          if (minDate == undefined || dateVal > minDate) {
                            minDate = dateVal;
                          }
                        }

                        if (rule.validation === ValidationOperator.GreaterThan) {
                          let dateVal = new Date(vaidationDate);
                          dateVal.setHours(0);
                          if (maxDate == undefined || dateVal < maxDate) {
                            maxDate = dateVal;
                          }
                        }
                      }
                      break;
                    case ValidationType.SpecificDate:
                      const dateValidation = rule.value;
                      if (dateValidation) {
                        if (rule.validation === ValidationOperator.LessThan) {
                          const dateVal = new Date(dateValidation);
                          dateVal.setHours(0);
                          if (maxDate == undefined || dateVal < maxDate) {
                            maxDate = dateVal;
                          }
                        }

                        if (rule.validation === ValidationOperator.GreaterThan) {
                          const dateVal = new Date(dateValidation);
                          dateVal.setHours(0);
                          if (minDate == undefined || dateVal > minDate) {
                            minDate = dateVal;
                          }
                        }
                      }
                      break;
                    case ValidationType.Property:
                      this.datePropValidation.push({
                        property: prop.entityPropertyId,
                        validationProp: rule.value,
                        operator: rule.validation,
                      });
                  }
                });
              }
            }

            this.dateValidationValue.push({
              property: prop.entityPropertyId,
              max: maxDate,
              min: minDate,
            });

            if (minDate) {
              this.setMinDate(controlDetail, minDate);
            }

            if (maxDate) {
              this.setMaxDate(controlDetail, maxDate);
            }
            this._setDateValidationMessage(controlDetail);
            break;
          default:
            break;
        }

        // set list option for List type
        let lstOption: any[] = [];
        if (
          (prop.type === EntityPropertyType.Lookup || prop.type === EntityPropertyType.Tier) &&
          prop.options?.length
        ) {
          lstOption = prop.options.map((x) => ({
            displayValue: x.text,
            value: x.id,
          }));
        } else if (
          (prop.type === EntityPropertyType.Employer || prop.type === EntityPropertyType.Department) &&
          prop.options?.length
        ) {
          lstOption = prop.options
            .filter((x) => x.active)
            .map((x) => ({
              displayValue: x.text,
              value: x.id,
              valueDescription: x.description,
            }));
        }

        if (stringToBoolean(prop.configs?.readOnly) || (!this.addToggleCtrl.value && !this.recordId)) {
          formControl.disable();
        }

        // Check exists entity property value
        if (prop.configs?.unique === 'true') {
          formControl.addAsyncValidators(
            checkApiValidator(
              this.entityPropertyService.checkExistPropertyValue,
              'value',
              undefined,
              {
                params: {
                  entityId: prop.entityId,
                  componentId: prop.entityComponentId,
                  propertyId: prop.entityPropertyId,
                  propertyType: prop.type,
                },
              },
              () => {
                this.formGroup.updateValueAndValidity();
              },
            ),
          );
          this.listUnique.forEach((uniqueField) => {
            if (uniqueField.name === prop.entityPropertyId) {
              if (this.validatorPrevious[prop.entityPropertyId]) {
                formControl.removeValidators(this.validatorPrevious[prop.entityPropertyId]);
              }
              if (uniqueField.value) {
                this.validatorPrevious[prop.entityPropertyId] = checkUnique(
                  uniqueField.value,
                  prop?.label + ' is already exists.',
                );
                formControl.addValidators(this.validatorPrevious[prop.entityPropertyId]);
                formControl.updateValueAndValidity();
              }
            }
          });
          this.uniqueOtherReference.forEach((uniqueField) => {
            if (uniqueField.name === prop.entityPropertyId) {
              if (!this.validatorPrevious[uniqueField.parent]) {
                this.validatorPrevious[uniqueField.parent] = {};
              }
              if (
                this.validatorPrevious[uniqueField.parent] &&
                this.validatorPrevious[uniqueField.parent][prop.entityPropertyId]
              ) {
                formControl.removeValidators(this.validatorPrevious[uniqueField.parent][prop.entityPropertyId]);
              }
              if (uniqueField.value) {
                this.validatorPrevious[uniqueField.parent][prop.entityPropertyId] = checkUnique(
                  uniqueField.value,
                  prop?.label + ' is already exists.',
                );
                formControl.addValidators(this.validatorPrevious[uniqueField.parent][prop.entityPropertyId]);
                formControl.updateValueAndValidity();
              }
            }
          });
        }

        // Validate DateOfBirth only for Member entity
        if (this.isMuniPortal && this.isMemberER && prop.entityPropertyId.toLocaleLowerCase() === PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase()) {
          controlDetail.formControl.addAsyncValidators(this._validateDateOfBirth());
        }

        if (prop.entityPropertyId === FixedPropertyKey.LineOfDutyDeath) {
          formControl.addValidators(this._lineOfDutyDeathValidator());
          controlDetail.customError = 'invalidLineOfDutyDeath';
        }

        controlDetail.type = isEINProperty(prop.entityPropertyId) ? 'EIN' : prop.type;
        controlDetail.lstOption = lstOption;
        controlDetail.configs = prop.configs;
        controlDetail.data = prop;
        this.controls.push(controlDetail);
      }
    });

    this.controls.forEach((f) => {
      if (f.formControl !== undefined) {
        this.formGroup.setControl(f.name, f.formControl);
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            this.formGroup.setControl(f.name + x.name, x.formControl);
          }
        });
      }
    });
  }

  private _validateDateOfBirth(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const dateofBirthValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');

      if (dateofBirthValue === null) {
        return of(null);
      }
      const DOBType = this.controls.find(v => v.name.toLocaleLowerCase() === PERSON_PROPERTY_MAPPING.DateOfBirth.toLocaleLowerCase())?.recordId;
      const body: EmployerCheckDateOfBirthRequest = {
        memberId: DOBType ? DOBType : null ?? '',
        dateOfBirth: dateofBirthValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDateOfBirth(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { errorMsg: res?.message };
                }
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }


  _getRelatedPersonSelector() {
    const existEntityPersonOption = !!this.optionsEntity.find(option => option.value === this.ENTITY_PERSON_GUILD);

    if (existEntityPersonOption) {
      this.memberStore
        .pipe(
          select(fromMember.selecRelatedPersonState),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state) => {
          if (state && state?.relatedPersonList?.relatedPersons?.length) {
            this.relatedPersonList = state?.relatedPersonList?.relatedPersons;
            this.showListRelatedPerson();
          } else {
            this.searchResultList = [];
          }
        });
    }
  }

  showListRelatedPerson(entityCtrlValueLatest?: string) {
    if ((entityCtrlValueLatest || this.entityCtrl.value) === ENTITY_PERSON_GUID) {
      this.searchResultColumns = ['Name', 'Relationship'];
      this.searchResultList = this.relatedPersonList.map(
        result => {
          return {
            Name: { value: result.personName, type: EntityPropertyType[PropertyTypeLabel.Text] },
            Relationship: { value: result.relationship, type: EntityPropertyType[PropertyTypeLabel.Text] },
            id: result.personId,
            entityId: this.entityCtrl.value,
            isRelatedPersonList: true
          };
        }
      );
    }
  }

  onSearchInputValueChange(value: string) {
    if (this.entityCtrl.value === ENTITY_PERSON_GUID && value === '') {
      this.isSearching = false;
      this.isSearchError = false;
      this.showListRelatedPerson();
    }
  }

  onSearchFocus(value: string) {
    if (this.entityCtrl.value === ENTITY_PERSON_GUID && value === '') {
      this.showListRelatedPerson();
    }
  }

  setStatus(childForm: FieldData) {
    if (childForm.key === 'status') {
      this.listStatusEvent = this.listAllStatus
        .find((item) => item.id === childForm.formControl?.value)
        .events?.map((item: any) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
    }
  }

  private _lineOfDutyDeathValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) return null;

      const { value: dateOfDeath } = control.parent.get(FixedPropertyKey.DateOfDeath) as FormControl;
      if (control.value && !dateOfDeath) {
        return {
          invalidLineOfDutyDeath: 'Invalid Line of Duty Death.',
        };
      }

      return null;
    };
  }

  dateValueChange(event: any, form: any): void {
    if (this.datePropValidation) {
      //validate property rules
      const validateRules = this.datePropValidation.filter((o) => o.property === form.name);
      if (validateRules) {
        for (const element of validateRules) {
          const rule = element;
          const compareProperty = rule.validationProp;

          const compareForm = this.controls.find((o) => o.name === compareProperty);
          if (compareForm) {
            const compareControl = compareForm.formControl;
            if (compareControl) {
              const valueCompare = compareControl.value;
              if (valueCompare) {
                if (rule.operator === ValidationOperator.LessThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMinDateValidation(form.name, dateValidation);
                  this.setMinDate(form.formControl, dateValidation);
                }

                if (rule.operator === ValidationOperator.GreaterThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMaxDateValidation(form.name, dateValidation);
                  this.setMaxDate(form.formControl, dateValidation);
                }
                this._setDateValidationMessage(form.formControl);
              }
            }
          }
        }
      }

      //validate property
      const validateProps = this.datePropValidation.filter((o) => o.validationProp == form.name);

      validateProps.forEach((prop) => {
        const sourceForm = this.controls.find((o) => o.name === prop.property);
        if (sourceForm) {
          if (prop.operator === ValidationOperator.LessThan) {
            let valDate = new Date(event);
            valDate = this.compareMaxDateValidation(prop.property, valDate);
            this.setMaxDate(sourceForm, valDate);
          }

          if (prop.operator === ValidationOperator.GreaterThan) {
            let valDate = new Date(event);
            valDate = this.compareMinDateValidation(prop.property, valDate);
            this.setMinDate(sourceForm, valDate);
          }

          this._setDateValidationMessage(sourceForm);
        }
      });
    }
  }

  private compareMaxDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.max) {
        dateVal.setHours(0);
        if (val.max < dateVal) {
          dateVal = val.max;
        }
      }
    }
    return dateVal;
  }

  private compareMinDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.min) {
        dateVal.setHours(0);
        if (val.min > dateVal) {
          dateVal = val.min;
        }
      }
    }
    return dateVal;
  }

  private setMaxDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(-24);

      formControlDetail.max = vaidationDate;
    }
  }

  private setMinDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(24);
      formControlDetail.min = vaidationDate;
    }
  }

  private _setDateValidationMessage(formControlDetail: FormControlDetail): void {
    const min = formControlDetail.min as Date;
    const max = formControlDetail.max as Date;
    const current = new Date();

    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);

    formControlDetail.minMessage = formControlDetail.maxMessage = 'The input does not meet the validation.';

    if (min?.getTime() === current.getTime()) {
      formControlDetail.minMessage = 'The input must be greater than today';
    }

    if (max?.getTime() === current.getTime()) {
      formControlDetail.maxMessage = 'The input must be less than today';
    }

    if (max && min && min.getTime() >= max.getTime()) {
      formControlDetail.maxMessage = formControlDetail.minMessage = 'No date satifies the validation.';
    }
  }

  private _setValue(data: any) {
    this.dataSourceDefault = deepClone(data);
    let propValue: any;
    this.controls.forEach((element) => {
      propValue =
        data[element.name] ||
        data?.find((item: any) =>
          item.entityPropertyId ? item.entityPropertyId === element.name : item.id === element.name,
        ) ||
        null;
      element.recordId = data[ENTITY_ID_GUID] || propValue?.recordId;
      // Check exists entity property value
      if (element.configs?.unique === 'true' && element.recordId) {
        element.formControl.clearAsyncValidators();
        element.formControl.addAsyncValidators(
          checkApiValidator(
            this.entityPropertyService.checkExistPropertyValue,
            'value',
            undefined,
            {
              params: {
                entityId: propValue.entityId || propValue.entityReferenceId,
                componentId: propValue.entityComponentId || propValue.entityReferenceComponentId,
                propertyId: propValue.entityPropertyId || propValue.entityReferencePropertyId || propValue.id,
                id: propValue?.recordId,
                propertyType: element.type,
              },
            },
            () => {
              this.formGroup.updateValueAndValidity();
            },
          ),
        );
        element.formControl.updateValueAndValidity();
      }
      let value = propValue?.valueObj || propValue?.value || (typeof propValue !== 'object' ? propValue : null);
      if (value?.maskedValue || value?.maskedValue === null || value?.maskedValue === '') {
        value = value.originalValue;
      }

      if (isEmpty(value) && element.type !== EntityPropertyType.Binary) {
        return;
      }
      if (element.type == EntityPropertyType.Address || element.type == EntityPropertyType['Person Name']) {
        element.lstChildFormControl?.forEach((item: any) => {
          if (item?.key === 'country') {
            if (value[item.key]) {
              item.formControl?.setValue(value[item.key]);
            }
          } else {
            item.formControl?.setValue(
              item?.key === 'effectFrom' && value?.isClearEffectFrom ? null : value[item.key] || null,
            );
          }
        });
        if (element.type == EntityPropertyType.Address) {
          element.isActive = value?.id ? true : false;
        }
      } else if (element.type == EntityPropertyType.Phone) {
        element.formControl?.setValue(this.formatPhoneNumberInput(value));
      } else if (element.type == EntityPropertyType.Binary) {
        element.formControl?.setValue(value === 'true' || value ? true : false);
      } else if (element.type == EntityPropertyType.Status) {
        element.lstChildFormControl?.forEach((el) => {
          el.formControl?.setValue(value[el.key]);
          if (el.key === 'event' && propValue?.options) {
            this.listStatusEvent = propValue?.options
              ?.find((x: DetailOptionStatus) => x.id === value['status'])
              ?.events?.map((item: any) => {
                return {
                  value: item.id,
                  displayValue: item.name,
                };
              });
          } else if (!propValue?.options) {
            this.setStatus(el);
          }
        });
      } else if (element.type == EntityPropertyType.SSN) {
        element.formControl?.setValue(value);
      } else if (element.type === EntityPropertyType['Date Time']) {
        element.formControl?.setValue(getDateString(value));
      } else {
        if (element?.fractionalLength) {
          const fractionalLengthValue = setFractionalLength(value, element?.fractionalLength);
          element.formControl?.setValue((+value)?.toFixed(fractionalLengthValue));
        } else {
          element.formControl?.setValue(value);
        }
      }
    });
  }

  formatPhoneNumberInput(formControlValue: string) {
    const cleaned = ('' + formControlValue).trim().replace(/[^0-9]/g, '');
    let match = cleaned.match(LIMIT_PHONE_NUMBER);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return formControlValue;
  }

  changeEntity(event: any) {
    const getEntityOptionSelected = this.property.options.find((item) => item.id === event);

    this.initiationProperties = undefined;
    this.isShowAddNewToggle = getEntityOptionSelected?.allowAddNew;
    this.entityReal = event;
    this.entityRealSender.emit(this.entityReal);
    this.recordId = '';
    this.controls.splice(0);
    this.dataSource = undefined;
    this._loadPropertiesToForm(event, getEntityOptionSelected.properties?.filter((x: any) => x.type !== EntityPropertyType.Calculation &&
      x.type !== EntityPropertyType.Aggregation && x.type !== EntityPropertyType.System));
    if (getEntityOptionSelected?.properties?.length > 0) {
      this.isNoPropertyConfig = false;

      this._checkInitiationProperties(getEntityOptionSelected.properties);
    } else {
      this.isNoPropertyConfig = true;
    }

    this._clearSearchResult(event);
  }

  private _checkInitiationProperties(properties: any[]) {
    const hasInitiationProperty = (properties ?? []).some((item: any) => item.isInitiationProperty);
    if (!hasInitiationProperty) {
      this.isNoPropertyConfig = true;
    }
  }

  changeToggleAdd(event: boolean) {
    if (event) {
      this.onAddNew.emit(event);
      this._clearSearchResult();
    }
    this.recordId = '';
    this.formGroup.reset();

    this.controls.forEach((f) => {
      if (f.formControl !== undefined) {
        this.checkDisabledControl(event, f);
        f.formControl.setValue(null);
        this.formGroup.setControl(f.name, f.formControl);

        // Check exists entity property value
        if (f.configs?.unique === 'true') {
          f.formControl.clearAsyncValidators();
          f.formControl.addAsyncValidators(
            checkApiValidator(
              this.entityPropertyService.checkExistPropertyValue,
              'value',
              undefined,
              {
                params: {
                  entityId: f.data.entityId,
                  componentId: f.data.entityComponentId,
                  propertyId: f.data.entityPropertyId,
                  propertyType: f.type,
                },
              },
              () => {
                this.formGroup.updateValueAndValidity();
              },
            ),
          );
          f.formControl.updateValueAndValidity();
        }
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            x.formControl.setValue(null);
            this.checkDisabledControl(event, f, x);
            this.formGroup.setControl(f.name + x.name, x.formControl);
            if (f?.type === EntityPropertyType.Address) {
              f.isActive = false;
              if (x?.key === 'country') {
                const defaultCountry = (x?.lstOption || []).find(item => item.code === this.UnitedStates)?.value;
                x.formControl.setValue(defaultCountry ? defaultCountry : null);
              }
            }
          }
        });
      }

      //clear recordId
      if (event) {
        f.recordId = '';
      }
    });
  }

  checkDisabledControl(toggleAddValue: boolean, control: FormControlDetail, childFormControl?: any) {
    if (toggleAddValue) {
      if (!stringToBoolean(control.configs?.readOnly)) {
        control.formControl?.enable();
        if (childFormControl) {
          childFormControl.formControl.enable();
        }
      }
    } else {
      control.formControl?.disable();
      if (childFormControl) {
        childFormControl.formControl.disable();
      }
    }
  }

  //#region Search Member
  onSearchResult(value: string) {
    this.entityStore.dispatch(clearSearchEntityDatasStateAction());
    this.entityStore.dispatch(
      searchEntityDatasAction({
        searchValue: value,
        entityId: this.entityCtrl.value,
        propertyId: this.property.entityPropertyId
      }),
    );
  }

  private _getColumnValue(searchResult: SearchResult, index: number) {
    const currentMember = searchResult.members[index];
    return searchResult.columns.reduce((result, currentColumn) => {
      const columnKey = currentColumn.columnKey;
      let value = '';
      let typedValue: any = {
        IconName: currentMember.IconName,
        Color: currentMember.Color,
        EventName: currentMember.EventName,
      };
      if (!currentColumn.listSubField || !currentColumn.listSubField.length) {
        value = currentMember[columnKey];
        if (value && currentColumn.type === PropertyTypeEntity.TYPE_DATE_TIME) {
          value = DateTime.fromISO(value).toLocal().toFormat('MM/dd/yyyy hh:mm a');
        }
        if (currentColumn.type === PropertyTypeEntity.TYPE_ADDRESS) {
          typedValue.isSubFieldEmpty = true;
        }
      } else {
        value = currentColumn.listSubField.reduce((fieldValue, currentItem) => {
          let currentSubItemValue = currentMember[currentItem.key];
          switch (currentColumn.type) {
            case PropertyTypeEntity.TYPE_STATUS:
            case PropertyTypeEntity.TYPE_PERSON_NAME: {
              if (currentItem.key === PropertyTypeEntity.TYPE_STATUS_DATE && currentSubItemValue) {
                currentSubItemValue = DateTime.fromISO(currentSubItemValue).toFormat('MM/dd/yyyy');
              }
              if (fieldValue === null) {
                fieldValue = '';
              }
              fieldValue += `${!fieldValue || !currentSubItemValue ? '' : ', '}${currentSubItemValue || ''}`;
              break;
            }
            case PropertyTypeEntity.TYPE_ADDRESS: {
              if (fieldValue === null) {
                fieldValue = {};
              }
              fieldValue[currentItem.option.charAt(0).toLocaleLowerCase() + currentItem.option.substring(1)] =
                currentSubItemValue;
            }
          }
          return fieldValue;
        }, null as any);
      }
      let typeText = deepClone(currentColumn.type);
      let type;
      if (typeText === PropertyTypeEntity.TYPE_PERSON_NAME) {
        type = EntityPropertyType[PropertyTypeLabel.Text];
      } else {
        type = EntityPropertyType[typeText];
      }
      result[currentColumn.columnName] = {
        value,
        type,
      };
      return { ...result, noProfile: currentMember.NoProfile };
    }, {} as any);
  }

  onSelectResult(record: any, recordId?: string) {
    this.entityStore.dispatch(clearGetDetailsEntityDatasStateAction());
    let fields: string[] = [];
    this.controls.forEach((prop) => {
      fields.push(prop.name);
    });

    this.recordId = recordId ? recordId : record?.id;
    this.onRecordChange.emit({
      id: this.property?.entityPropertyId,
      value: this.recordId,
    });
    this.entityStore.dispatch(
      getDetailsEntityDatasAction({
        entityId: this.entityCtrl.value,
        entityComponentId: this.entityComponentId,
        entityPropertyId: this.property.entityPropertyId,
        id: recordId ? recordId : record?.id,
        fields: fields,
      }),
    );
  }

  getDataReference(record: any, recordId?: string) {
    let fields: string[] = [];
    this.controls.forEach((prop) => {
      fields.push(prop.name);
    });

    this.recordId = recordId ?? record?.id;
    this.onRecordChange.emit({
      id: this.property?.entityPropertyId,
      value: this.recordId,
    });

    this.entityDataService.getDetailsEntityDatas(this.entityCtrl.value, this.recordId ?? GUID_EMPTY).subscribe(
      res => {
        this._mappingEntityReferenceData(res.record ?? {});
      }
    );
  }

  onClickClearSearchResult(isReload: boolean): void {
    this.entityReal = this.entityCtrl.value;
    this.isSaving = false;
    this._clearSearchResult();
    this.changeToggleAdd(false);
  }

  private _clearSearchResult(event?: string): void {
    this.searchContent = '';
    this.recordId = '';
    this.autoSearch.clearSearch();
    this.showListRelatedPerson(event);
  }
  //#endregion

  onSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup.markAllAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  private saveValue(): void {
    if (!this.recordId && !this.addToggleCtrl.value) return;

    const entityPropertyValues: EntityInitiationPropertyValue[] = [];
    const datepipe = new DatePipe('en-US');
    for (const f of this.controls) {
      let value = f.formControl?.value;
      let valueObj: any;
      let valueMask = {
        maskedValue: '',
        originalValue: '',
      };
      switch (f.type) {
        case EntityPropertyType.Status:
        case EntityPropertyType.Address:
        case EntityPropertyType['Person Name']:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            if (childForm.type === 'date') {
              objVal[childForm.name] = childForm.formControl?.value
                ? datepipe.transform(childForm.formControl?.value, 'yyyy-MM-dd')
                : f.type === EntityPropertyType.Address
                  ? null
                  : '';
            } else {
              objVal[childForm.name] = childForm.formControl?.value;
            }
          }
          value = isHaveData ? JSON.stringify(objVal) : null;
          valueObj = value ? JSON.parse(value, (key, value) => toCamelCase(key, value)) : '';
          break;
        case EntityPropertyType.Date:
          value = value ? datepipe.transform(value, 'yyyy-MM-dd') : null;
          break;
        case EntityPropertyType.Binary:
          value = value ? true : false;
          break;
        case EntityPropertyType.Percentage:
          value = value?.toString() || null;
          value = (value?.endsWith('.') ? value?.substring(0, value.length - 1) : value) || null;
          break;
        case EntityPropertyType.Email:
          value = {
            maskedValue: value ? this.getMarkValueEmail(value) : value,
            originalValue: value,
          };
          break;
        case EntityPropertyType.SSN:
          value = {
            maskedValue: value ? this.getMarkValueSSN(getValueWithoutFormat(value)) : value,
            originalValue: value,
          };
          break;
        case EntityPropertyType.Phone:
          value = {
            maskedValue: value ? this.getMarkValuePhone(getValueWithoutFormat(value)) : value,
            originalValue: value,
          };
          break;
        default:
          value = value ? value?.toString() : null;
      }

      entityPropertyValues.push({
        configs: f.data.configs,
        type: f.data.type,
        label: f.data.label,
        options: f.data.options,
        entitySelectedId: this.entityCtrl.value,
        entityId: f.data.entityId,
        entityComponentId: f.data.entityComponentId,
        entityPropertyId: f.data.entityPropertyId,
        value: value,
        valueObj: valueObj,
        valueMask: valueMask,
        recordId: f.recordId,
        selectedRecordId: this.recordId,
        isInitiationProperty: f.data.isInitiationProperty,
        isComputedProperty: f.data.isComputedProperty,
      } as EntityInitiationPropertyValue);
    }
    const getMember = this.property.options.find((item) => item.id === this.entityCtrl.value);
    if (getMember && !getMember.isAddressHistory) {
      this.dialogRef.close({
        entityPropertyValues,
        entityReal: this.entityReal,
      });
      return;
    }
    if (this.dataSourceDefault?.length) {
      entityPropertyValues.forEach((item) => {
        if (item.type === EntityPropertyType.Address) {
          const getAddressId = this.dataSourceDefault?.find(
            (value: EntityInitiationPropertyValue) => value.id === item.entityPropertyId,
          );
          if (getAddressId?.value?.id || getAddressId?.valueObj?.id) {
            const cloneValue = item.value ? JSON.parse(item.value) : null;
            if (cloneValue) {
              cloneValue.id = getAddressId?.value?.id || getAddressId?.valueObj?.id;
              cloneValue.code = getAddressId?.value?.code || getAddressId?.valueObj?.code;
              item.value = JSON.stringify(cloneValue);
              item.valueObj.id = cloneValue.id;
              item.valueObj.code = cloneValue.code;
            }
          }
        }
      });
    }
    const cloneTypeAddress = entityPropertyValues.filter((item) => item.type === EntityPropertyType.Address);
    if (!cloneTypeAddress.length) {
      this.dialogRef.close({
        entityPropertyValues,
        entityReal: this.entityReal,
      });
      return;
    }
    let body: any = [];
    cloneTypeAddress.forEach((item) => {
      const value = item.value ? JSON.parse(item.value) : null;
      if (value) {
        const data = {
          reCordId: item.recordId ? item.recordId : null,
          entityId: item.entityId,
          key: item.entityPropertyId,
          entityPropertyId: item.entityPropertyId,
          effectFrom: value.EffectFrom,
          effectTo: value.EffectTo || null,
          id: value?.id ? value.id : null,
        };
        body.push(data);
      }
    });
    this.memberStore.dispatch(AddressHistoryAction.checkExitAddress({ body }));
    let subscription = this.memberStore
      .pipe(select(fromMember.selectAddressHistoryExitState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.messages) {
          if (state?.messages.length) {
            state?.messages?.forEach((item: any) => {
              const checkAddressError = this.controls.find((value) => value.data.entityPropertyId === item.key);
              if (checkAddressError) {
                const form = checkAddressError.lstChildFormControl?.find((f) => f.key === 'effectFrom')!;
                form.messageError = item.message;
                form?.formControl?.setErrors({ inValidAsync: true });
              }
            });
          } else {
            this.dialogRef.close({
              entityPropertyValues,
              entityReal: this.entityReal,
            });
          }
          this.memberStore.dispatch(clearSetMemberEventStateAction());
          subscription.unsubscribe();
        }
      });
  }

  getMarkValueEmail(email: string) {
    return (
      email.substring(0, 1) +
      email.substring(1, email.length - 1).replace(/[^@]/gi, 'X') +
      email.substring(email.length - 1, email.length)
    );
  }

  getMarkValueSSN(ssn: string) {
    let match: any;
    match = ssn.match(/^(\d{3})(\d{2})(\d{4})$/);
    return match[1].replace(/[0-9]/g, 'X') + '-' + match[2].replace(/[0-9]/g, 'X') + '-' + match[3];
  }

  getMarkValuePhone(phone: string) {
    let match: any;
    match = phone.match(/^(\d{1})(\d{2})(\d{3})(\d{2})(\d{2})$/);
    return (
      '(' +
      match[1].replace(/[0-9]/g, 'X') +
      match[2] +
      ') ' +
      match[3].replace(/[0-9]/g, 'X') +
      '-' +
      match[4].replace(/[0-9]/g, 'X') +
      match[5]
    );
  }

  // onCancel(): void {
  //   this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  // }

  private _effectiveDate(fromElement: any, toElement: any) {
    return (c: AbstractControl) => {
      return fromElement?.formControl?.value &&
        toElement?.formControl?.value &&
        fromElement?.formControl?.value >= toElement?.formControl?.value
        ? { fromToValidation: 'System does not allow Effective From is equal OR greater than Effective To.' }
        : null;
    };
  }

  checkTextbox(data?: FieldData[]) {
    const checkCountry = data?.find((item: FieldData) => item.key === 'country');
    const checkCode = checkCountry?.lstOption?.find((item: any) => item.value === checkCountry?.formControl?.value)!;
    return checkCode && checkCode?.code === 'USA' ? true : false;
  }

  changeValueAddress(field: FieldData, fieldDatas?: FieldData[], data?: FormControlDetail) {
    if (field.key === 'country') {
      this.checkTextbox(fieldDatas);
    }
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  changeValueInputAddress(data: FormControlDetail) {
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  validateEffectForm(data?: FormControlDetail) {
    if (data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    const effectFrom = data?.lstChildFormControl?.find((item: FieldData) => item?.key === 'effectFrom');
    if (effectFrom) {
      effectFrom.isSetRequired = checkValueForm ? true : false;
    }
  }

  validateAddressField(data?: FormControlDetail) {
    if (data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    data?.lstChildFormControl?.forEach((item: FieldData) => {
      if (!['effectTo', 'street2'].includes(item.key)) {
        item.isSetRequired = checkValueForm ? true : false;
        if (checkValueForm) {
          item?.formControl?.addValidators(Validators.required);
        } else {
          item?.formControl?.removeValidators(Validators.required);
        }
        item?.formControl?.updateValueAndValidity();
      }
    });
  }

  dateEffectiveChange(event: any, lstChildFormControl?: FieldData[]) {
    lstChildFormControl?.find((el) => el.key === 'effectFrom')?.formControl?.updateValueAndValidity();
    lstChildFormControl?.find((el) => el.key === 'effectTo')?.formControl?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.entityStore.dispatch(clearSearchEntityDatasStateAction());
  }
}
