import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance/value.const';

import {
  StatusHistoryList,
  GetListStatusHistoryQuery,
  MemberStatusList,
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
  GetAuditTrailsRequest,
  GetAuditTrailsResponse,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class StatusHistoryService {
  constructor(private httpClient: HttpClient) {}

  getStatusHistoryList(
    query: GetListStatusHistoryQuery
  ): Observable<StatusHistoryList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<StatusHistoryList>(
      `${environment.apiUrl}/member-portal/GetMemberStatusHistory`,
      { params }
    );
  }

  getMemberStatusList(): Observable<MemberStatusList> {
    return this.httpClient.get<MemberStatusList>(
      `${environment.apiUrl}/member-portal/GetMemberStatusEvent`
    );
  }

  createMemberStatus(body: CreateMemberStatusRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/CreateMemberStatusHistory`,
      body
    );
  }

  editMemberStatus(body: EditMemberStatusRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/SetMemberStatusHistory`,
      body
    );
  }

  getAuditTrails(
    request: GetAuditTrailsRequest
  ): Observable<GetAuditTrailsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType || SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<GetAuditTrailsResponse>(
      `${environment.apiUrl}/Members/${request.memberId}/GetAuditTrails/${request.statusHistoryId}`,
      { params }
    );
  }
}
