import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileType, ListFileType } from '../../constants';
import { DocumentFilter } from '../../services/models/documents.model';

@Component({
  selector: 'ptg-search-filter-documents',
  templateUrl: './search-filter-documents.component.html',
  styleUrls: ['./search-filter-documents.component.scss']
})
export class SearchFilterDocumentsComponent extends BaseComponent implements OnInit {
  FileType = FileType;
  formSubmit$ = new Subject<boolean>();
  filterForm!: FormGroup;
  fileTypeCtrl: FormControl = new FormControl(null);
  filteredByTypingTypeOptions!: Observable<Option[]>;
  currentTypesChipValue!: Option;
  currentTypesChipValueList: string[] = [];
  currentTypesChipValueDisplayList: string[] = [];
  showOnOverviewOption: Option[] = [
    {
      displayValue: 'Yes',
      value: true
    },
    {
      displayValue: 'No',
      value: false
    },
  ]
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'New Filter'
    }
  ];
  maxBeginDate: Date = new Date();
  minToDate: Date = new Date(0);
  maxDate: Date = new Date();
  minDate: Date = new Date();

  getNotUsedSources(value: any[]) {
    return value.map((x) => ({
      displayValue: x.displayValue,
      value: x.value
    })).sort((a, b) => a?.displayValue?.localeCompare(b?.displayValue));;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { currentFilter: DocumentFilter },
    public dialogRef: MatDialogRef<SearchFilterDocumentsComponent>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data.currentFilter) {
      this.listBreadcrumbs[0].name = 'Edit Filter';
    }
    this.initFormGroup();
  }

  initFormGroup() {
    this.filterForm = this.fb.group({
      documentName: this.fb.control(this.data?.currentFilter?.documentName || ''),
      fileName: this.fb.control(this.data?.currentFilter?.fileName || ''),
      uploadedFrom: this.fb.control(this.data?.currentFilter?.uploadedFrom ?? ''),
      uploadedTo: this.fb.control(this.data?.currentFilter?.uploadedTo ?? ''),
      types: this.fb.control(''),
      typesChipList: this.fb.array(this.data?.currentFilter?.typesChipList || []),
      showOnOverview: this.fb.control(this.data?.currentFilter?.showOnOverview ?? null),
    });
    if (this.data?.currentFilter?.types) {
      this.currentTypesChipValueList.push(...this.data?.currentFilter?.types);
      this.currentTypesChipValueDisplayList.push(...this.data?.currentFilter?.typesDisplay);
    }
  }

  get uploadedFrom(): FormControl {
    return this.filterForm.get('uploadedFrom') as FormControl;
  }

  get uploadedTo(): FormControl {
    return this.filterForm.get('uploadedTo') as FormControl;
  }

  get typesChipListControl(): FormArray {
    return this.filterForm?.get('typesChipList') as FormArray;
  }

  get typesAutoCompleteControl(): FormControl {
    return this.filterForm?.get('types') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.filterForm?.get('tagsChipList') as FormArray;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.filterForm?.get('tags') as FormControl;
  }

  get showOnOverviewCtrl(): FormControl {
    return this.filterForm?.get('showOnOverview') as FormControl;
  }

  typesFocus(event: any) {
    event.stopPropagation();
    this.currentTypesChipValue = deepClone(this.typesAutoCompleteControl?.value);
    this.filteredByTypingTypeOptions = this.typesAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        ListFileType.
          filter(item =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTypesChipValueList?.includes(item?.value)
          )));
  }

  displayTypeFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  validateTypes(): void {
    if (this.typesAutoCompleteControl.value) {
      if (
        this.typesAutoCompleteControl.enabled &&
        typeof this.typesAutoCompleteControl.value === 'string' ||
        this.currentTypesChipValueList?.includes(this.typesAutoCompleteControl.value.value)
      ) {
        this.typesAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  onAddNewTypeChip() {
    if (!this.typesAutoCompleteControl.value ||
      this.typesAutoCompleteControl.invalid) {
      this.typesAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTypesChipValue;
    this.typesAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.typesChipListControl.push(chipControl);

    if (!this.currentTypesChipValueList.includes(chip.value)) {
      this.currentTypesChipValueList.push(chip.value);
      this.currentTypesChipValueDisplayList.push(chip.displayValue);
    }
  }

  onRemoveTypeChip(value: any, index: number) {
    if (!value) return;
    this.currentTypesChipValueList.splice(index, 1);
    this.typesChipListControl.removeAt(index);
    this.currentTypesChipValueDisplayList = this.currentTypesChipValueDisplayList.filter(item => item !== value?.displayValue);
  }




  onChangeUploadedFrom(event: any) {
    this.uploadedTo.markAllAsTouched();
    if (this.uploadedTo.errors?.inValidAsync) {
      this.uploadedTo.setErrors(null);
    }
    let beginDate = new Date(event);
    let minToDateTemp = new Date(event);
    minToDateTemp.setDate(minToDateTemp.getDate() + 1);
    this.minToDate = minToDateTemp;
    let endDate = new Date(
      this.uploadedTo.value ? this.uploadedTo.value : DateTime.now
    );
    if (beginDate >= endDate) {
      this.uploadedFrom.setErrors({ errorMessageUploadedDate: 'The Uploaded From must be prior to the Uploaded To.' });
    } else {
      this.uploadedFrom.setErrors(null);
    }
  }

  onChangeUploadedTo(event: any) {
    this.uploadedFrom.markAllAsTouched();
    if (this.uploadedTo.errors?.inValidAsync) {
      this.uploadedTo.setErrors(null);
    }
    let beginDate = new Date(this.uploadedFrom.value);
    if (!event) this.uploadedFrom.setErrors(null);
    if (event) {
      let endDate = new Date(event);
      let maxBeginDateTemp = new Date(event);
      maxBeginDateTemp.setDate(maxBeginDateTemp.getDate() - 1);
      this.maxBeginDate = maxBeginDateTemp;
      if (beginDate > endDate) {
        this.uploadedFrom.setErrors({ errorMessageUploadedDate: 'The Uploaded From must be prior to the Uploaded To.' });
      } else {
        this.uploadedFrom.setErrors(null);
      }
    }
  }

  applyFilter() {
    const documentFilter = this.filterForm.value;
    let uploadedFrom = this.filterForm?.get('uploadedFrom')?.value;
    let uploadedTo = this.filterForm?.get('uploadedTo')?.value;
    documentFilter['uploadedFrom'] = uploadedFrom ? new DatePipe('en-US').transform(uploadedFrom, 'YYYY-MM-dd')?.toString() : '';
    documentFilter['uploadedTo'] = uploadedTo ? new DatePipe('en-US').transform(uploadedTo, 'YYYY-MM-dd')?.toString() : '';
    documentFilter['types'] = this.currentTypesChipValueList;
    documentFilter['typesDisplay'] = this.currentTypesChipValueDisplayList;
    if (this.filterForm.invalid) {
      return;
    }
    this.dialogRef.close(documentFilter);
  }

  clearFilter() {
    // reset form
    this.filterForm.reset();
    this.tagsChipListControl.clear();
    this.currentTypesChipValueList = [];
    this.currentTypesChipValueDisplayList = [];
    this.typesChipListControl.clear();
    this.showOnOverviewCtrl.setValue(null);
  }
}
