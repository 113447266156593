<div class="header-metadata">
  <div class="left-group" *ngIf="isShowChangeEmployerDetailBtn">
    <div class="option">
      <button mat-button (click)="changeEmployerDetail()" class="option-icon" [disabled]="navigationDisabled.previous">
        <mat-icon style="color: #707070">expand_less</mat-icon>
      </button>
      <button mat-button (click)="changeEmployerDetail(true)" class="option-icon" [disabled]="navigationDisabled.next">
        <mat-icon style="color: #707070">expand_more</mat-icon>
      </button>
    </div>
  </div>
  <div class="main-group">
    <ng-container>
      <div class="left-main-group">
        <div class="content">
          <div class="name">
            <span class="label">{{ employerInformationHeader?.label }}</span>
            <span class="value">{{ employerInformationHeader?.value }}</span>
          </div>
        </div>
      </div>
      <div class="right-main-group" *ngIf="isShowActionList">
        <div class="header-button">
          <ng-container>
            <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
              <mat-icon class="actions-icon">more_vert</mat-icon>
              <span class="menu-text">Actions</span>
              <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="member-action-menu">
              <ng-container>
                <button mat-menu-item>
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Add New Note
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item (click)="onUploadDocument()">
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Upload Document
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item (click)="addNewContact()">
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Add New Contact
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item (click)="addNewPortalUser()">
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Add New Portal User
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item>
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Participant Filter
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item>
                  <mat-icon style="color: #629dba">add</mat-icon>
                  Add New Participant
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="right-group" *ngIf="isShowCloseButton">
    <button (click)="close()" mat-button class="btn-close">
      <mat-icon class="icon-close">close</mat-icon>
      <span class="label">Close</span>
    </button>
  </div>
</div>
