import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE, SortType } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentTemplateState } from '../../../template/store/reducers';
import { getListTemplateAction, getListTemplateTagsAction } from '../../store/actions';
import { getListTemplateSelector } from '../../store/selectors';
import { EntityType } from '../../types/enums/entity-type.enum';

@Component({
  selector: 'ptg-generate-document',
  templateUrl: './generate-document.component.html',
  styleUrls: ['./generate-document.component.scss'],
})
export class GenerateDocumentComponent extends BaseComponent implements OnInit {
  title: string = 'Generate Document';
  readonly EntityType = EntityType;
  entityType!: EntityType;
  listBreadcrumbs: Breadcrumb[] = [];
  targetId?: string;
  generateDocForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isShowError: boolean = false;
  listTemplate: any[] = [];
  pageNumber: number = FIRST_PAGE;
  isLoading: boolean = true;
  pageSize: number = 50;
  listType = [
    { displayValue: 'PDF', value: 'pdf' },
    { displayValue: 'Word', value: 'docx' },
  ];

  pensionConfirmationLetter: string = 'pension confirmation letter';

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateDocumentComponent>,
    private documentStore: Store<DocumentState>,
    private documentTemplateStore: Store<DocumentTemplateState>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isPendingPayments?: boolean;
      currentEntity: any;
    },
  ) {
    super();
  }

  get templateCtrl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }

  ngOnInit(): void {
    this.initFormGroup(null);
    this.getDataTemplateList();
    this.registerGetListDocumentTemplateSelector();
    this.submitForm();
  }

  initFormGroup(formData: any | null) {
    const { currentEntity } = this.data;
    this.entityType = currentEntity?.entityType;
    this.targetId = currentEntity.entityId;
    this.listBreadcrumbs = this.getBreadcrumbs(currentEntity.entityType, currentEntity.entityId);
    this.generateDocForm = this.fb.group({
      template: this.fb.control(formData?.templateId || [], [Validators.required]) ?? '',
      fileType: this.fb.control([], [Validators.required]) ?? '',
      isShowPreview: this.fb.control(false),
      tags: this.fb.control(''),
      tagsChipList: this.fb.array([]),
    });
  }

  getBreadcrumbs(entityType: EntityType, entityId: string): Breadcrumb[] {
    const url =
      entityType == EntityType.Employer
        ? `/employer/individual-document/${entityId}`
        : `/member/individual-document/${entityId}`;
    return [
      {
        name: 'Document List',
        url,
      },
      { name: 'Generate Document' },
    ];
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.generateDocForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.generateDocForm.statusChanges.pipe(
            startWith(this.generateDocForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    const templateName = this.listTemplate.find((temp) => temp.value === this.generateDocForm.get('template')?.value)
      ?.displayValue;
    const fileTypeName = this.listType.find((item: any) => item.value === this.generateDocForm.get('fileType')?.value)
      ?.displayValue;
    const objGenerate = this.generateDocForm.value;
    objGenerate['templateName'] = templateName;
    objGenerate['fileTypeName'] = fileTypeName;
    if (this.data?.isPendingPayments) {
      objGenerate['template'] = this.templateCtrl?.value;
    }
    this.dialogRef.close(objGenerate);
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }

  getDataTemplateList() {
    this.documentStore.dispatch(
      getListTemplateAction({
        request: {
          sortType: SortType.ASC,
          sortNames: 'TemplateName',
        },
      }),
    );
  }

  registerGetListDocumentTemplateSelector() {
    const templateUsedFor = this.entityType === EntityType.Employer ? 'Employer' : 'Member';
    this.documentStore.pipe(select(getListTemplateSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.listTemplate =
          data?.payload?.filter(
            item => item.usedFor === templateUsedFor)?.map((temp) => {
            return {
              value: temp.id,
              displayValue: temp.templateName,
              fileId: temp.fileId,
            };
          }) ?? [];
        if (this.listTemplate.length === 0) {
          this.templateControl.setErrors({ errMsgTemplate: 'No available templates' });
          this.templateControl.markAsTouched();
        }
        if (this.data?.isPendingPayments) {
          this.listTemplate = this.listTemplate.filter(
            (item) => item.displayValue.toLowerCase() === this.pensionConfirmationLetter,
          );
          const formData = {
            templateId: this.listTemplate[0].value,
          };
          this.initFormGroup(formData);
          this.templateCtrl.disable();
        }
      }
    });
  }

  removeChipAttr(){
    setTimeout(() => {
      let matChipList = document.getElementsByTagName('mat-chip-list');
        if (matChipList.length > 0) {
          (function () {
            for (let i = 0; i < matChipList.length; i++) {
              matChipList[i].removeAttribute('aria-disabled');
              matChipList[i].removeAttribute('aria-invalid');
              matChipList[i].removeAttribute('aria-multiselectable');
              matChipList[i].removeAttribute('aria-orientation');
              matChipList[i].removeAttribute('aria-required');
            }
          })();
        }
      }, 100);
  }


  onSelectTemplate(event: any) {
    this.documentTemplateStore.dispatch(
      getListTemplateTagsAction({
        fileId: event.fileId,
      }),
    );
  }


  displayValue(value: string) {
    return value.length > 20 ? value.substring(0, 20) + '...' : value;
  }

  get templateControl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }

  get fileTypeControl(): FormControl {
    return this.generateDocForm?.get('fileType') as FormControl;
  }

  get isShowPreviewControl(): FormControl {
    return this.generateDocForm?.get('isShowPreview') as FormControl;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.generateDocForm?.get('tags') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.generateDocForm?.get('tagsChipList') as FormArray;
  }
}
