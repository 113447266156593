import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  CalculationState,
  StepConfigurationState,
  clearGetStepConfigurationDetailByBenefitOptionStateAction,
  clearGetValidationExceptionConfigurationStateAction,
  clearInitiateSurvivorStateAction,
  getInitiateSurvivorSelector,
  getValidationExceptionConfigurationAction,
  getValidationExceptionConfigurationSelector,
  initiateSurvivorAction,
} from '@ptg-member/features/calculation/store';
import { CalculationType, ExceptionType } from '@ptg-member/features/calculation/types/enums';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { FormControlDetail } from '@ptg-shared/types/models/form-control-detail';
import { deepClone, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { filter, takeUntil, tap } from 'rxjs/operators';
import {
  CalculationStep,
  CalculationStepProperty,
  GetStepConfigurationResponse,
} from '../../services/models/step-configuration.model';
import { CreateRetirementBenefitRequest } from '../../services/models/retirement-benefit-dialog.model';
import { DateTime } from 'luxon';
import { BenefitDetail, GetValidationExceptionRequest } from '../../services/models/exception-configuration.model';
import { validationData } from '../component.util';
import { SetBenefitDetailProperties } from '../../services/models';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

export interface RecalculateBenefitDialogData {
  memberId: string;
  benefitEntityId: string;
  calculationType: CalculationType;
  calculationBenefitId: string;
  benefitStepConfigInfos: GetStepConfigurationResponse;
}

@Component({
  selector: 'ptg-recalculate-survivor-benefit',
  templateUrl: './recalculate-survivor-benefit-dialog.component.html',
  styleUrls: ['./recalculate-survivor-benefit-dialog.component.scss'],
})
export class RecalculateSurvivorBenefitDialogComponent extends BaseComponent {
  formData: FormGroup = this.initForm;
  dialogTitle: string = this.title;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: this.dialogTitle,
    },
  ];
  isLoading: boolean = true;
  propertyValueFromPriorStepConfigs: FormControlDetail[] = [];
  private calculationStepProperties: CalculationStepProperty[] = [];
  private calculationSteps: CalculationStep[] = [];
  private validationExceptionInfo: BenefitDetail[] = [];

  get initForm() {
    return this.fb.group({
      propertyValueFromPriorSteps: this.fb.array([]),
    });
  }

  get title() {
    return this.data.calculationType === CalculationType.Survivor
      ? 'Recalculate Survivor Calculation'
      : 'Recalculate Joint Survivor Calculation';
  }

  get propertyValueFromPriorStepsFormArray(): FormArray {
    return this.formData.get('propertyValueFromPriorSteps') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: RecalculateBenefitDialogData,
    public readonly dialogRef: MatDialogRef<RecalculateSurvivorBenefitDialogComponent>,
    public readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    public readonly calculationStore: Store<CalculationState>,
    public readonly stepConfigurationsStore: Store<StepConfigurationState>,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.clearDataState();

    this.selectInitiateSurvivorState();

    this.getValidationExceptionConfigurationData();
    this.selectValidationExceptionConfigurationState();

    this.initStepConfigurationByRecalculateDetection();
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef, CANCEL_CONFIRM_MESSAGE, () => this.clearDataState());
  }

  onSubmit(): void {
    this.formData.markAllAsTouched();
    if (this.formData.invalid) {
      return;
    }
    this.onSave();
  }

  private initStepConfigurationByRecalculateDetection(): void {
    this.calculationSteps = deepClone(this.data.benefitStepConfigInfos?.calculationSteps ?? []).sort(
      (a, b) => b.order - a.order,
    );

    const firstStep = this.getStepConfiguration(1);
    if (firstStep) {
      this.propertyValueFromPriorStepsFormArray.clear();
      this.calculationStepProperties = firstStep?.calculationStepProperties ?? [];
      this.propertyValueFromPriorStepMapping();
    }
  }

  private getStepConfiguration(order: number): CalculationStep | undefined {
    return this.calculationSteps.find((item) => item.order === order);
  }

  private propertyValueFromPriorStepMapping(): void {
    this.propertyValueFromPriorStepConfigs = deepClone(this.calculationStepProperties).map((property) => {
      return {
        ...property,
        type: (InputType as any)[EntityPropertyType[property.dataType] as any],
        label: property.inputLabel,
        config: { ...(property?.inputConfigs ?? (property as any)?.config) },
        options: property.inputOptions ?? property.options,
      } as any;
    });
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.propertyValueFromPriorStepConfigs.forEach((property: any) => {
      this.propertyValueFromPriorStepsFormArray.push(
        this.fb.group({
          calculationParamMappingId: property.calculationParamMappingId,
          calculationStepId: property.calculationStepId,
          id: property.id,
          options: property.inputOptions ?? property.options,
          order: property.order,
          orderColumn: property.orderColumn,
          propertyLabel: property.inputLabel,
          value: this.fb.control(this.initFieldsValue(property), [this.customValidation(property)]),
          type: property.dataType ?? property.type,
          benefitDetailKey: property.benefitDetailKey ?? property.id,
          inputConfigs: property.inputConfigs ?? property.config,
        }),
      );
    });
  }

  private initFieldsValue(property: any): any {
    if (isEmpty(property.value) || property.value === '') {
      return null;
    }
    if (property.type === InputType.Binary) {
      return stringToBoolean(property.value);
    }
    return property.value;
  }

  onSave(): void {
    const request: CreateRetirementBenefitRequest = {
      benefitEntityId: this.data.benefitEntityId,
      calculationType: this.data.calculationType,
      propertyValueFromPriorSteps: this.formData.getRawValue().propertyValueFromPriorSteps.map((item: any) => {
        item.value = this.getPropertyValue(item.value, this.getPropertyType(item.type));
        return item;
      }),
    };

    this.calculationStore.dispatch(
      initiateSurvivorAction({
        id: this.data.memberId,
        body: request,
      }),
    );
  }

  private selectInitiateSurvivorState(): void {
    this.calculationStore
      .select(getInitiateSurvivorSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearInitiateSurvivorStateAction());

        if (!response?.success) {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: `Error occurred initiating Survivor Benefit. Please try again.`,
          });
          return;
        }

        this.dialogRef.close();
        this.router.navigateByUrl(
          `member/benefit-overview/${this.data.calculationType}/${this.data.memberId}/detail/${
            response.payload?.retirementBenefitId ?? ''
          }`,
        );
      });
  }

  private getPropertyType(type: InputType | EntityPropertyType): EntityPropertyType {
    if (typeof type === 'number') {
      return type;
    }
    return (EntityPropertyType as any)[type];
  }

  private getPropertyValue(value: any, type: EntityPropertyType) {
    if (type === EntityPropertyType.Date) {
      return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    }
    return value;
  }

  private clearDataState(): void {
    this.calculationStore.dispatch(clearInitiateSurvivorStateAction());
    this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
    this.calculationStore.dispatch(clearGetStepConfigurationDetailByBenefitOptionStateAction());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearDataState();
  }

  private customValidation(field: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const properties: SetBenefitDetailProperties[] = this.formData.getRawValue().propertyValueFromPriorSteps;

      const data = this.validationExceptionInfo.filter(
        (item) =>
          item.benefitId === this.data.benefitEntityId &&
          item.additionalInfos.length &&
          item.additionalInfos[0].value === field.calculationParamMappingId,
      );
      return validationData(data, properties);
    };
  }

  private getValidationExceptionConfigurationData(): void {
    const request: GetValidationExceptionRequest = {
      memberId: this.data.memberId,
      exceptionType: ExceptionType.DateValueValidation,
      calculationBenefitId: this.data.calculationBenefitId === '' ? undefined : this.data.calculationBenefitId,
    };
    this.calculationStore.dispatch(getValidationExceptionConfigurationAction({ request }));
  }

  private selectValidationExceptionConfigurationState(): void {
    this.calculationStore
      .pipe(
        select(getValidationExceptionConfigurationSelector),
        tap((response) => (this.isLoading = !!response?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
        if (response?.payload) {
          this.validationExceptionInfo = response?.payload?.benefitDetails ?? [];
        }
      });
  }
}
