<div class="header">
  <span class="title">Payment {{ this.data.bank.index + 1 }}: Direct Deposit</span>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="bank-panel">
    <div class="group-control">
      <ptg-textbox
        [controlField]="formGroup.get('routing')"
        placeholder="ABA Routing #"
        [maxLength]="9"
        inputType="Number"
        [isDecimal]="true"
        [isPositive]="true"
        [allowZero]="true"
        [hasLabel]="true"
        [isRequired]="true"
        errorAsync="Unable to verify bank information."
      ></ptg-textbox>
      <div class="bank-info">
        <label>Bank Name</label>
        <p>
          {{
            formGroup.get('routing')?.invalid
              ? 'N/A'
              : vendorMaintenanceService?.bankInfo
                ? vendorMaintenanceService?.bankInfo?.name
                : data.bank.bankName
                  ? data.bank.bankName
                  : 'N/A'
          }}
        </p>
      </div>
    </div>
    <div class="group-control">
      <ptg-textbox
        [controlField]="formGroup.get('accountNumber')"
        placeholder="Account #"
        [maxLength]="30"
        [hasLabel]="true"
        [isRequired]="true"
        errorAsync="Duplicated bank account number."
      ></ptg-textbox>
      <ptg-radio-button
        [controlField]="formGroup.get('accountType')"
        [listOption]="listAccountType"
        class="account-type"
      ></ptg-radio-button>
    </div>
  </div>
  <div class="wrap-btn">
    <button mat-raised-button type="submit" style="background-color: #2d6c71; color: #fff; width: 97px">Save</button>
    <button mat-raised-button type="button" style="background-color: #444444; color: #fff" (click)="onCancel()">
      Cancel
    </button>
  </div>
</form>
