<div class="municipality-payment-container" id="municipality-payment-page" *ngIf="isViewPaymentScreen">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div *ngIf="!isEntityView; else entityView">
    <ptg-overview-header #overViewHeader (changeMemberDetailEvent)="onChangeMemberDetail()"></ptg-overview-header>
  </div>
  <ng-template #entityView>
    <ptg-overview-header-entity
      [shouldHideStatusDateProperty]="true"
      [shouldHideStatusEventProperty]="true"
    ></ptg-overview-header-entity>
  </ng-template>

  <div *ngIf="bannerType" class="px-6 pt-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <div class="flex flex-col p-6 gap-6">
    <ptg-municipality-group-aggregation [aggregationDetails]="listArregations"> </ptg-municipality-group-aggregation>
    <ng-container *ngIf="!isGenerateReport; else loading">
      <ptg-detail-display-box
        *ngIf="dataTable && dataTable.length"
        title="Selected Payment Record"
        [nonEditItem]="dataTable[currentRowIndex]"
        [selectedItem]="dataTable[currentRowIndex]"
        [propertyDisplayed]="propertyDisplayed"
      >
        <ng-template detailValueContent templateName="metRequirement">
          <div class="flex justify-items-center">
            <ng-container
              [ngTemplateOutlet]="!dataTable[currentRowIndex].isEditingMetRequirement ? viewMode : editMode"
              [ngTemplateOutletContext]="{ detailData: dataTable[currentRowIndex] }"
            >
            </ng-container>

            <ng-template #viewMode let-detailData="detailData">
              <div class="value">
                <span
                  class="mr-2"
                  *ngIf="detailData?.metRequirement || detailData?.metRequirement === false; else blankData"
                >
                  {{ getMetRequirementLabel(metRequirementControl.value) }}
                </span>
                <ptg-button
                  *ngIf="detailData?.isEditMetRequirement && !isMuniPortal && isShowEditMetRequirementBtnByPermission"
                  buttonName=""
                  classInput="edit-button"
                  (clickButton)="detailData.isEditingMetRequirement = true"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </div>
            </ng-template>
            <ng-template #blankData><span class="mr-2">-</span></ng-template>
            <ng-template #editMode>
              <ptg-select
                [controlField]="metRequirementControl"
                [listData]="metRequirementValues"
                [isOptionObj]="true"
              ></ptg-select>
              <div class="flex items-center">
                <ptg-button buttonName="" classInput="yesno-button" (clickButton)="onEditMetRequirement()">
                  <mat-icon>close</mat-icon>
                </ptg-button>
                <ptg-button buttonName="" classInput="yesno-button" (clickButton)="onEditMetRequirement(true)">
                  <mat-icon style="color: #196f57">check</mat-icon>
                </ptg-button>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </ptg-detail-display-box>

      <ptg-list-display-box
        title="Payment History"
        pageName="{{ currentPageName || menuName }}"
        btnGenerate="Generate Service Record"
        [isLoading]="isLoading"
        [dataTable]="dataTable"
        [columns]="columns"
        [sortInfo]="sortInfo"
        [lengthPg]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [currentRowIndex]="currentRowIndex"
        (changePage)="changePage($event)"
        (sortChange)="sortChange($event)"
        (selectRow)="selectRow($event)"
        (generateRecord)="onClickGenerateRecord()"
      ></ptg-list-display-box>
    </ng-container>

    <ng-template #loading>
      <div class="loading flex-grow flex">
        <div class="spinner m-auto">
          <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
          </mat-progress-spinner>
          <p>Loading...</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="!isViewPaymentScreen" class="no-permission">
  <p>You don't have permission to view this screen.</p>
</div>
