<div class="dialog-container" id="edit-vendor-dialog">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>
  <form [formGroup]="filterForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="flex flex-col p-6 gap-6">
        <div class="filter-container">
          <div class="button-container flex">
            <ptg-button
              buttonName="Apply"
              classInput="primary"
              (clickButton)="applyFilter()"
            ></ptg-button>
            <ptg-button
              buttonName="Clear Filter"
              classInput="tertiary"
              (clickButton)="clearFilter()"
            ></ptg-button>
          </div>
          <div class="flex">
            <ptg-textbox
              [controlField]="filterForm?.get('documentName')"
              placeholder="Document Name"
              [hasLabel]="true"
              [width]="'310px'"
              [maxLength]="250"
            ></ptg-textbox>
            <ptg-textbox
              [controlField]="filterForm?.get('fileName')"
              placeholder="File Name"
              [hasLabel]="true"
              [width]="'310px'"
              [maxLength]="250"
            ></ptg-textbox>
          </div>
          <div class="flex">
            <ptg-datepicker
              [controlField]="uploadedFrom"
              placeholder="Uploaded From"
              class="input-upload-date"
              customError="errorMessageUploadedDate"
              (changeValue)="onChangeUploadedFrom($event)"
            ></ptg-datepicker>
            <ptg-datepicker
              [controlField]="uploadedTo"
              placeholder="Uploaded To"
              class="input-upload-date"
              (changeValue)="onChangeUploadedTo($event)"
            ></ptg-datepicker>
          </div>
          <div class="type">
            <div class="autocomplete-chip-section">
              <div class="auto-complete-section">
                <div class="select-container">
                  <div class="select-tag">
                    <mat-form-field class="auto-complete" appearance="fill">
                      <mat-label>File Extension</mat-label>
                      <input class="custom-input" type="text" matInput [formControl]="typesAutoCompleteControl" [matAutocomplete]="auto" (blur)="validateTypes()" (focus)="typesFocus($event)">
                      <span class="custom-arrow mat-select-arrow-wrapper">
                        <span class="mat-select-arrow" [ngClass]="{ 'has-error':
                          typesAutoCompleteControl.errors !== null &&
                          typesAutoCompleteControl?.touched }">
                        </span>
                      </span>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTypeFn">
                        <mat-option *ngFor="let option of filteredByTypingTypeOptions | async" [value]="option">
                          {{ option.displayValue }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="!typesAutoCompleteControl?.errors?.required && typesAutoCompleteControl?.errors?.inValidAsync">
                        Value must be selected from result list.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="chip-container">
                    <span class="add-chip-btn-section">
                      <button
                        mat-icon-button
                        (click)="onAddNewTypeChip()"
                        [disabled]="!typesAutoCompleteControl.value || typesAutoCompleteControl?.errors?.inValidAsync"
                        aria-label="add file extension"
                      >
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </span>
                    <div class="tags-chip-list-section">
                      <mat-chip-list *ngIf="typesChipListControl?.length">
                        <mat-chip *ngFor="let item of typesChipListControl.controls; index as i" (removed)="onRemoveTypeChip(item.value, i)">
                          <span class="ml-2 pl-2">
                            <span class="chip__txt--primary">
                              <span class="mr-1">{{ item.value.displayValue }}</span>
                            </span>
                          </span>
                          <button matChipRemove>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="source">
            <div class="type-select flex">
              <ptg-select
                [placeholder]="'Show on Overview'"
                [controlField]="showOnOverviewCtrl"
                [listData]="showOnOverviewOption"
                [isOptionObj]="true"
                [width]="'637px'"
              ></ptg-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
