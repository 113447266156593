import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CalculationQDROErrorType } from '../types/enums';

import {
  GetAlternatePayeesRequest,
  GetAlternatePayeesResponse,
  GetQDROBenefitInformationRequest,
  GetQDROBenefitInformationResponse,
  GetQDROLabelNameResponse,
  GetQDROValidationBeforeInitializationRequest,
  GetQDROValidationBeforeInitializationResponse,
} from './models';

@Injectable()
export class CalculationQDROService {
  constructor(private httpClient: HttpClient) {}

  getCalculationQDROLabelName(): Observable<GetQDROLabelNameResponse> {
    return this.httpClient.get<GetQDROLabelNameResponse>(
      `${environment.apiUrl}/calculation-portal/benefit-processing/name-of-qdro`,
    );
  }

  getAlternatePayees(request: GetAlternatePayeesRequest): Observable<GetAlternatePayeesResponse> {
    const { memberId } = request;
    return this.httpClient.get<GetAlternatePayeesResponse>(`${environment.apiUrl}/calculations/${memberId}/qdro/payee`);
  }

  getQDROValidationBeforeInitialization(
    request: GetQDROValidationBeforeInitializationRequest,
  ): Observable<GetQDROValidationBeforeInitializationResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { memberId, calculationType, benefitEntityId } = request;
    return this.httpClient.get<GetQDROValidationBeforeInitializationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/${calculationType}/entity/${benefitEntityId}`,
      { context }
    );
  }

  getGetQDROBenefitInformation(
    request: GetQDROBenefitInformationRequest,
  ): Observable<GetQDROBenefitInformationResponse> {
    const { memberId, calculationBenefitId } = request;
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', capitalizeFirstLetter(request.sortNames));
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetQDROBenefitInformationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/${calculationBenefitId}/qdro`,
      { params },
    );
  }
}

export const errorMessageValidationQDRO = (errorType: CalculationQDROErrorType) => {
  switch (errorType) {
    case CalculationQDROErrorType.CalculationFileIsMissing:
      return 'Please set up QDRO Benefit before initiating.';
    case CalculationQDROErrorType.StepConfigurationIsMissing:
      return 'Step Configuration needs to be set up before initiating Benefit Calculation.';
    case CalculationQDROErrorType.BenefitEntityMissing:
      return 'Benefit Option must be associated with a Calculation File before initiating Benefit.';
    case CalculationQDROErrorType.PendingBenefitCalculation:
      return 'System cannot proceed since there is already a pending Benefit Calculation.';
    case CalculationQDROErrorType.NoCourtOrderAvailable:
      return 'No Court Order available to initiate Benefit Calculation.';
    case CalculationQDROErrorType.NonExistingRetirementBenefitCalculation:
      return 'Please initiate and complete Retirement Benefit of current Member before proceeding.';
    default:
      return '';
  }
};
