<mat-form-field
  *ngIf="controlField"
  appearance="fill"
  class="select-custom {{ class }}"
  [ngClass]="{ 'has-icon': selected?.iconConfig }"
  [style.width]="width"
>

<mat-label>{{ placeholder }}</mat-label>
  <mat-select
    #select
    [formControl]="controlField"
    [compareWith]="compareWithFunction"
    [panelClass]="[isSetMaxWidthSelect ? 'max-length-option' : 'common-option', panelClass]"
    (focus)="onFocusValue()"
    (focusout)="onFocusOutValue()"
    [disabled]="isDisabledSelect!!"
    [multiple]="isMultiple"
  >
    <!-- id="mat-select-{{ placeholder }}" -->
    <mat-select-trigger *ngIf="isOptionObj">
      <!-- id="dropdown-{{ placeholder }}" -->
      <ng-container
        [ngTemplateOutlet]="optionContainer"
        [ngTemplateOutletContext]="{
          option: selected,
          isShowDescription: false
        }"
      ></ng-container>
    </mat-select-trigger>
    <ng-container *ngIf="!isOptionObj; else optionObj">
      <mat-option *ngIf="hasNoneValue" value="" class="blank">{{ noneValue }}</mat-option>
      <mat-option *ngFor="let option of listData" [value]="option" (click)="isCheckChange ? changeOption(option) : {}">
        {{ option }}
      </mat-option>
    </ng-container>
    <ng-template #optionObj>
      <mat-option *ngIf="hasNoneValue" value="" class="blank" (click)="changeOption()">{{ noneValue }}</mat-option>
      <mat-option
        *ngFor="let option of listData"
        [value]="option.value"
        (click)="changeOption(option)"
        [ngClass]="{
          'select-option-container': option?.iconConfig,
          'multiple-select-option-container': isMultipleLineOption
        }"
        [ngStyle]="{ display: option.isHide ? 'none' : 'block' }"
      >
        <ng-container
          [ngTemplateOutlet]="optionContainer"
          [ngTemplateOutletContext]="{
            option: option,
            isShowDescription: true
          }"
        >
        </ng-container>
      </mat-option>
    </ng-template>

    <ng-template let-option="option" let-isShowDescription="isShowDescription" #optionContainer>
      <ng-container
        *ngIf="option?.iconConfig?.icon && option?.iconConfig?.iconFirst"
        [ngTemplateOutlet]="optionIcon"
        [ngTemplateOutletContext]="{ config: option?.iconConfig }"
      >
      </ng-container>
      <ng-container [ngTemplateOutlet]="optionContent" [ngTemplateOutletContext]="{ option, isShowDescription }">
      </ng-container>
      <ng-container
        *ngIf="option?.iconConfig?.icon && !option?.iconConfig?.iconFirst"
        [ngTemplateOutlet]="optionIcon"
        [ngTemplateOutletContext]="{ config: option?.iconConfig }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-config="config" #optionIcon>
      <ng-container *ngIf="config">
        <mat-icon *ngIf="!config.isSvg; else svgIcon" [style.color]="config.color">{{ config.icon }}</mat-icon>
        <ng-template #svgIcon>
          <mat-icon *ngIf="config.icon && config.iconFirst" [svgIcon]="config.icon" [style.color]="config.color">
          </mat-icon>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template let-option="option" let-isShowDescription="isShowDescription" #optionContent>
      <div class="multiple-line-option-container">
        <div
          class="title"
          [ngClass]="{ 'title-no-bold': onlyTruncateTitle }"
          [ngStyle]="{ color: !option?.displayValue ? '#707070' : '#303030' }"
        >
          <span [style]="option?.displayValueStyle">{{
            (!isShowDescription && isShowValue ? option?.valueShow : option?.displayValue) || noneValue
          }}</span>
          <span *ngIf="option?.hasSeparator"> - </span>
          <span *ngIf="option?.additionalDataConfig" [style]="option?.additionalDataConfig?.style">
            {{ option?.additionalDataConfig?.value }}
          </span>
        </div>
        <div class="description" *ngIf="isMultipleLineOption && isShowDescription && option?.valueDescription">
          {{ option.valueDescription }}
        </div>
      </div>
    </ng-template>
  </mat-select>
  <mat-hint *ngIf="isShowHintText && !controlField?.errors && select.focused" class="helpText">{{ hintText }}</mat-hint>
  <mat-error *ngIf="controlField?.errors?.required" id="mat-error-{{ placeholder }}">{{ errorRequire }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.duplicated" id="mat-error-{{ placeholder }}">{{ errorDuplicated }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.inValidAsync">{{
    errorAsync ? errorAsync : controlField.getError('inValidAsync')
  }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.[customError] && !controlField?.errors?.required">{{
    controlField.getError([customError])
  }}</mat-error>
</mat-form-field>
