<a class="skip-link" href="#main-content">Skip to Main Content</a>
<ng-container *ngIf="isLogin; else isNotLogin">
  <ptg-layout>
    <router-outlet id="main-content" tabindex="-1"></router-outlet>
  </ptg-layout>
</ng-container>

<ng-template #isNotLogin>
  <ng-container *ngIf="isNotDisplayLayout; else loadingCheck">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-template #loadingCheck>
    <div id="loading-check">
      <div class="wrap-item">
        <mat-progress-spinner aria-label="Progress Spinner" role="progressbar" diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </ng-template>
</ng-template>
