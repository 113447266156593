import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { takeUntil } from 'rxjs/operators';
import { EntityType } from '../../types/enums/entity-type.enum';
import { select, Store } from '@ngrx/store';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { generateDocumentSelector } from 'src/app/admin/features/file/store/selectors';

@Component({
  selector: 'ptg-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent extends BaseComponent implements OnInit {
  readonly EntityType = EntityType;
  listBreadcrumbs: Breadcrumb[] = [];
  entityType!: EntityType;
  targetId?: string;
  templateName = '';
  fileTypeName = '';
  documentPreviewUrl = '';
  isLoading = false;

  constructor(
    private readonly fb: FormBuilder,
    private documentStore: Store<DocumentsState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentEntity: any;
      document: any;
      documentForm: any;
      isCustomParentUrl?: boolean;
    },
  ) {
    super();
  }

  getBreadcrumbs(entityType: EntityType, entityId: string): Breadcrumb[] {
    const url =
      entityType == EntityType.Employer
        ? `/employer/individual-document/${entityId}`
        : `/member/individual-document/${entityId}`;
    return [
      {
        name: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationTitle : 'Document List',
        url: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationRouter : url,
      },
      { name: `${this.templateName} Preview` },
    ];
  }

  ngOnInit(): void {
    this.registerGenerateDocument();
    this.onInitForm();
  }

  onInitForm() {
    const { document, currentEntity, documentForm } = this.data;

    this.templateName = document.templateName;
    this.fileTypeName = document.fileTypeName;
    this.entityType = currentEntity.entityType;
    this.listBreadcrumbs = this.getBreadcrumbs(currentEntity.entityType, currentEntity.entityId);
    this.targetId = currentEntity.entityId;
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onSubmit() {
    const objGenerate = { ...this.data.documentForm };
    this.dialogRef.close(objGenerate);
  }

  registerGenerateDocument() {
    this.documentStore.pipe(select(generateDocumentSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data.isLoading;
        if (data.state === STATE.SUCCESS && data.filePreview) {
          this.documentPreviewUrl = data.filePreview;
        }
      }
    });
  }
}
