import { Component, OnInit } from '@angular/core';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ptg-login-error-page',
  templateUrl: './login-error-page.component.html',
  styleUrls: ['./login-error-page.component.scss']
})
export class LoginErrorPageComponent implements OnInit {
  hasRegistered = true;
  constructor(
    private authService: Auth0Service
  ) { 
  }

  ngOnInit(): void {
    this.hasRegistered = !!this.authService.existingOid;
  }
}
