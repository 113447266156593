import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CardDetail,
  CheckExitRequest,
  CheckExitResponse,
  GetCartResponse,
  GetEntityRequest,
  GetEntityResponse,
  ParametersQuery,
  QueryRequest,
  SetCartRequest,
  SetCartResponse,
} from '@ptg-member/types/models/card.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private httpClient: HttpClient) {}

  getCart(query: ParametersQuery): Observable<GetCartResponse> {
    const body: ParametersQuery = {} as ParametersQuery;
    const listSortName = query.sortNames.filter((item: string) => item.length);
    if (listSortName.length) {
      body.sortNames = query.sortNames;
      body.sortType = query.sortType || 0;
    }
    const params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<GetCartResponse>(`${environment.apiUrl}/entity/cards`, { params });
  }

  checkExitsCardName = (body: CheckExitRequest): Observable<CheckExitResponse> => {
    return this.httpClient.put<CheckExitResponse>(`${environment.apiUrl}/entity/cards/exist`, body);
  };

  getEntity(query?: GetEntityRequest): Observable<GetEntityResponse> {
    let params = new HttpParams();
    if (query?.includeVersionHistory) {
      params = params.append('includeVersionHistory', true);
    }
    return this.httpClient.get<GetEntityResponse>(`${environment.apiUrl}/entity/cards/entities`, { params });
  }

  removeCard(query: QueryRequest): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/entity/cards/${query.id}`);
  }
  setCart(body: SetCartRequest): Observable<SetCartResponse> {
    return this.httpClient.post<SetCartResponse>(`${environment.apiUrl}/entity/cards`, body);
  }

  getCardDetail(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/entity-portal/cards/${id}`);
  }

  getEntityProperties(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/entity/entities/${id}/properties/items`);
  }

  getEntityComponent(cardId: string, entityComponentId: string) {
    // return this.httpClient.get(`${environment.apiUrl}/entity/entities/${entityId}/components/${entityComponentId}`);

    const params = new HttpParams({
      fromObject: {
        componentId: entityComponentId,
      } as any,
    });
    return this.httpClient.get(`${environment.apiUrl}/entity/cards/${cardId}/available-property`, { params });
  }

  getEntityProperty(cardId: string, isVersionHistory?: boolean) {
    let params = new HttpParams();
    if (isVersionHistory) {
      params = params.append('isVersionHistory', true);
    }
    return this.httpClient.get(`${environment.apiUrl}/entity/cards/${cardId}/available-property`, { params });
  }

  updateCard(id: string, body: CardDetail) {
    return this.httpClient.put(`${environment.apiUrl}/entity/cards/${id}`, body);
  }
}
