import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MunicipalityServiceHistoryAction from '../../store/actions/municipality-service-history.action';
import { MunicipalityServiceHistoryService } from '../../services/municipality-service-history.service';
import {
  MunicipalityNameList,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
} from '../../types/models';

@Injectable()
export class MunicipalityServiceHistoryEffects {
  getMunicipalityServiceHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryList
      ),
      switchMap(({ query }) => {
        return this.municipalityServiceHistoryService
          .getMunicipalityServiceHistoryList(query)
          .pipe(
            map((serviceRecords: MunicipalityServiceRecordList) => {
              return MunicipalityServiceHistoryAction.municipalityServiceHistoryListSuccess(
                { serviceRecords }
              );
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.municipalityServiceHistoryListFailure()
              );
            })
          );
      })
    )
  );

  getMunicipalityServiceHistoryEntityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryEntityList
      ),
      switchMap(({ query, memberId }) => {
        return this.municipalityServiceHistoryService
          .getMunicipalityServiceHistoryEntityList(query, memberId)
          .pipe(
            map((serviceRecordsEntity: MunicipalityServiceRecordListEntity) => {
              return MunicipalityServiceHistoryAction.municipalityServiceHistoryEntityListSuccess(
                { serviceRecordsEntity }
              );
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.municipalityServiceHistoryEntityListFailure()
              );
            })
          );
      })
    )
  );

  getMunicipalityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.getMunicipalityList),
      switchMap(() => {
        return this.municipalityServiceHistoryService
          .getMunicipalityList()
          .pipe(
            map((municipalitysName: MunicipalityNameList) => {
              return MunicipalityServiceHistoryAction.getMunicipalityListSuccess(
                { listMunicipality: municipalitysName.employers }
              );
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.getMunicipalityListFailure()
              );
            })
          );
      })
    )
  );

  editMemberServiceHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryRequest
      ),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService
          .editMunicipalityServiceHistory(body)
          .pipe(
            map(() => {
              return MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistorySuccess();
            }),
            catchError((err) => {
              let errorMessage = '';
              if (err?.error?.errorType === 'CommandException') {
                errorMessage = err?.error?.errorMessage?.[1] ?? '';
              }
              return of(
                MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure({ errorMessage })
              );
            })
          );
      })
    )
  );

  createMemberServiceHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryRequest
      ),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService
          .editMunicipalityServiceHistory(body)
          .pipe(
            map(() => {
              return MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistorySuccess();
            }),
            catchError((err) => {
              let errorMessage = '';
              if (err?.error?.errorType === 'CommandException') {
                errorMessage = err?.error?.errorMessage?.[1] ?? '';
              }
              return of(
                MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryFailure({ errorMessage })
              );
            })
          );
      })
    )
  );

  validateTerminatedMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.validateTerminatedMemberRequest),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService.validateTerminatedMember(body)
          .pipe(
            map((validateTerminatedMember) => {
              return MunicipalityServiceHistoryAction.validateTerminatedMemberSuccess({ validateTerminatedMember });
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.validateTerminatedMemberFailure(),
              );
            }),
          );
      }),
    ),
  );

  createTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.createTransactionRequest),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService.createTransaction(body)
          .pipe(
            map(() => {
              return MunicipalityServiceHistoryAction.createTransactionSuccess();
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.createTransactionFailure(),
              );
            }),
          );
      }),
    ),
  );

  validateServiceHistoryRecordTerminatedMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberAction),
      switchMap(({ request }) =>
        this.municipalityServiceHistoryService.validateServiceHistoryRecordTerminatedMember(request).pipe(
          map((response) => MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberSuccessAction({ response })),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  createValidateServiceHistoryRecordBeginDateTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionAction),
      switchMap(({ request }) =>
        this.municipalityServiceHistoryService.createValidateServiceHistoryRecordBeginDateTransaction(request).pipe(
          map((response) => MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionSuccessAction({ response })),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private municipalityServiceHistoryService: MunicipalityServiceHistoryService
  ) {}
}
