<div class="list-column-container">
  <h3>Manage Column Set</h3>
  <div class="list-column-config">
    <div class="grid-wrap">
      <ptg-grid
        [data]="listColumnConfig"
        [columns]="columns"
        [paginable]="false"
        [allowDragDrop]="true"
        (rowDrop)="changeItemIndex($event)"
        [hideHeader]="true"
        notFoundMessage="No Property to Display"
      >
        <ng-template cellContent [columnName]="'columnName'" let-row>
          <div class="multiple-line-text">
            <p class="caption">{{ row.columnSetName }}</p>
            <p class="description">{{ row.valueDescription }}</p>
          </div>
        </ng-template>

        <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
          <ng-container *ngIf="row.auditTrail">
            <ptg-button buttonName="" classInput="delete-button" (clickButton)="onUpdateColumSet(row)">
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button buttonName="" classInput="delete-button non-action" (clickButton)="removeColumSet(row)">
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-container>
        </ng-template>
      </ptg-grid>
    </div>

    <div class="btn-create">
      <ptg-button buttonName="Create New Column Set" classInput="edit-button" (clickButton)="onUpdateColumSet()">
        <mat-icon>add</mat-icon>
      </ptg-button>
    </div>
    <div id="loading-table" *ngIf="isLoading">
      <div class="wrap-item">
        <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </div>

  <div class="button-control">
    <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Close</button>
  </div>
</div>
