import { Component, ViewChild } from '@angular/core';
import * as fromReducer from '@ptg-reducers';
import { select, Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  Accident,
  AccidentClaim,
  AccidentDocumentList,
  AccidentLetterRequest,
  AccidentReport,
  AccidentStatus,
  ActionHistory,
  UploadAccidentReportRequest,
} from '@ptg-member/features/accident-claims/services/models';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import { AddAccidentClaimComponent } from '@ptg-member/features/accident-claims/components/add-accident-claim/add-accident-claim.component';
import {
  addAccidentReportSelector,
  createAccidentClaimsSelector,
  createAccidentNoteSelector,
  editAccidentClaimsSelector,
  editAccidentDocumentSelector,
  editAccidentPostCardSelector,
  editAccidentReportSelector,
  editParticipantAccidentStatusSelector,
  getAccidentClaimDetailSelector,
  getAccidentDocumentDownloadSelector,
  getAccidentDocumentsSelector,
  getAccidentPostcardLetterSelector,
  getAccidentReportLetterSelector,
  removeAccidentClaimSelector,
  removeAccidentDocumentSelector,
  uploadAccidentDocumentsSelector,
  viewAccidentDetailsSelector,
} from '@ptg-member/features/accident-claims/store/selectors';
import { deepClone, showBanner, toTimeZoneLocal } from '@ptg-shared/utils/common.util';
import {
  clearAccidentClaimAction,
  clearAccidentPostCardAction,
  clearAccidentReportAction,
  clearCreateAccidentClaimsStateAction,
  clearCreateAccidentNoteStateAction,
  clearEditAccidentClaimsStateAction,
  clearEditAccidentDocumentStateAction,
  clearEditAccidentReportAction,
  clearEditParticipantAccidentStatusAction,
  clearGetAccidentPostcardLetterStateAction,
  clearGetAccidentReportLetterStateAction,
  clearRemoveAccidentDocumentAction,
  clearUploadAccidentDocumentsStateAction,
  editAccidentDocumentAction,
  getAccidentClaimDetailAction,
  getAccidentDocumentsAction,
  getAccidentNotesAction,
  getAccidentPostcardLetterAction,
  getAccidentReportLetterAction,
  removeAccidentClaimAction,
  removeAccidentDocumentAction,
  uploadAccidentDocumentsAction,
  viewAccidentDetailsAction,
} from '@ptg-member/features/accident-claims/store/actions';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AddParticipantAccidentPostCardComponent } from '@ptg-member/features/accident-claims/components/add-participant-accident-post-card/add-participant-accident-post-card.component';
import { ACTION, ADMIN_PORTAL, BUTTON_LABEL_CLOSE, SortType, STATE } from '@ptg-shared/constance';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { AddParticipantAccidentReportComponent } from '@ptg-member/features/accident-claims/components/add-participant-accident-report/add-participant-accident-report.component';
import { DatePipe } from '@angular/common';
import { AddAccidentNoteComponent } from '@ptg-member/features/accident-claims/components/add-accident-note/add-accident-note.component';
import { AccidentNoteDetailComponent } from '@ptg-member/features/accident-claims/components/accident-note-detail/accident-note-detail.component';
import { AccidentParticipantsService } from '@ptg-member/features/accident-claims/services/accident-participants.service';
import {
  AccidentDocumentTypeDisplay,
  AccidentType,
  documentTypeOptionListForBvffAdmin,
  documentTypeOptionListForMunicipalityAdmin,
} from '@ptg-member/features/accident-claims/constants';
import { EditParticipantAccidentStatusComponent } from '@ptg-member/features/accident-claims/components/edit-participant-accident-status/edit-participant-accident-status.component';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { Observable, of, timer } from 'rxjs';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { selectDowloadMemberDocumentState } from 'src/app/admin/features/file/store/selectors/documents.selector';

const PAGE_SIZE_CONST = '-ptg-accident-detail-pageSize';

@Component({
  selector: 'ptg-accident-detail',
  templateUrl: './accident-detail.component.html',
  styleUrls: ['./accident-detail.component.scss'],
})
export class AccidentDetailComponent extends BaseComponent {
  @ViewChild(OverviewHeaderEntityComponent) overViewHeader!: OverviewHeaderEntityComponent;
  @ViewChild(AccidentNoteDetailComponent) accidentNoteDetail!: AccidentNoteDetailComponent;
  readonly AccidentDocumentTypeDisplay = AccidentDocumentTypeDisplay;
  AccidentStatus = AccidentStatus;
  listBreadcrumbs: Breadcrumb[] = [];
  listAccidentClaims: AccidentClaim[] = [];
  listAccidentDocuments: AccidentDocumentList[] = [];
  menuItemTitle: string = MenuItemTitle.Accident;
  menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;
  columnsAccidentDocuments: Column[] = [
    {
      name: 'dateCreated',
      header: {
        title: 'Upload Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy hh:mm a',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      truncate: true,
      sortable: false,
    },
    {
      name: 'documentTypeLabel',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];
  columns: Column[] = [
    {
      name: 'warrantRegisterNumber',
      header: {
        title: 'Warrant Register Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: 'warrantBatchNumber',
      header: {
        title: 'Warrant Batch Number',
      },
      align: Align.Right,
      truncate: true,
      sortable: true,
    },
    {
      name: 'vendorName',
      header: {
        title: 'Vendor Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'vendorNumber',
      header: {
        title: 'Vendor Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: 'disbursementTypeName',
      header: {
        title: 'Disbursement Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'paymentDate',
      header: {
        title: 'Payment Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'paymentNumber',
      header: {
        title: 'Payment Number',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: 'amount',
      header: {
        title: 'Claim Amount',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs: {
        decimal: 2,
        unit: '$',
        unitPosition: 'left',
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];
  isAccidentLoading: boolean = false;
  isLoading: boolean = true;
  isLoadingDocument: boolean = true;
  sortInfoDocument: {} | any = {};
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize!: number;
  pageIndex: number = FIRST_PAGE;
  lengthNotePg: number | any;
  pageNoteSize: number = 2;
  pageNoteIndex: number = FIRST_PAGE;
  lengthPgDocument: number | any;
  pageSizeDocument!: number;
  pageIndexDocument: number = FIRST_PAGE;
  defaultPageSize: number = 50;
  currentFund: any = {};
  memberId: string = '';
  accidentId: string = '';
  showMenuButton: boolean = true;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  listAccidentClaimDetail!: AccidentClaim[];
  accidentReport?: AccidentReport;
  accident?: Accident;
  latestAuditTrail?: ActionHistory;
  accidentTime?: string;
  textLatestAuditTrail?: string;
  accidentStatusText?: string;
  locationOfAccident?: string;
  downloadFileName: string = 'sample.pdf';
  accidentType!: AccidentType;
  listChangeStatus = [
    { displayValue: 'Pending Review', value: AccidentStatus.Pending },
    { displayValue: 'Open', value: AccidentStatus.Open },
    { displayValue: 'Approved', value: AccidentStatus.Approved },
    { displayValue: 'Denied', value: AccidentStatus.Denied },
  ];
  accidentStatus?: number;
  documentTypeOptionList: Option[] = [];
  isEditAccidentDocument: boolean = false;
  documentName: string = '';
  isPortalUser!: boolean;
  isShowEditPostCard!: boolean;
  employerId: string = '';
  isFileDownloading: boolean = false;

  constructor(
    private fundStore: Store<fromReducer.State>,
    private documentStore: Store<DocumentsState>,
    private store: Store<AccidentParticipantsState>,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    public authService: Auth0Service,
    private accidentParticipantsService: AccidentParticipantsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isPortalUser = this.authService.Role === ADMIN_PORTAL;
    this.employerId = this.authService.employerId ?? '';
    this.documentTypeOptionList = this.authService.isAdminPortal$.value ? documentTypeOptionListForBvffAdmin : documentTypeOptionListForMunicipalityAdmin;
    this.getCurrentFund();
    const accidentDocumentPageSizeInSession = Number(
      sessionStorage.getItem(this.currentFund.key + AccidentType.AccidentDocument + PAGE_SIZE_CONST),
    );
    const accidentClaimPageSizeInSession = Number(
      sessionStorage.getItem(this.currentFund.key + AccidentType.AccidentClaim + PAGE_SIZE_CONST),
    );
    this.pageSizeDocument =
      accidentDocumentPageSizeInSession === 0 ? this.defaultPageSize : accidentDocumentPageSizeInSession;
    this.pageSize = accidentClaimPageSizeInSession === 0 ? this.defaultPageSize : accidentClaimPageSizeInSession;
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.accidentId = params.accidentId;
    });
    this.listBreadcrumbs = [
      {
        name: 'Overview',
        url: `member/accident/${this.memberId}`,
      },
      {
        name: 'Accident',
      },
    ];
    this.getAccidentDocument();
    this.getAccidentClaimDetailList();
    this.getData();
    this.registerEditDocumentSelector();
    this.store.pipe(select(getAccidentClaimDetailSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
      }
      this.lengthPg = data?.total;
      this.listAccidentClaimDetail = data?.payload ?? [];
    });

    this.store.pipe(select(getAccidentDocumentsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoadingDocument = data?.isLoading;
      }
      this.lengthPgDocument = data?.total;
      this.listAccidentDocuments =
        data?.payload?.map((item: any) => {
          return {
            ...item,
            dateCreated: this.datePipe.transform(getDateString(item.dateCreated as string), 'MM/dd/yyyy HH:mm'),
          };
        }) ?? [];
    });

    this.store.pipe(select(uploadAccidentDocumentsSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        showBanner.call(this, state.state, 'Accident Document', state.action);
        this.store.dispatch(clearUploadAccidentDocumentsStateAction());
        if (state.state === STATE.SUCCESS) {
          this.getAccidentDocument();
        }
      }
    });

    this.store.pipe(select(createAccidentClaimsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
        } else {
          showBanner.call(this, data?.state?.state, 'Accident Claim', data?.state?.action);
        }
        this.store.dispatch(clearCreateAccidentClaimsStateAction());

        if (data?.state?.state === STATE.SUCCESS) {
          this.getAccidentClaimDetailList();
        }
      }
    });

    this.store.pipe(select(editAccidentClaimsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
        } else {
          showBanner.call(this, data?.state?.state, 'Accident Claim', data?.state?.action);
        }
        this.store.dispatch(clearEditAccidentClaimsStateAction());

        if (data?.state?.state === STATE.SUCCESS) {
          this.getAccidentClaimDetailList();
        }
      }
    });

    this.store.pipe(select(removeAccidentClaimSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        if (state?.accidentClaimState?.state === STATE.FAIL) {
          if (state?.errorMsg) {
            showBanner.call(
              this,
              state?.accidentClaimState?.state,
              'Accident Claim',
              state?.accidentClaimState?.action, {customMessage : state?.errorMsg}
            );

          } else {
            showBanner.call(
              this,
              state?.accidentClaimState?.state,
              'Accident Claim',
              state?.accidentClaimState?.action,
            );
          }
        } else {
          showBanner.call(this, state?.accidentClaimState?.state, 'Accident Claim', state?.accidentClaimState?.action);
        }
        if (state.accidentClaimState.action === ACTION.REMOVE && state.accidentClaimState.state === STATE.SUCCESS) {
          if (this.pageIndex !== 1 && this.listAccidentClaimDetail.length <= 1) {
            this.pageIndex = this.pageIndex - 1;
          }
          let sortType = SortType.DESC;
          let sortNames = 'PaymentNumber';
          if (this.sortInfo?.active && this.sortInfo?.direction) {
            sortNames = capitalizeFirstLetter(this.sortInfo.active);
            sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
          }
          const request = {
            pageNumber: this.pageIndex,
            pageSize: this.pageSize,
            sortNames,
            sortType,
          };
          this.store.dispatch(
            getAccidentClaimDetailAction({
              request: request,
              memberId: this.memberId,
              accidentId: this.accidentId,
            }),
          );
        }
        this.store.dispatch(clearAccidentClaimAction());
      }
    });

    this.store.pipe(select(createAccidentNoteSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        showBanner.call(this, data?.state, 'Note', data?.action);
        this.store.dispatch(clearCreateAccidentNoteStateAction());
        if (data?.state === STATE.SUCCESS) {
          this.accidentNoteDetail.getDataFirstPage();
        }
      }
    });

    this.store.pipe(select(editAccidentReportSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        showBanner.call(this, data?.state, 'Accident Report', data?.action);
        this.store.dispatch(clearEditAccidentReportAction());
        if (data?.state === STATE.SUCCESS) {
          this.getData();
        }
      }
    });

    this.store.pipe(select(editParticipantAccidentStatusSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
          this.store.dispatch(clearEditParticipantAccidentStatusAction());
        } else {
          showBanner.call(this, data?.state?.state, 'Accident Status', data?.state?.action);
          this.store.dispatch(clearEditParticipantAccidentStatusAction());
          if (data?.state?.state === STATE.SUCCESS) {
            this.getAccidentNoteList();
            this.getData();
          }
        }
      }
    });

    this.store
      .pipe(select(removeAccidentDocumentSelector), takeUntil(this.unsubscribe$))
      .subscribe((removeAccidentDocumentState) => {
        if (removeAccidentDocumentState) {
          showBanner.call(
            this,
            removeAccidentDocumentState?.state,
            'Accident Document',
            removeAccidentDocumentState?.action,
          );

          if (
            removeAccidentDocumentState.action === ACTION.REMOVE &&
            removeAccidentDocumentState?.state === STATE.SUCCESS
          ) {
            if (this.pageIndexDocument !== 1 && this.listAccidentDocuments.length <= 1) {
              this.pageIndexDocument = this.pageIndexDocument - 1;
            }
            this.getAccidentDocument();
          }

          this.store.dispatch(clearRemoveAccidentDocumentAction());
        }
      });

    this.store.pipe(select(getAccidentPostcardLetterSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.isLoadingDocument = state?.isLoading;
      }
      if (state?.success !== undefined) {
        if (state?.success) {
          showBanner.call(this, STATE.SUCCESS, '', ACTION.GENERATE, {
            customMessage: 'Generated Post Card Acknowledgement letter successfully!',
          });
        } else {
          showBanner.call(this, STATE.FAIL, 'Post Card Acknowledgement letter', ACTION.GENERATE);
        }
        this.store.dispatch(clearGetAccidentPostcardLetterStateAction());
        if (this.pageIndexDocument !== 1 && this.listAccidentDocuments.length <= 1) {
          this.pageIndexDocument = this.pageIndexDocument - 1;
        }
        this.getAccidentDocument();
      }
    });

    this.store.pipe(select(getAccidentReportLetterSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.isLoadingDocument = state?.isLoading;
      }
      if (state?.success !== undefined) {
        if (state?.success) {
          showBanner.call(this, STATE.SUCCESS, '', ACTION.GENERATE, {
            customMessage: 'Generated Report Acknowledgement letter successfully!',
          });
        } else {
          showBanner.call(this, STATE.FAIL, '', ACTION.GENERATE, {
            customMessage: 'Please fill accident report information to generate report acknowledgement letter!',
          });
        }
        this.store.dispatch(clearGetAccidentReportLetterStateAction());
        if (this.pageIndexDocument !== 1 && this.listAccidentDocuments.length <= 1) {
          this.pageIndexDocument = this.pageIndexDocument - 1;
        }
        this.getAccidentDocument();
      }
    });

    this.getCurrentFund();
    this.checkStatusAddAccidentReport();
    this.checkStatusEditAccidentParticipantPostCard();
    this.selectorDownloadFile();
  }

  getCurrentFund() {
    this.fundStore.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.defaultPageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
    });
  }

  getAccidentClaimDetailList() {
    let sortType = SortType.DESC;
    let sortNames = 'PaymentNumber';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getAccidentClaimDetailAction({
        request: {
          pageNumber: this.pageIndex,
          pageSize: this.pageSize,
          sortNames,
          sortType,
        },
        memberId: this.memberId,
        accidentId: this.accidentId,
      }),
    );
  }

  getAccidentDocument() {
    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfoDocument?.active && this.sortInfoDocument?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfoDocument.active);
      sortType = this.sortInfoDocument.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getAccidentDocumentsAction({
        request: {
          pageNumber: this.pageIndexDocument,
          pageSize: this.pageSizeDocument,
          sortNames,
          sortType,
        },
        memberId: this.memberId,
        accidentId: this.accidentId,
        municipalityId: this.employerId,
      }),
    );
  }

  getAccidentNoteList() {
    this.store.dispatch(
      getAccidentNotesAction({
        query: {
          pageIndex: this.pageNoteIndex,
          pageSize: this.pageNoteSize,
        },
        memberId: this.memberId ?? '',
        accidentId: this.accidentId ?? '',
      }),
    );
  }

  getData() {
    this.store.dispatch(
      viewAccidentDetailsAction({
        memberId: this.memberId,
        accidentId: this.accidentId,
      }),
    );

    this.store.pipe(select(viewAccidentDetailsSelector), tap((state) => (this.isAccidentLoading = !!state?.isLoading)), filter((res) => !!res && !res.isLoading), takeUntil(this.unsubscribe$),).subscribe((data) => {
      const accidentDetail = data?.payload;
      this.accident = accidentDetail?.accident;
      this.accidentReport = accidentDetail?.report;
      if (this.accident) {
        this.accidentStatus = this.accident?.accidentStatus;
        this.accidentStatusText =
          this.accident?.accidentStatus === 0 ? 'Pending Review' : AccidentStatus[this.accident?.accidentStatus];
      }
      if (this.accidentReport) {
        this.accidentTime = this.datePipe
          .transform(
            new Date(1970, 1, 1, this.accidentReport?.timeInHour, this.accidentReport?.timeInMinute, 0),
            'hh:mm a',
          )
          ?.toString();
        this.locationOfAccident =
          this.accidentReport.locationOfAccident === 'Other'
            ? this.accidentReport.otherLocation
            : this.accidentReport.locationOfAccident;
      }
      if (accidentDetail?.latestAuditTrail) {
        this.buildTextLatestAuditTrail(accidentDetail?.latestAuditTrail);
      }
      if (this.isPortalUser) {
        this.isShowEditPostCard = this.accident?.accidentStatus === AccidentStatus.Pending;
      } else {
        this.isShowEditPostCard = true;
      }
    });
  }

  buildTextLatestAuditTrail(actionHistory: ActionHistory) {
    const action = actionHistory.actionType;
    let actionName = 'Created by';
    switch (action) {
      case 0:
        actionName = 'Created by';
        break;
      case 1:
        actionName = 'Updated by';
        break;
      case 2:
        actionName = 'Submitted by';
        break;
      case 3:
        actionName = 'Approved by';
        break;
      case 4:
        actionName = 'Denied by';
        break;
      case 5:
        actionName = 'Pending review by';
        break;
      case 6:
        actionName = 'Reviewed by';
        break;
    }
    const formatActionTime = this.datePipe.transform(
      toTimeZoneLocal(new Date(actionHistory.actionTime)),
      'h:mm a MM/dd/yyyy',
    );
    this.textLatestAuditTrail = `Status: ${actionName} ${actionHistory.actionBy} on ${formatActionTime}`;
  }

  onEditAccidentPostCard() {
    if (this.isPortalUser) {
      if (this.accident?.accidentStatus !== AccidentStatus.Pending) {
        const message = 'The accident post card can only be edited if the accident status is "Pending Review".';
        this.showErrorDialog(message);
        return;
      }
    }

    this.dialog.open(AddParticipantAccidentPostCardComponent, {
      panelClass: ['edit-popup', 'add-participant-accident-post-card-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        accidentPostCard: this.accident,
        memberId: this.memberId,
        isAccidentPostCardEditable: this.checkAccidentPostCardEditable,
        isEdit: true,
        isPortalUser: this.isPortalUser,
        employerId: this.employerId,
      },
    });
  }

  checkAccidentPostCardEditable = () => {
    if (typeof this.accidentStatus === 'number' && [AccidentStatus.Open, AccidentStatus.Pending].includes(this.accidentStatus)) {
      return true;
    }
    showBanner.call(this, STATE.FAIL, '', ACTION.EDIT, { customMessage: 'The accident post card can only be edited if the accident status is "Pending Review".' });
    return false;
  };

  onEditAccidentReport() {
    this.dialog.open(AddParticipantAccidentReportComponent, {
      panelClass: ['edit-popup', 'add-participant-accident-report-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        accidentReport: this.accidentReport,
        memberId: this.memberId,
        accidentId: this.accident?.accidentId,
        employerId: this.employerId,
      },
    });
  }

  onAddAccidentReport() {
    this.dialog.open(AddParticipantAccidentReportComponent, {
      panelClass: ['edit-popup', 'add-participant-accident-report-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        accidentReport: null,
        memberId: this.memberId,
        accidentId: this.accident?.accidentId,
        isPortalUser: this.isPortalUser,
      },
    });
  }

  addAccidentClaim() {
    this.dialog.open(AddAccidentClaimComponent, {
      panelClass: ['edit-popup', 'add-accident-claim-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        accidentClaim: null,
        clientId: this.currentFund.id,
        memberId: this.memberId,
        accidentId: this.accidentId,
      },
    });
  }

  uploadAccidentDocument() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.ACCIDENT,
      documentTypeOptionList: this.documentTypeOptionList,
      acceptFile: '.pdf',
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
      isHiddenAddNewTag: this.isPortalUser,
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;
        const body: UploadAccidentReportRequest = {
          fileName: files[0].name,
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          documentLocationTitle: DOCUMENT_LOCATION.ACCIDENT,
          documentLocationRouter: `/member/accident/${this.accidentId}/${this.memberId}`,
          type: objectUpload.type,
          employerId: this.employerId
        };

        this.accidentParticipantsService.accidentDocumentFile = files[0];

        this.store.dispatch(uploadAccidentDocumentsAction({
          request: body,
          memberId: this.memberId,
          accidentId: this.accidentId,
        }));
      }
    });
  }

  onEditAccidentClaim(row: any) {
    this.dialog.open(AddAccidentClaimComponent, {
      panelClass: ['edit-popup', 'add-accident-claim-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        accidentClaim: row,
        clientId: this.currentFund.id,
        memberId: this.memberId,
        accidentId: this.accidentId,
      },
    });
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + AccidentType.AccidentClaim + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getAccidentClaimDetailList();
  }

  onChangeDocumentPage(event: PageEvent) {
    this.pageSizeDocument = event.pageSize;
    this.pageIndexDocument = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + AccidentType.AccidentDocument + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getAccidentDocument();
  }

  onSortChange(sort: Sort) {
    this.sortInfo = sort;
    this.getAccidentClaimDetailList();
  }

  onSortDocumentChange(sort: Sort) {
    this.sortInfoDocument = sort;
    this.getAccidentDocument();
  }

  onRemoveAccidentClaim(row: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Accident',
        text: 'Are you sure you want to remove this accident claim?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          removeAccidentClaimAction({
            memberId: this.memberId,
            accidentId: row.accidentId,
            accidentClaimId: row.accidentClaimId,
          }),
        );
      }
    });
  }

  checkStatusEditAccidentParticipantPostCard() {
    this.store
      .pipe(select(editAccidentPostCardSelector), takeUntil(this.unsubscribe$))
      .subscribe((accidentPostCardState) => {
        if (accidentPostCardState) {
          if (accidentPostCardState.state === STATE.FAIL && accidentPostCardState?.message?.error?.errorMessage) {
            const customMessage = accidentPostCardState?.message?.error?.errorMessage?.[1];
            showBanner.call(this, accidentPostCardState.state, 'Accident Post Card', accidentPostCardState.action, {
              customMessage,
            });
            this.store.dispatch(clearAccidentPostCardAction());
            return;
          }
          showBanner.call(this, accidentPostCardState.state, 'Accident Post Card', accidentPostCardState.action);
          this.getData();
          this.store.dispatch(clearAccidentPostCardAction());
        }
      });
  }

  checkStatusAddAccidentReport() {
    this.store
      .pipe(select(addAccidentReportSelector), takeUntil(this.unsubscribe$))
      .subscribe((accidentReportState) => {
        if (accidentReportState) {
          showBanner.call(this, accidentReportState.state, 'Accident Report', accidentReportState.action);
          this.getData();
          this.store.dispatch(clearAccidentReportAction());
        }
      });
  }

  downloadDocumentFile(row: any) {
    if (!row) {
      return;
    }

    if (this.isPortalUser) {
      if (!row.canModifyAction) {
        const message = 'This document is uploaded by Fund Admin and cannot be downloaded by Municipality user.';
        this.showErrorDialog(message);
        return;
      }
    }
    this.isFileDownloading = true;
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  selectorDownloadFile() {
    this.store
      .select(selectDowloadMemberDocumentState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (downloadDocument) {
          this.isFileDownloading = downloadDocument?.isLoading;
          this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
        }
      });
  }

  onEditDocument(attachment: any) {
    if (this.isPortalUser) {
      if (!attachment.canModifyAction) {
        const message = 'This document is uploaded by Fund Admin and cannot be edited by Municipality user.';
        this.showErrorDialog(message);
        return;
      }
    }

    this.isEditAccidentDocument = true;
    this.documentName = attachment?.documentName;
    // Open form upload
    let rowData = deepClone(attachment);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.ACCIDENT;
    rowData['documentLocationRouter'] = `/member/accident/${this.accidentId}/${this.memberId}`;

    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: this.documentTypeOptionList,
      isHiddenAddNewTag: this.isPortalUser,
    };
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const request = {
          documentName: objectUpload.documentName,
          tags: objectUpload.tags,
          documentDescription: objectUpload.description,
          showOnOverview: objectUpload.showOnOverview,
          documentType: objectUpload.type,
        };

        this.store.dispatch(editAccidentDocumentAction({
          memberId: this.memberId,
          accidentId: this.accidentId,
          fileId: rowData?.fileId,
          request: request,
        }));
      }
    });
  }

  registerEditDocumentSelector() {
    this.store.pipe(select(editAccidentDocumentSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
        } else if (data?.state?.state === STATE.FAIL && !data?.errorMsg) {
          showBanner.call(this, data?.state?.state, 'Accident Document', data?.state?.action);
        } else {
          showBanner.call(this, data?.state?.state, 'Accident Document', data?.state?.action);
          this.getAccidentDocument();
        }
        this.store.dispatch(clearEditAccidentDocumentStateAction());
      }
    });
  }

  onRemoveDocument(file: any) {
    if (this.isPortalUser) {
      if (!file.canModifyAction) {
        const message = 'This document is uploaded by Fund Admin and cannot be removed by Municipality user.';
        this.showErrorDialog(message);
        return;
      }

      if (!([AccidentStatus.Pending, AccidentStatus.Open].includes(this.accident?.accidentStatus as AccidentStatus))) {
        const message = 'User cannot remove document when the accident status is different from Pending Review or Open.';
        this.showErrorDialog(message);
        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Accident Document',
        text: 'Are you sure you want to remove this document?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          removeAccidentDocumentAction({
            memberId: this.memberId,
            accidentId: this.accidentId,
            accidentDocumentId: file.id,
          }),
        );
      }
    });
  }

  generateAccidentPostcardLetter() {
    this.isLoadingDocument = true;
    const request: AccidentLetterRequest = {
      memberId: this.memberId,
      accidentId: this.accidentId,
      documentLocation: DOCUMENT_LOCATION.ACCIDENT,
      documentLocationRouter: `/member/accident/${this.accidentId}/${this.memberId}`
    }
    this.store.dispatch(getAccidentPostcardLetterAction({ request }));
  }

  generateAccidentReportLetter() {
    this.isLoadingDocument = true;
    const request: AccidentLetterRequest = {
      memberId: this.memberId,
      accidentId: this.accidentId,
      documentLocation: DOCUMENT_LOCATION.ACCIDENT,
      documentLocationRouter: `/member/accident/${this.accidentId}/${this.memberId}`
    }
    this.store.dispatch(getAccidentReportLetterAction({ request }));
  }

  addNote() {
    const dialogRef = this.dialog.open(AddAccidentNoteComponent, {
      panelClass: ['edit-popup', 'add-note-popup'],
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      minWidth: '900px',
      data: {
        title: 'Add Note',
        memberId: this.memberId,
        accidentId: this.accidentId,
      },
    });
  }

  onEditParticipantAccidentStatus() {
    this.dialog.open(EditParticipantAccidentStatusComponent, {
      panelClass: ['edit-popup', 'edit-participant-accident-status-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        memberId: this.memberId,
        accidentId: this.accidentId,
        listChangeStatus: this.listChangeStatus.filter((stt) => stt.value !== this?.accidentStatus),
        canEditStatus: this.accident?.canEditStatus,
      },
    });
  }

  validateExistDocumentNameExist(fileId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.accidentParticipantsService
              .checkExistDocumentName({
                memberId: this.memberId || '',
                documentName: control.value.toString(),
                accidentId: this.accidentId ?? null,
                fileId: fileId,
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  showErrorDialog(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        title: BannerType.Error,
        text: message,
        type: ConfirmType.Warning,
        hideConfirmButton: true,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
      },
    });
  }
}
