<div class="header">
  <span class="title">Split Payments</span>
</div>
<div class="net-payment">
  <span style="margin-left: 8px"> Net Payment </span>
  <span>
    <span class="dollar-sign">$</span><span>{{ data?.netPayment | numberLocalDecimal: '' }}</span>
  </span>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="payment-type">
    <ptg-radio-button
      [controlField]="formGroup.get('splitPaymentType')"
      [listOption]="listType"
      (changeValue)="onChangeSlipPaymentType($event)"
    ></ptg-radio-button>
  </div>
  <div class="payment-panel">
    <table>
      <tr *ngFor="let payments of formPayment.controls; let i = index" class="group-control">
        <td>
          <div class="payment-title">Payment {{ i + 1 }}</div>
        </td>
        <td *ngIf="isPercent">
          <ptg-textbox
            [controlField]="payments.get('paymentPercent')"
            placeholder="Percentage of Net Pay"
            [isDecimal]="true"
            [isRequired]="isPercent"
            [isPositive]="true"
            [allowZero]="true"
            inputType="Currency"
            suffix=" %"
            prefix=""
            [min]="0.01"
            [max]="100"
            [maxLength]="6"
            [isDisabled]="data?.netPayment < 0 || data?.netPayment === 0"
            [hasLabel]="true"
            (onFocus)="onFocusField()"
            errorMinMaxValue="Percentage of Net Pay must be within the range of 0.01 - 100."
            errorAsync="Invalid total Percentage of Net Pay."
          ></ptg-textbox>
        </td>
        <td *ngIf="!isPercent">
          <ptg-textbox
            [controlField]="payments.get('paymentAmount')"
            placeholder="Amount"
            [hasLabel]="true"
            [isRequired]="!isPercent"
            [min]="0.01"
            [max]="9999999999999.99"
            [maxLength]="16"
            [isDisabled]="data?.netPayment < 0 || data?.netPayment === 0"
            inputType="Currency"
            (onFocus)="onFocusField()"
            errorMinMaxValue="Amount must be within the range of 0.01 - 9,999,999,999,999.99."
            errorAsync="Invalid total Amount."
          ></ptg-textbox>
        </td>
        <td>
          <ptg-select
            placeholder="Payment Type"
            [controlField]="payments.get('paymentType')"
            [listData]="listPaymentType"
            [isRequired]="true"
            [isOptionObj]="true"
            (changeOptionValue)="onChangePaymentType(i)"
            errorRequire="Payment Type is required."
          ></ptg-select>
        </td>
        <td style="vertical-align: top">
          <div *ngIf="allowEdits[i]" class="bank-info">
            <label>Account #</label>
            <p *ngIf="payments.get('accountNumber')?.value">
              {{ payments.get('bankName')?.value }} -
              <span style="color: #707070">{{ payments.get('accountNumber')?.value | mask: true }}</span
              >{{
                (payments.get('accountNumber')?.value | mask: false) +
                  (payments.get('accountType')?.value === 0 ? ' - Checking' : ' - Savings')
              }}
            </p>
          </div>
        </td>
        <td>
          <div class="edit-btn">
            <div *ngIf="allowEdits[i]" (click)="onEdit(i)" [ngStyle]="{ 'margin-right': i > 0 ? '32px' : '56px' }">
              <mat-icon>edit</mat-icon>
            </div>
            <div *ngIf="i > 0" (click)="onRemove(i)"><mat-icon>delete_forever</mat-icon></div>
          </div>
        </td>
      </tr>
    </table>
    <div
      *ngIf="
        controlSplitButton() && data?.netPayment > 0 && offCyclePayment?.paymentInfoType !== PaymentInfoTypeEnum.Reissue
      "
      class="add-btn"
    >
      <ptg-button buttonName="Split" (clickButton)="addPayment()" classInput="add-btn">
        <mat-icon>add</mat-icon>
      </ptg-button>
    </div>
  </div>
  <div class="wrap-btn">
    <button mat-raised-button type="submit" style="background-color: #2d6c71; color: #fff; width: 97px">Save</button>
    <button mat-raised-button type="button" style="background-color: #444444; color: #fff" (click)="onCancel()">
      Cancel
    </button>
  </div>
</form>
