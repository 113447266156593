import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import { EmployerActions } from '../../actions';
import { 
  EmployerContact, 
  EmployerSection 
} from '../../models/employer.model';
import * as fromEmployer from '../../reducers';
import { SCREEN_ID } from '@ptg-member/constance/lookupTable.const';
import { UnitedStates } from '@ptg-entity-management/constants';
import { EmployerService } from '@ptg-employer/services/employer.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';


@Component({
  selector: 'ptg-add-employer-contact',
  templateUrl: './add-employer-contact.component.html',
  styleUrls: ['./add-employer-contact.component.scss']
})
export class AddEmployerContactComponent extends BaseComponent implements OnInit {
  readonly UnitedStates = UnitedStates;
  editForm!: FormGroup; 
  listPrefix: { displayValue: string, value: string, id?: string }[] = [];
  listState: { displayValue: string, value: string, id?: string }[] = [];
  listCountry: { displayValue?: string, value: string, id?: string }[] = [];
  formSubmit$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEmployerContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: EmployerContact | null, configSection: EmployerSection, employerId: string },
    private fb: FormBuilder,
    private employerStore: Store<fromEmployer.EmployerState>,
    private readonly employerService: EmployerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();    
    this.employerStore.dispatch(EmployerActions.getLookupTableOptions({ screenId: SCREEN_ID.EmployerOverview }));
    this.employerStore
      .pipe(
        select(fromEmployer.selectLookupTableOptions),
        takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state) {
          this.listPrefix = state.prefixOptions.map(items => this.tranformData(items));
          this.listState = state.stateOptions.map(items => this.tranformData(items, true));
          this.listCountry = state.countryOptions.map(items => this.tranformData(items));
          if (this.data.contact) {
            if (this.data.contact?.name.prefix && !this.listPrefix.find(item => item.value === this.data.contact?.name.prefix)) {
              this.listPrefix.unshift({
                displayValue: this.data.contact?.name.prefix,
                value: this.data.contact?.name.prefix
              });
            }
            if (this.data.contact?.address.stateCode && !this.listState.find(item => item.value === this.data.contact?.address.stateCode)) {
              this.listState.unshift({
                displayValue: `${this.data.contact?.address.stateCode} - ${this.data.contact?.address.state}`,
                value: this.data.contact?.address.stateCode
              });
            }
            if (this.data.contact?.address.countryCode && !this.listCountry.find(item => item.value === this.data.contact?.address.countryCode)) {
              this.listCountry.unshift({
                displayValue: this.data.contact?.address.country,
                value: this.data.contact?.address.countryCode
              });
            }
          }
          
          if (!this.data?.contact?.address?.countryCode) {
            const defaultCountry = this.listCountry.find(item => item.value === this.UnitedStates)?.value;
            this.editForm.get('address')?.get('countryCode')?.setValue(defaultCountry ? defaultCountry : null);
          }
        }
    });

    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(status => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  private _validateEmail() {
    return checkApiValidator(
      this.employerService.validateContact,
      'email',
      this.data?.contact?.email,
      {
        params: {
          employerId: this.data.employerId
        }
      }
    );
  }

  tranformData(obj: any, includesCode?: boolean) {
    return {
      displayValue: includesCode ? `${obj?.code} - ${obj?.description}` : obj?.description,
      value: obj?.code,
      id: obj?.id
    }
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  initFormGroup() {
    const contact = this.data?.contact;
    this.editForm = this.fb.group({
      id: contact?.id || '',
      isPrimary: contact?.isPrimary || false,    
      name: new FormGroup({
        prefix: this.fb.control(contact?.name?.prefix || null),
        first: this.fb.control(contact?.name?.first || '', Validators.required),
        middle: this.fb.control(contact?.name?.middle || ''),
        last: this.fb.control(contact?.name?.last || '', Validators.required),
        suffix: this.fb.control(contact?.name?.suffix || '')
      }),
      phone: this.fb.control(contact?.phone || '', [Validators.required, Validators.maxLength(30)]),      
      email: this.fb.control(contact?.email || '', {validators: [Validators.required, Validators.maxLength(255)], asyncValidators: this._validateEmail()}),
      address: new FormGroup({
        street1: this.fb.control(contact?.address?.street1 || '', Validators.required),
        street2: this.fb.control(contact?.address?.street2 || ''),
        city: this.fb.control(contact?.address?.city || '', Validators.required),
        stateCode: this.fb.control(contact?.address?.stateCode || '', Validators.required),
        zipCode: this.fb.control(contact?.address?.zipCode || '', { validators: [Validators.required] }),
        countryCode: this.fb.control(contact?.address?.countryCode || null, { validators: [Validators.required] }),
      }),
    });
  }

  onSubmit() {
    const employerContact = JSON.parse(JSON.stringify(this.editForm.value as EmployerContact));
    employerContact.name.prefix = employerContact.name.prefix ? this.listPrefix.find(item => item.value === employerContact.name.prefix)?.id || employerContact.name.prefix : null;
    employerContact.address.state = employerContact.address.stateCode ? this.listState.find(item => item.value === employerContact.address.stateCode)?.id || this.data.contact?.address.state : null;
    employerContact.address.country = employerContact.address.countryCode ? this.listCountry.find(item => item.value === employerContact.address.countryCode)?.id || this.data.contact?.address.country : null;
    if (employerContact.id) {
      employerContact.address.stateCode = employerContact.address.stateCode ? this.listState.find(item => item.value === employerContact.address.stateCode)?.value || this.data.contact?.address.stateCode : null;
      employerContact.address.countryCode = employerContact.address.countryCode ? this.listCountry.find(item => item.value === employerContact.address.countryCode)?.value || this.data.contact?.address.countryCode : null;
      this.employerStore.dispatch(EmployerActions.updateEmployerContact({ 
        employerId: this.data.employerId, 
        employerContact: employerContact 
      }));
    } else {
      this.employerStore.dispatch(EmployerActions.addEmployerContact({ 
        employerId: this.data.employerId,
        employerContact: employerContact
      }));
    }
    this.dialogRef.close();
  }
}
