<div class="edit-entity-list-item-config-container" id="edit-entity-list-item-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div [ngClass]="{ banner: bannerType }">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>
  <div *ngIf="!isLoading">
    <ptg-dialog
      class="edit-entity-dialog"
      [footerPosition]="'top'"
      [footerAlign]="'left'"
      [isFullScreen]="true"
      [title]="title"
    >
      <ng-template dialogBody>
        <div class="flex flex-col gap-6">
          <ptg-panel [title]="'List Configuration'" [noPaddingBody]="true">
            <ng-template panelExtraHeader>
              <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onEditListConfiguration()">
                <mat-icon>edit</mat-icon>
              </ptg-button>
            </ng-template>
            <ng-template panelBody>
              <div class="detail-container">
                <div class="detail">
                  <span class="label">List Name</span>
                  <span class="value truncate">{{ entityData?.name || '-' }}</span>
                </div>
                <div class="detail" *ngIf="entityData?.isParticipant">
                  <span class="label">Enable Bulk Update</span>
                  <span class="value">{{ entityData?.importKey ? 'Yes' : 'No' }}</span>
                </div>
                <div class="detail" *ngIf="entityData?.isParticipant && entityData?.importKey">
                  <span class="label">Import Label</span>
                  <span class="value truncate">{{ entityData?.importKey }}</span>
                </div>
                <div class="detail" *ngIf="entityData?.isParticipant">
                  <span class="label">Edit by Creator Only</span>
                  <span class="value truncate">{{ entityData?.editByCreatorOnly ? 'Yes' : 'No' }}</span>
                </div>
                <div class="detail" *ngIf="entityData?.isParticipant">
                  <span class="label">Remove by Creator Only</span>
                  <span class="value truncate">{{ entityData?.removeByCreatorOnly ? 'Yes' : 'No' }}</span>
                </div>
              </div>
            </ng-template>
          </ptg-panel>

          <ptg-panel [title]="'Property List'" [noPaddingBody]="true">
            <ng-template panelExtraHeader>
              <ptg-button buttonName="New Property" classInput="edit-button" (clickButton)="onClickAddNewProperty()">
                <mat-icon>add</mat-icon>
              </ptg-button>
            </ng-template>

            <ng-template panelBody>
              <ptg-grid
                [data]="propertiesData"
                [columns]="propertiesColumns"
                [fitToParent]="true"
                [paginable]="false"
                notFoundMessage="No Property to Display"
                [isLoading]="isLoadingProperty"
              >
                <ng-template cellContent columnName="action" let-row>
                  <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEditProperty(row)">
                    <mat-icon>edit</mat-icon>
                  </ptg-button>
                  <ptg-button buttonName="Remove" classInput="remove-button" (clickButton)="onClickRemoveProperty(row)">
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </ng-template>
              </ptg-grid>
            </ng-template>
          </ptg-panel>
        </div>
      </ng-template>
    </ptg-dialog>
  </div>
  <div id="loading-table" #loading *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner role="progressbar" aria-label="Progress Spinner" diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
