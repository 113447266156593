import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  AccidentClaim,
  AccidentPostCardRequest,
  GetAccidentClaimsRequest,
  GetAccidentClaimsResponse,
  GetListMunicipalityResponse,
  GetAccidentHistoriesRequest,
  GetAccidentHistoriesResponse,
  ViewAccidentDetailsResponse,
  AccidentReportRequest,
  GetAccidentNotesResponse,
  AccidentDocumentDownloadRequest,
  EditParticipantAccidentStatusRequest,
  UploadAccidentReportRequest,
  AccidentLetterRequest,
  EditAccidentRequest,
  EmployerDetail,
} from './models';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import {
  GetDisbursementReportsRequest,
  GetDisbursementReportsResponse,
  GetVendorsRequest,
  GetVendorsResponse,
} from 'src/app/admin/features/external-payment/services/models';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { getLocalDate } from '@ptg-shared/utils/string.util';

@Injectable({
  providedIn: 'root',
})
export class AccidentParticipantsService {
  accidentDocumentFile?: File;

  constructor(private httpClient: HttpClient) {}

  getAccidentClaims(
    request: GetAccidentClaimsRequest,
    memberId?: string,
    municipalityId?: string,
  ): Observable<GetAccidentClaimsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('sortNames', request.sortNames);
      params = params.append('sortType', request?.sortType ?? SortType.ASC);
    }

    if (request?.pageSize && request?.pageNumber) {
      params = params.append('pageSize', request.pageSize);
      params = params.append('pageIndex', request.pageNumber);
    }

    if (municipalityId) {
      params = params.append('municipalityId', municipalityId);
    }

    return this.httpClient.get<GetAccidentClaimsResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/accident-claims`,
      { params },
    );
  }

  getAccidentHistories(
    request: GetAccidentHistoriesRequest,
    memberId?: string,
    municipalityId?: string,
  ): Observable<GetAccidentHistoriesResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('sortNames', request.sortNames);
      params = params.append('sortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('pageSize', request.pageSize);
      params = params.append('pageIndex', request.pageNumber);
    }
    if (request?.forInitiateDisability) {
      params = params.append('forInitiateDisability', request.forInitiateDisability);
    }
    if (municipalityId) {
      params = params.append('municipalityId', municipalityId);
    }
    return this.httpClient.get<GetAccidentHistoriesResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/accident-histories`,
      { params },
    );
  }

  getListMunicipality(memberId: string, accidentDate: string) {
    return this.httpClient.get<GetListMunicipalityResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentDate}`,
    );
  }

  addAccidentPostCard(memberId: string, accidentPostCardRequest: AccidentPostCardRequest) {
    return this.httpClient.post(`${environment.apiUrl}/member-portal/${memberId}/accidents`,
      {...accidentPostCardRequest, localDate: getLocalDate()}
    );
  }

  editAccidentPostCard(memberId: string, accidentPostCardRequest: AccidentPostCardRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.put(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentPostCardRequest.id}`,
      accidentPostCardRequest,
      { context },
    );
  }

  removeAccidentHistory(memberId: string, accidentId: string) {
    return this.httpClient.delete(`${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}`);
  }

  createAccidentClaims(request: AccidentClaim, memberId: string, accidentId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/accident-claims`,
      request,
    );
  }

  editAccidentClaims(
    request: AccidentClaim,
    memberId: string,
    accidentId: string,
    accidentClaimId: string,
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/accident-claims/${accidentClaimId}`,
      request,
    );
  }

  getVendorsMasterData(request: GetVendorsRequest, clientId: string): Observable<GetVendorsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetVendorsResponse>(`${environment.apiUrl}/external-vendors`);
  }

  getAccidentClaimDetail(
    request: GetAccidentClaimsRequest,
    memberId?: string,
    accidentId?: string,
  ): Observable<GetAccidentClaimsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('sortNames', request.sortNames);
      params = params.append('sortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('pageSize', request.pageSize);
      params = params.append('pageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetAccidentClaimsResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/accident-claims`,
      { params },
    );
  }

  getDisbursementReports(
    request: GetDisbursementReportsRequest,
    clientId: string,
  ): Observable<GetDisbursementReportsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetDisbursementReportsResponse>(`${environment.apiUrl}/disbursement-report-histories`, {
      params,
    });
  }

  removeAccidentClaim(memberId: string, accidentId: string, accidentClaimId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/accident-claims/${accidentClaimId}`,
    );
  }

  viewAccidentDetails(memberId: string, accidentId: string) {
    return this.httpClient.get<ViewAccidentDetailsResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}`,
    );
  }

  addAccidentReport(memberId: string, accidentId: string, accidentReportRequest: AccidentReportRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/report`,
      accidentReportRequest,
    );
  }

  getAccidentDocuments(request: any, memberId: string, accidentId: string, municipalityId?: string): Observable<any> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('pageSize', request.pageSize);
      params = params.append('pageIndex', request.pageNumber);
    }
    if (municipalityId) {
      params = params.append('municipalityId', municipalityId);
    }

    return this.httpClient.get<any>(`${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/documents`, {
      params,
    });
  }

  uploadAccidentDocuments(
    request: UploadAccidentReportRequest,
    memberId: string,
    accidentId: string,
  ): Observable<void> {
    const body = new FormData();
    body.append('documentName', request.documentName);
    body.append('type', request.type);

    if (this.accidentDocumentFile) {
      body.append('attachment', this.accidentDocumentFile);
    }

    if (request?.fileName) {
      body.append('fileName', request?.fileName);
    }

    if (request?.tags?.length) {
      request?.tags?.forEach((tag, idx) => {
        body.append(`tags[${idx}]`, tag);
      });
    }

    if (request?.documentLocationTitle) {
      body.append('documentLocationTitle', request?.documentLocationTitle);
    }

    if (request?.documentLocationRouter) {
      body.append('documentLocationRouter', request?.documentLocationRouter);
    }

    if (request?.documentDescription) {
      body.append('documentDescription', request?.documentDescription);
    }

    if (request?.employerId) {
      body.append('employerId', request?.employerId);
    }

    if (request?.showOnOverview) {
      body.append('showOnOverview', (request?.showOnOverview as boolean)?.toString());
    }

    return this.httpClient.post<void>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/documents`,
      body,
    );
  }

  checkExistDocumentName = (bodyCheck: any) => {
    let params = new HttpParams();
    params = params.append('document-name', bodyCheck.documentName);
    const memberId = bodyCheck.memberId;
    const accidentId = bodyCheck.accidentId;
    if (bodyCheck?.fileId) {
      params = params.append('fileId', bodyCheck?.fileId);
    }
    return this.httpClient.get<any>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/documents/exists`,
      { params },
    );
  };

  dowloadAccidentDocument(request: AccidentDocumentDownloadRequest) {
    const { memberId, accidentId, accidentDocumentId } = request;
    return this.httpClient.get(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/documents/${accidentDocumentId}`,
      { responseType: 'blob' },
    );
  }

  getAccidentPostcardLetter(request: AccidentLetterRequest) {
    const body = {
      accidentId: request.accidentId,
      clientTime: new Date().toISOString(),
      timeZoneOffset: new Date().getTimezoneOffset(),
      documentLocation: DOCUMENT_LOCATION.ACCIDENT,
      documentLocationRouter: request?.documentLocationRouter
    }
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/${request.memberId}/accidents/post-card-acknowledgment-letter`, body
    );
  }

  getAccidentReportLetter(request: AccidentLetterRequest) {
    const body = {
      accidentId: request.accidentId,
      clientTime: new Date().toISOString(),
      timeZoneOffset: new Date().getTimezoneOffset(),
      documentLocation: DOCUMENT_LOCATION.ACCIDENT,
      documentLocationRouter: request?.documentLocationRouter
    }
    return this.httpClient.post(
      `${environment.apiUrl}/member-portal/${request.memberId}/accidents/report-acknowledgment-letter`, body
    );
  }

  createAccidentNote(note: string, memberId: string, accidentId: string) {
    return this.httpClient.post(`${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/notes`, note);
  }

  getAccidentNotes(query: any, memberId: string, accidentId: string): Observable<GetAccidentNotesResponse> {
    let params = new HttpParams();

    params = params.append('PageSize', query.pageSize);
    params = params.append('PageIndex', query.pageIndex);
    params = params.append('SortNames', 'CreatedTime');

    return this.httpClient.get<GetAccidentNotesResponse>(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/notes`,
      { params },
    );
  }

  editAccidentReport(memberId: string, accidentId: string, accidentReportRequest: AccidentReportRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/report/${accidentReportRequest.id}`,
      accidentReportRequest,
    );
  }

  editParticipantAccidentStatus(
    memberId: string,
    accidentId: string,
    editParticipantAccidentStatusRequest: EditParticipantAccidentStatusRequest,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/change-status/`,
      editParticipantAccidentStatusRequest,
    );
  }

  removeAccidentDocument(memberId: string, accidentId: string, accidentDocumentId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/documents/${accidentDocumentId}`,
    );
  }

  editAccidentDocument(
    memberId: string,
    accidentId: string,
    fileId: string,
    request: EditAccidentRequest,
  ) {

    return this.httpClient.put(
      `${environment.apiUrl}/member-portal/${memberId}/accidents/${accidentId}/${fileId}/documents`,
      request,
    );
  }

  getEmployerInfoForPostCard(employerId: string): Observable<EmployerDetail> {
    return this.httpClient.get<EmployerDetail>(`${environment.apiUrl}/employer-portal/employer/${employerId}/detail`);
  }
}
