<div class="status-history-container" id="status-history-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="status-history-content flex flex-col p-6 gap-6">
    <ptg-banner *ngIf="message" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="wrap-btn">
      <button mat-raised-button class="submit-button" (click)="onSubmit()">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>

    <!-- benefit type list -->
    <div class="flex-grow overflow-hidden">
      <div class="list-display">
        <div class="list-header">
          <span class="title-name">Report Categories List</span>
        </div>

        <div class="grid-wrapper">
          <ptg-grid
            #gridDataless
            [data]="reportCategories"
            [columns]="columns"
            [isLoading]="isLoading"
            [fitToParent]="true"
            [errorMessage]="errorMsg"
            notFoundMessage="No Report Category to display"
            [paginable]="false"
            [inlineEditable]="true"
            [allowDragDrop]="true"
            [softDeletable]="true"
          >
            <ng-template cellContent columnName="name" let-row>
              <div class="flex">
                <mat-icon
                  class="mt-auto mb-auto"
                  style="font-size: 14px; width: 18px; height: 18px"
                  [style.color]="row.statusColor"
                >
                  {{ row.order }}
                </mat-icon>
                <span class="mt-auto mb-auto ml-2">
                  {{ row.name }}
                </span>
              </div>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
    </div>
  </div>
</div>
