import { CardItem } from '@ptg-member/types/models/card.model';

import { EntityComponentType, EntityPropertyType, EntityType } from '../../types/enums';
import { CardProperty } from './entity-view.model';

export interface CreateEntityRequest {
  name: string;
  code: string;
  parentId: string;
  isEnableVersioning: boolean;
  description: string;
}

export interface GetEntitiesResponse {
  entities: Entity[];
  total?: number;
}

export interface Entity {
  id: string;
  name: string;
  label: string;
  description?: string;
  isStatic?: boolean;
  order?: number;
  parentId?: string;
  tableName?: string;
  numberOfProperty?: number;
  removeable: boolean;
}

export interface GetEntitiesQuery {
  pageIndex?: number;
  pageSize?: number;
  sortField?: string;
  sortType?: number;
}

export interface GetEntityDetailRequest {
  id: string;
}

export interface GetEntityDetailResponse {
  id: string;
  type: EntityType;
  entityName: string;
  code: string;
  description: string;
  parentId: string;
  parentName: string;
  entityKeyId: string;
  entityKey: string;
  displayFieldId: string;
  displayField: string;
  isEnableVersioning: boolean;
}

export interface GetEntityCardsTypeResponse {
  cards: CardItem[];
}

export interface UpdateEntityRequest {
  name: string;
  description: string;
  parentId: string;
  entityKeyId: string;
  displayFieldId: string;
  code: string;
  id: string;
}

export interface CheckExistEntityResponse {
  isExistsName: boolean;
  isExistsCode: boolean;
}

export interface AddPropertyRequest {
  name?: string;
  importKey?: string;
  type?: number;
  configs?: any;
}

export interface AddListRequest {
  name: string;
  importKey?: string;
  description?: string;
}

export interface DeletePropertyRequest {
  entityId: string;
  entityComponentId: string;
  entityPropertyId?: string;
  propertyName: string;
  isRemoveProperty: boolean;
}

export interface DeleteEntityComponentRequest {
  entityComponentId: string;
  propertyName: string;
}

export interface CheckEntityPropertyInUsedResponse {
  exists: boolean;
  message: string;
}

export interface Section {
  // TODO Enter your code here
}

export interface GetListDetailRequest {
  entityId: string;
  entityComponentId: string;
}

export interface GetListDetailResponse {
  section: any;
}

export interface UpdateListConfigRequest {
  name: string;
  importKey: string;
  entityId: string;
  entityComponentId: string;
  enableDragAndDropSorting: string;
  editByCreatorOnly: boolean;
  removeByCreatorOnly: boolean;
}

export interface Types {
  displayValue: string;
  value: number;
}

export interface GetPropertyTypesRequest {
  type: number;
}

export interface GetPropertyTypesResponse {
  entityPropertyTypes: Types[];
  propertyTypes: Types[];
}

export interface GetEntityPropertiesForCalculationRequest {
  entityId: string;
}

export interface GetEntityPropertiesForCalculationResponse {
  entityProperties: EntityPropertyForCalculation[];
}

export interface EntityPropertyForCalculation {
  entityComponentId: string;
  entityPropertyId: string;
  entityName: string;
  entityPropertyName: string;
  subTitle: string;
  propertyType: EntityPropertyType;
}

export interface PropertyItemConfig {
  key: string;
  value: string;
}

export interface UpdatePropertyItemRequest {
  name: string;
  importKey: string;
  configs: { key: string; value: string }[];
  entityId: string;
  objectId: string;
  id: string;
}

export interface EntityProperties {
  id: string;
  entityComponentId: string;
  importKey: string;
  linkedEntityName: string;
  propertyName: string;
  type: EntityPropertyType;
  isStatic: boolean;
  inheritance: boolean;
  configs: any;
  attributeType: string;
  displayDatatype: string;
  dateCreated: string;
  isUnique: boolean;
}

export interface GetEntityPropertiesRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  entityId: string;
}

export interface GetEntityPropertiesResponse {
  propertyItems: EntityProperties[];
  total: number;
  entityComponentId: string;
}

export interface EntityList {
  id: string;
  name: string;
  importLabel: string;
  inheritance: boolean;
  totalProperty: number;
}

export interface GetEntityListsRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  entityId: string;
}

export interface GetEntityListsResponse {
  listItems: EntityList[];
  total: number;
}

export interface EntityProperties {
  id: string;
  entityComponentId: string;
  importKey: string;
  linkedEntityName: string;
  propertyName: string;
  type: EntityPropertyType;
  isStatic: boolean;
  inheritance: boolean;
  config: any;
  attributeType: string;
  displayDatatype: string;
  dateCreated: string;
}

export interface EntityList {
  id: string;
  name: string;
  importLabel: string;
  inheritance: boolean;
  totalProperty: number;
}

export interface EntityComponentList {
  id: string;
  name: string;
  type: EntityComponentType;
  entityProperties: any;
}

export interface GetEntityComponentListsRequest {
  entityId: string;
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
}

export interface GetEntityComponentListsResponse {
  listItems: EntityComponentList[];
  total: number;
}

export interface CheckExistRequest {
  name: string;
  importKey: string;
  entityId: string;
  entityComponentId: string;
  entityPropertyId: string;
}

export interface CheckExistResponse {
  checkExist: CheckExist;
}

export interface CheckExist {
  isExist: boolean;
}

export interface EntityPropertyDetail {
  id: string;
  entityComponentId: string;
  importKey: string;
  propertyName: string;
  type: EntityPropertyType;
  configs: any;
  isIdentifierPropertyHasValue?: boolean;
}

export interface GetEntityPropertyDetailRequest {
  entityPropertyId: string;
}

export interface GetEntityPropertyDetailResponse {
  id: string;
  entityComponentId: string;
  importKey: string;
  propertyName: string;
  type: EntityPropertyType;
  configs: any;
}

export interface SetEntityPropertyRequest {
  entityComponentId: string;
  entityPropertyId: string;
  name: string;
  importKey?: string;
  configs: any;
}

export enum PropertyConfigOption {
  MinInput = 'minInput',
  MaxInput = 'maxInput',
  MaxLengthInput = 'maxLengthInput',
  FractionalLengthInput = 'fractionalLengthInput',
  LookupTable = 'lookupTable',
  Affirmative = 'affirmative',
  Negative = 'negative',
  CountryLookupTable = 'countryLookupTable',
  StateLookupTable = 'stateLookupTable',
  Unique = 'unique',
  Encrypted = 'encrypted',
  Aggregation = 'aggregation',
  Calcucation = 'calcucation',
  EntityReference = 'entityReference',
  DefaultStateLookupTable = 'defaultStateLookupTable',
  DefaultCountryLookupTable = 'defaultCountryLookupTable',
  DefaultTitleLookupTable = 'defaultTitleLookupTable',
}

export enum AggregationType {
  Count,
  Sum,
  Average,
  Min,
  Max,
}

export type BooleanPropertyConfigTypeValue =
  | BooleanPropertyConfigType.ReadOnly
  | BooleanPropertyConfigType.Required
  | BooleanPropertyConfigType.IncludeInBulkUpdate
  | BooleanPropertyConfigType.InclusiveInRange
  | BooleanPropertyConfigType.MaximumLength
  | BooleanPropertyConfigType.FractionalLength
  | BooleanPropertyConfigType.SMSEnabled
  | BooleanPropertyConfigType.PrefixList
  | BooleanPropertyConfigType.ListValues
  | BooleanPropertyConfigType.Boolean
  | BooleanPropertyConfigType.UsedStateLookupTable
  | BooleanPropertyConfigType.UsedCountryLookupTable
  | BooleanPropertyConfigType.Initiated;

export enum BooleanPropertyConfigType {
  ReadOnly = 'readOnly',
  Required = 'required',
  IncludeInBulkUpdate = 'includeInBulkUpdate',
  InclusiveInRange = 'inclusiveInRange',
  MaximumLength = 'maximumLength',
  FractionalLength = 'fractionalLength',
  SMSEnabled = 'smsEnabled',
  PrefixList = 'prefixList',
  ListValues = 'listValues',
  Boolean = 'boolean',
  Initiated = 'initiated',
  UsedCountryLookupTable = 'usedCountryLookupTable',
  UsedStateLookupTable = 'usedStateLookupTable',
  Masked = 'masked',
  ExcludeFutureDates = 'excludeFutureDates',
  ExcludePastDates = 'excludePastDates',
  DateValidation = 'dateValidation',
}

export enum DefaultBooleanValue {
  Affirmative = 'Yes',
  Negative = 'No',
}

export interface PropertyConfig {
  includeInBulkUpdate?: string;
  inclusiveInRange?: string;
  maxInput?: string;
  minInput?: string;
  readOnly?: string;
  required?: string;
  maximumLength?: string;
  maxLengthInput?: string;
  fractionalLength?: string;
  fractionalLengthInput?: string;
  smsEnabled?: string;
  prefixList?: string;
  listValues?: string;
  boolean?: string;
  affirmative?: string;
  negative?: string;
  lookupTable?: string;
  unique?: string;
  encrypted?: string;
  masked?: string;
  initiated?: string;
  usedStateLookupTable?: string;
  stateLookupTable?: string;
  usedCountryLookupTable?: string;
  countryLookupTable?: string;
  defaultStateLookupTable?: string;
  defaultCountryLookupTable?: string;
  defaultTitleLookupTable?: string;
  excludeFutureDates?: string;
  excludePastDates?: string;
  dateValidation?: string;
  dateValidationExpressions?: string;
  entityReference?: string;
  autogenerated?: string;
  pattern?: string;
  patternPrefix?: string;
  patternSuffix?: string;
  startingValue?: string;
}

export enum CalculationPropertyType {
  ParticipantMetadata,
  Aggregation,
  Calculation,
}

export enum Operator {
  Plus,
  Minus,
  Multiply,
  Divide,
}

export enum CalculationDataType {
  Property,
  Number,
}

export interface EntityCalculationConfig {
  displayFormat: EntityPropertyType;
  fractionalLength?: number;
  calculationComponents: CalculationComponent[];
}

export interface CalculationComponent {
  operator?: Operator;
  dataType: CalculationDataType;
  value?: number;
  order: number;
  property?: CalculationProperty;
}

export interface CalculationProperty {
  entityPropertyId: string;
  propertyType?: EntityPropertyType;
}

export interface EntityReference {
  id: string;
  name: string;
  properties: any;
}

export interface GetEntityReferencesRequest { }
export interface GetEntityReferencesResponse {
  entities: EntityReference[];
}

export interface EntityConfigs {
  title: string;
  propertyName?: string;
  buttonName?: string;
  label?: string;
}

export interface OrderTitle {
  title: string;
}

export interface EntityPropertyOptions {
  id: string;
  name: string;
  type: EntityPropertyType;
}

export interface CreateEntityInitiationConfigurationsRequest {
  label: string;
  configuredProperties: ConfiguredProperties[];
}

export interface ConfiguredProperties {
  id: string;
  entityPropertyId: string;
  entityPropertyName: string;
  label: string;
  order: number;
}

export interface EntityInitiationConfiguration {
  id?: string;
  columnName: string;
  columnNameDescription?: string;
  entityPropertyId?: string;
  entityPropertyName?: string;
  deleted?: boolean;
  isUsed?: boolean;
  isEnable?: boolean;
  orderRow?: number;
  sortType?: number;
  orderColumn?: number;
  order?: number;
}

export interface GetEntityInitiationConfigurationRequest {
  entityId: string;
}

export interface GetEntityInitiationConfigurationResponse {
  entityName: string;
  entityLabel: string;
  properties: EntityPropertyOptions[];
  configuredProperties: ConfiguredProperties[];
}

export interface ConfiguredProperties {
  id: string;
  entityPropertyId: string;
  entityPropertyName: string;
  label: string;
  order: number;
}

export interface EntityHeaderDetail {
  entityName?: string;
  entityLabel?: string;
}

export interface SetEntityInitiationConfigurationRequest {
  entityId?: string;
  label: string;
  configuredProperties: ConfiguredProperties[];
}

export interface CheckEntityCanRemoveResponse {
  canRemove?: boolean;
}

export interface CheckEntityCanRemoveRequest {
  entityId?: string;
}

export interface EntityListData {
  id: string;
  noOfAttachments: number;
  values: any[];
  canEdit?: boolean;
  canRemove?: boolean
}

export interface GetEntityListDataByIdQuery {
  pageIndex?: number;
  pageSize?: number;
  sortField?: string;
  sortType?: number;
  memberId: string;
  cardId: string;
  entityId: string;
  isSummary: boolean;
  screenId?: string;
}

export interface GetEntityListDataByIdResponse {
  rowData: EntityListData[];
  total: number;
  cardProperties: CardProperty[];
  entityComponentId: string;
  isCourtOrder?: boolean;
}

export interface DeleteEntityListDataQuery {
  entityComponentId: string;
  id: string;
}

export interface ReOrderEntityListDataRequest {
  targetId: string;
  entityComponentId: string;
  upperAdjacentId: number;
  reorderId: string;
}


export interface GetListBenefitResponse {
  data: EntityBenefit[];
  calculationList: Calculation[];
  parentBenefitEntityList: ParentBenefit[];
  isConfig: boolean;
  total: number
}

export interface EntityBenefit {
  name: string;
  calculation: string;
  parentBenefitEntity: ItemBenefitEntity[];
}

export interface ItemBenefitEntity {
  code: string;
  name: string;
  description: string;
  id: string;
}

export interface Calculation {
  id: string;
  name: string;
}
export interface ParentBenefit {
  code: string;
  description: string;
  displayFieldId: string;
  entityKeyId: string;
  id: string;
  name: string;
  parentId: string;
}

export interface BenefitMappingPayload {
  benefitMapping: {
    id: string;
    calculation: string;
    parentBenefitEntity: {id: string}[];
    name: string;
  }
}
